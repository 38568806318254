// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultSelectLocationsFields = {
  [GC.FIELD_ADDRESS]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_LONGITUDE]: '',
};

const inputWrapperStyles = { width: 180 };
const labelStyles = { fontWeight: 700, color: G.getTheme('colors.white') };

export const selectLocLatLngFields = [
  {
    labelStyles,
    type: 'text',
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
    inputWrapperStyles: { mr: 25, width: 180 },
  },
  {
    labelStyles,
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
  },
];

export const selectLocAddressFields = [
  {
    labelStyles,
    inputWrapperStyles,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
    label: ['titles:address', 'Address'],
    placeholder: G.getWindowLocale('titles:search-a-location', 'Search a location...'),
  },
];
