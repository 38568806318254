import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SELECT_TERMINAL',
    mapPropsToValues: () => ({ terminal: null, eventType: null }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, handleSubmitAction } = props;

      closeModal();
      handleSubmitAction(values);
    },
    validationSchema: Yup.object().shape({
      terminal: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
      eventType: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    }),
  }),
  pure,
);

const stopOptions = [
  {
    value: GC.EVENT_TYPE_PICKUP,
    label: G.getWindowLocale('titles:pickup', 'Pickup'),
  },
  {
    value: GC.EVENT_TYPE_DROP,
    label: G.getWindowLocale('titles:drop', 'Drop'),
  },
];

const getFields = (options: Array) => [
  {
    type: 'reactSelect',
    options: stopOptions,
    fieldName: 'eventType',
    inputWrapperStyles: { mb: 25, width: 420, zIndex: 11 },
    placeholder: G.getWindowLocale('titles:stop-type', 'Stop Type'),
  },
  {
    options,
    type: 'reactSelect',
    fieldName: 'terminal',
    inputWrapperStyles: { mb: 25, width: 420 },
    placeholder: G.getWindowLocale('titles:terminal', 'Terminal'),
  },
];

const SelectTerminal = enhance((props: Object) => {
  const {
    options,
    closeModal,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...props}
        fields={getFields(options)}
      />
      <FormFooter2 closeModal={closeModal} />
    </form>
  );
});

export default SelectTerminal;
