import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// forms
import { Fieldset2 } from '../formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const defaultDocumentFields = {
  [GC.FIELD_DOCUMENT_URL]: '',
  [GC.FIELD_DOCUMENT_STATUS]: '',
  [GC.FIELD_DOCUMENT_UPLOAD]: null,
  [GC.FIELD_DOCUMENT_DESCRIPTION]: '',
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: '',
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: '',
  [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: '',
};

const validationSchema = Yup.lazy(({ url, file }: Object) => Yup.object().shape({
  [GC.FIELD_DOCUMENT_STATUS]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_DESCRIPTION]: G.yupStringNotRequired,
  [GC.FIELD_DOCUMENT_URL]: G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    G.yupStringRequired,
    Yup.string().nullable(true).url(G.getShouldBeUrlLocaleTxt()),
  ),
  [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

const inputWrapperStyles = { mb: 25, width: 190 };
const inputWrapperStylesFullWidth = { mb: 25, width: 400 };

const fieldSettings = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'documentTypes',
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
    label: ['titles:document-type', 'Document Type'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:status', 'Status'],
    fieldName: GC.FIELD_DOCUMENT_STATUS,
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.DOCUMENT_STATUS_CURRENT,
        label: G.getWindowLocale('titles:current', 'Current'),
      },
      {
        value: GC.DOCUMENT_STATUS_HISTORICAL,
        label: G.getWindowLocale('titles:historical', 'Historical'),
      },
    ],
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DOCUMENT_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: inputWrapperStylesFullWidth,
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_DOCUMENT_SIGNED_DATE,
    label: ['titles:signed-date', 'Signed Date'],
    maxDate: ({ values: { expirationDate } }: Object) =>
      R.and(G.isNotNilAndNotEmpty(expirationDate), G.makeMomentInstance(expirationDate)),
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    fieldName: GC.FIELD_DOCUMENT_EXPIRATION_DATE,
    label: ['titles:expired-date', 'Expired Date'],
    minDate: ({ values: { signedDate } }: Object) =>
      R.and(G.isNotNilAndNotEmpty(signedDate), G.makeMomentInstance(signedDate)),
  },
  {
    type: 'text',
    label: ['titles:url', 'URL'],
    fieldName: GC.FIELD_DOCUMENT_URL,
    inputWrapperStyles: {
      ...inputWrapperStylesFullWidth,
      display: ({ values }: Object) => {
        const { file, documentFilename } = values;

        return G.ifElse(G.isOneNotNilOrNotEmpty([file, documentFilename]), 'none', 'block');
      },
    },
  },
  {
    type: 'file',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    label: ['titles:document-template', 'Document Template'],
    inputWrapperStyles: {
      ...inputWrapperStylesFullWidth,
      display: ({ values }: Object) => {
        const { url, version, documentFilename } = values;

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
];

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultDocumentFields, initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const { signedDate, expirationDate } = values;

      const data = R.mergeRight(values, {
        [GC.FIELD_DOCUMENT_SIGNED_DATE]: G.ifElse(G.isValidMoment(signedDate), signedDate, null),
        [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: G.ifElse(G.isValidMoment(expirationDate), expirationDate, null),
      });

      return submitAction(G.mapObjectEmptyStringFieldsToNull(data));
    },
  }),
  pure,
);

export const DocumentForm = enhance((props: Object) => {
  const { handleSubmit, documentTypes } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        fields={fieldSettings}
        documentTypes={documentTypes}
        fieldsWrapperStyles={{ pt: 15, px: '0px', justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
});
