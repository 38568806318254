import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// features
import { AuthWrapper } from '../../features/permission';
import PC from '../../features/permission/role-permission';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../../forms';
import { FormGroupWrapper } from '../ui';
// ui
import { BoxWithChildDiv } from '../../ui';
//////////////////////////////////////////////////

export const oldPasswordSettings = [
  {
    isRequired: true,
    type: 'password',
    fieldName: GC.FIELD_OLD_PASSWORD,
    label: ['titles:old-password', 'Old Password'],
    inputWrapperStyles: { mt: 15, mb: 25, width: 270 },
  },
];

export const newPasswordSettings = [
  {
    isRequired: true,
    type: 'password',
    fieldName: GC.FIELD_NEW_PASSWORD,
    label: ['titles:new-password', 'New Password'],
    inputWrapperStyles: { mt: 15, mb: 25, width: 270 },
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_NEW_PASSWORD]: Yup.string()
    .nullable(true)
    .required('Field is Required')
    .max(128, 'Should be less or equal'),
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, changePasswordRequest } = props;

      closeModal();
      changePasswordRequest(values);
    },
    mapPropsToValues: () => ({
      [GC.FIELD_NEW_PASSWORD]: null,
      [GC.FIELD_OLD_PASSWORD]: null,
    }),
  }),
  pure,
);

export const ChangePasswordForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FormGroupWrapper>
      <BoxWithChildDiv childWidth='100%'>
        <AuthWrapper notHas={[PC.USER_WRITE]}>
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={oldPasswordSettings}
          />
        </AuthWrapper>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={newPasswordSettings}
        />
        <FormFooter2 />
      </BoxWithChildDiv>
    </FormGroupWrapper>
  </form>
));
