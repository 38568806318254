import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-search
import TemplateSearch from '../';
import { makeSelectItemTemplates } from '../selectors';
import { getItemListBySearchRequest } from '../actions';
import { itemFields, defaultItemFields } from '../settings';
//////////////////////////////////////////////////

// TODO: with constants and clean logic

const renderItemInfoPair = (first: string, second: string) => {
  if (R.or(R.isNil(first), R.isNil(second))) return '';

  return `${first} ${second}`;
};

const getInfo = (itemTemplate: Object = {}) => {
  const weight = renderItemInfoPair(itemTemplate[GC.FIELD_ITEM_WEIGHT], itemTemplate.weightType);
  const volume = renderItemInfoPair(itemTemplate[GC.FIELD_ITEM_VOLUME], itemTemplate[GC.FIELD_ITEM_VOLUME_UOM]);
  const quantity = renderItemInfoPair(R.pathOr('', [GC.FIELD_ITEM_QUANTITY], itemTemplate), itemTemplate.packageType);
  const condition = R.and(
    R.and(R.isEmpty(weight), R.isEmpty(quantity)),
    R.isEmpty(volume),
  );

  if (condition) return itemTemplate.itemInternalId;

  const itemInfo = R.filter(
    (info: string) => G.isNotNilAndNotEmpty(info),
    [weight, quantity, volume],
  );

  return itemInfo.join(', ');
};

const createTemplateSelectOptions = (itemTemplates: Array) => itemTemplates.map((itemTemplate: Object) => ({
  value: itemTemplate,
  label: R.trim(`${itemTemplate.itemId}
    ${getInfo(itemTemplate)}
    ${R.or(itemTemplate.description, '')}
  `),
}));

const Component = (props: Object) => (
  <TemplateSearch
    {...props}
    templateFields={itemFields}
    templateList={props.itemTemplates}
    defaultTemplateFields={defaultItemFields}
    getListTemplatesAction={props.getItemListBySearchRequest}
    selectOptions={createTemplateSelectOptions(props.itemTemplates)}
  />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemTemplates: makeSelectItemTemplates(state),
});

export const ItemTemplateSearch = connect(mapStateToProps, {
  getItemListBySearchRequest,
})(Component);
