import styled from 'styled-components';
import { space, width, height, maxWidth } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// ui
import { renderBorderColor } from '../helpers';
//////////////////////////////////////////////////

export const SwitchDriversWrapper = styled('section')`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled('section')``;

export const FormSection = styled('section')`
  ${space}

  display: flex;
  flex-wrap: wrap;
  position: static;
  z-index: ${({ zIndex }: Object) => zIndex || 'initial'};
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'row'};
`;

export const FieldsGroup = styled('div')`
  ${space}
  ${width}

  position: static;
  display: ${({ display }: Object) => display || 'flex'};
  z-index: ${({ zIndex }: Object) => zIndex || 'initial'};
  flex-wrap: ${({ flexWrap }: Object) => flexWrap || 'wrap'};
  justify-content: ${({ justifyContent }: Object) => justifyContent};
  align-items: ${({ alignItems }: Object) => alignItems || 'flex-start'};
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'row'};
`;

export const Fieldset = styled('section')`
  ${space}
  ${width}

  border: none;
  display: flex;
  outline: none;
  flex-grow: ${({ flexGrow }: Object) => flexGrow || 1};
  z-index: ${({ zIndex }: Object) => zIndex || 'initial'};
  flex-wrap: ${({ flexWrap }: Object) => flexWrap || 'wrap'};
  justify-content: ${({ justifyContent }: Object) => justifyContent};
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'row'};
`;

export const InputsWrapper = styled('div')`
  ${space}
  ${width}
  ${maxWidth}

  display: flex;
  flex-wrap: wrap;
  position: relative;

  justify-content: ${({ jc }: Object) => jc || 'initial'};
  align-items: ${({ alignItems }: Object) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'row'};
`;

InputsWrapper.defaultProps = {
  width: 'auto',
  maxWidth: 'auto',
};

export const InputSelect = styled('select')`
  ${space}
  ${width}

  height: 30px;
  outline: none;
  appearance: none;
  line-height: 30px;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};
  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

export const ReactSelectWrapper = styled('div')`
  ${width}
`;

export const CountrySelectWrapper = styled.section`
  ${space}
  ${width}

  height: 30px;
  display: flex;
  position: relative;

  z-index: ${({ zi }: Object) => zi};

  & > select {
    z-index: 0;
    width: 100%;
    height: 30px;
    outline: none;
    appearance: none;
    line-height: 30px;
    padding-left: 15px;
    border-radius: 4px;
    position: relative;
    padding-right: 25px;
  
    background-color: ${() => G.getTheme('forms.inputs.bgColor')};
    border: 1px solid ${(props: Object) => renderBorderColor(props)};
  
    &:focus {
      box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
    }
  }

  &:after {
    top: 15px;
    width: 6px;
    content: '';
    right: 15px;
    height: 6px;
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);
  }
`;

export const CalendarWrapper = styled('div')`
  & > div {
    z-index: 12;
  }
  & .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 5;
    margin-left: 3;
  }
  & .react-datepicker-popper[data-placement^="top"] {
    margin-left: 10px;
  }
  & .react-datepicker__navigation {
    z-index: 11;
  }
  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker__navigation--next {
    border-left-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__navigation--previous {
    border-right-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__day--selected {
    background-color: ${() => G.getTheme('colors.light.mainRed')};
    &:hover {
      background-color: ${() => G.getTheme('colors.light.mainRed')};
    }
  }
  & .react-datepicker__header--time {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  & .react-datepicker {
    box-shadow: 0 0 1px 1px ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
      padding: 0;
      height: 183px;
      & li.react-datepicker__time-list-item {
        padding: 5px 6px;
      }
      & li.react-datepicker__time-list-item--selected {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
      &:hover {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
        }
      }
    }
`;

export const MultiEmailInput = styled.input`
  ${space}
  ${width}
  ${height}

  border: none;
  outline: none;
  display: ${({ display }: Object) => display};
  min-width: ${({ minWidth }: Object) => minWidth};
  background: ${({ background }: Object) => background};

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const UploadWrapper = styled.label`
  ${space}
  ${width}

  height: 30px;
  padding: 6px 0;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  position: relative;
  border-radius: 4px;
  text-overflow: ellipsis;

  display: ${({ display }: Object) => display || 'initial'};
  border: 1px dashed ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.ifElse(
      disabled,
      G.getTheme('forms.inputs.bgDisabled'),
      G.getTheme('forms.inputs.bgColor'),
    )
  )};

  & input[type=file] {
    display: none;
  }
  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

export const PhoneNumberInputWrapper = styled.div`
  ${space}
  ${width}

  & .react-phone-number-input {
    width: 100%;
  }

  & .react-phone-number-input__row {
    width: 100%;
  }

  & .react-phone-number-input__input--style {
    height: 30px;
    width: calc(100% - 30px);
    border-color: ${(props: Object) => renderBorderColor(props)};

    &:focus {
      border-color: ${({ version }: Object) => (
        G.getThemeByCond(G.isThemeSecondVersion(version), 'colors.boxShadowBlue', 'colors.boxShadowRed')
      )};
    }
  }
`;
