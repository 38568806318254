import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
// helpers/constants
import * as G from '../../helpers';
// forms
import { CalendarMuiFormGroup } from '../restyled';
// components
import { DatePickerMui } from '../../components/datepicker-mui';
//////////////////////////////////////////////////

const DatePickerMuiFormik = ({
  id,
  value,
  width,
  error,
  touched,
  minDate,
  maxDate,
  onChange,
  borderColor,
  placeholder,
  isClearable,
  borderRadius,
  timeSelection,
  setFieldValue,
  setFieldTouched,
  withCalendarIcon,
  excludeDates = [],
  shouldCustomChange,
}: Object) => {
  const { format } = G.getDateTimeFormatForMui(timeSelection);

  const handleBlur = () => {
    setFieldTouched(id, true);
  };

  const handleChange = (value: string) => {
    if (R.isNil(value)) {
      return setFieldValue(id, null);
    }

    if (shouldCustomChange) {
      return onChange({ target: { id, value: G.createLocalDateTimeFromInstanceOrISOString(value, format) } });
    }

    setFieldValue(id, G.createLocalDateTimeFromInstanceOrISOString(value, format));
  };

  const selected = G.ifElse(G.isNotNilAndNotEmpty(value), moment(value), null);

  const setMaxDate = () => {
    if (G.isNotNilAndNotEmpty(maxDate)) return maxDate;

    return moment().add(100, 'years');
  };

  const setMinDate = () => {
    if (G.isNotNilAndNotEmpty(minDate)) return minDate;

    return moment().subtract(100, 'years');
  };

  const timeIntervals = R.or(G.getCalendarTimeIntervalConfigFromWindow(), 15);

  const isDateDisabled = (date: Object) => (
    R.includes(
      G.createLocalDateTimeFromInstanceOrISOString(date, format),
      excludeDates,
    )
  );

  return (
    <CalendarMuiFormGroup direction='row'>
      <DatePickerMui
        width={width}
        value={selected}
        maxDate={setMaxDate()}
        minDate={setMinDate()}
        onChange={handleChange}
        placeholder={placeholder}
        isClearable={isClearable}
        minutesStep={timeIntervals}
        isDateDisabled={isDateDisabled}
        withCalendarIcon={withCalendarIcon}
        inputSettings={{
          id,
          error,
          touched,
          borderColor,
          borderRadius,
          onBlur: handleBlur,
          boxShadow: 'unset',
        }}
      />
    </CalendarMuiFormGroup>
  );
};

export default DatePickerMuiFormik;
