import * as R from 'ramda';
import React from 'react';
// components
import { LocalLoader } from '../../components/local-loader';
// forms
import { SelectDropdownForm } from './select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncConfigs } from '../../hocs';
//////////////////////////////////////////////////

const transformValueToRequest = (value: string, fullOptions: Array) => {
  const indexedOptions = G.indexMappedDropdownOptionsByField(
    GC.FIELD_DROPDOWN_OPTION_GUID,
    G.mapDropdownConfigOptionsToObjects,
    fullOptions,
  );

  const orderType = R.prop(value, indexedOptions);

  return { orderType };
};

const AsyncComponent = withAsyncConfigs((props: Object) => {
  const { closeModal, submitAction } = props;

  const options = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    R.path(['asyncConfigs'], props),
    GC.CLO_ORDER_TYPE_DROPDOWN,
  );
  const fullOptions = R.path(
    ['asyncConfigs', 'dropdowns', GC.CLO_ORDER_TYPE_DROPDOWN, 'options'],
    props,
  );
  const initialValues = {
    option: R.pathOr(null, ['defaultOrderType', GC.FIELD_DROPDOWN_OPTION_GUID], props),
  };

  return (
    <LocalLoader width={330} minHeight={135} localLoaderOpen={R.isNil(props.asyncConfigs)}>
      <SelectDropdownForm
        options={options}
        optionRequired={false}
        cancelAction={closeModal}
        initialValues={initialValues}
        submitAction={(value: string) => (
          submitAction(transformValueToRequest(value, fullOptions))
        )}
        fieldLabel={G.getWindowLocale('titles:order-type', 'Order Type')}
      />
    </LocalLoader>
  );
});

export const AsyncOrderTypeForm = (props: Object) => (
  <AsyncComponent {...props} configsNamesArray={[GC.CLO_ORDER_TYPE_DROPDOWN]} />
);
