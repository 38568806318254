import React from 'react';
import * as R from 'ramda';
import { pure, compose, withProps } from 'react-recompose';
// components
import { LocalLoader } from '../../components/local-loader';
// forms
import { SelectDropdownForm } from './select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncConfigs, withAsyncGetTransportationModeGrouping } from '../../hocs';
//////////////////////////////////////////////////

const transformValueToRequest = (value: string, fullEquipmentOptions: Array) => {
  const indexedOptions = G.indexMappedDropdownOptionsByField(
    GC.FIELD_DROPDOWN_OPTION_GUID,
    G.mapDropdownConfigOptionsToObjects,
    fullEquipmentOptions,
  );
  const equipment = R.prop(value, indexedOptions);
  return { equipment };
};

const enhance = compose(
  withProps(({ loadConfigs }: Object) => ({
    configsNamesArray: G.ifElse(R.isNil(loadConfigs), R.of(Array, GC.GENERAL_EQUIPMENTS)),
  })),
  withAsyncConfigs,
  withAsyncGetTransportationModeGrouping,
  pure,
);


const AsyncComponent = enhance((props: Object) => {
  const {
    loadConfigs,
    asyncConfigs,
    submitAction,
    transportationModeGroupingList,
    customerRateTransportationModeGuid,
  } = props;

  const configs = R.pathOr(asyncConfigs, ['configsByNames'], loadConfigs);
  const equipment = R.pathOr(null, ['defaultEquipment', GC.FIELD_DROPDOWN_OPTION_GUID], props);

  const { equipmentOptions } = G.makeTransportationGroupingOptions(
    customerRateTransportationModeGuid,
    transportationModeGroupingList,
    configs,
    { equipment },
  );


  const fullEquipmentOptions = R.path(['dropdowns', GC.GENERAL_EQUIPMENTS, 'options'], configs);

  const initialValues = {
    option: equipment,
  };

  return (
    <LocalLoader localLoaderOpen={R.isNil(configs)}>
      <SelectDropdownForm
        optionRequired={false}
        options={equipmentOptions}
        initialValues={initialValues}
        fieldLabel={G.getWindowLocale('titles:equipments', 'Equipments')}
        submitAction={(value: string) => (
          submitAction(transformValueToRequest(value, fullEquipmentOptions))
        )}
      />
    </LocalLoader>
  );
});

export const AsyncLoadEquipmentsForm = (props: Object) => (
  <AsyncComponent {...props} />
);
