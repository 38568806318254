import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form, Fieldset2, FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, IconWrapper, SimpleWrapper } from '../../../ui';
// appointments
import {
  appointmentSectionFields,
  defaultAppointmentSectionFields,
  appointmentSectionValidationSchema,
} from './field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: appointmentSectionValidationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultAppointmentSectionFields,
      {
        ...initialValues,
        [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: G.convertTimeToConfigFormat(
          R.path([GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], initialValues), GC.DEFAULT_TIME_FORMAT,
        ),
        [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: G.convertTimeToConfigFormat(
          R.path([GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], initialValues), GC.DEFAULT_TIME_FORMAT,
        ),
      },
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      if (G.isFalse(G.validateAppointmentsFormValues(values))) return;

      const dataForRequest = {
        ...values,
        [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: G.convertTimeToFormat(
          R.path([GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], values), GC.DEFAULT_TIME_FORMAT,
        ),
        [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: G.convertTimeToFormat(
          R.path([GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], values), GC.DEFAULT_TIME_FORMAT,
        ),
      };

      props.submitAction(dataForRequest);
    },
  }),
  withHandlers({
    handleCleanForm: ({ values, setValues }: Object) => () => (
      setValues(R.mergeRight(values, defaultAppointmentSectionFields))
    ),
  }),
  pure,
);

const AppointmentsForm = enhance((props: Object) => {
  const { handleSubmit, handleCleanForm } = props;

  const textColor = G.getTheme('colors.light.blue');

  return (
    <Form onSubmit={handleSubmit}>
      <SimpleWrapper
        mt={10}
        p='0 15px'
        height={30}
        width='100%'
        jc='space-between'
      >
        <Box color={textColor} lineHeight='30px' fontWeight='bold'>
          {G.getWindowLocale('titles:edit-appointment', 'Edit Appointment')}
        </Box>
        <IconWrapper cursor='pointer' onClick={handleCleanForm}>
          {I.clean(textColor)}
        </IconWrapper>
      </SimpleWrapper>
      <FormGroupWrapper p={15} mt={10} flexDirection='column'>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={appointmentSectionFields}
        />
        <FormFooter2 />
      </FormGroupWrapper>
    </Form>
  );
});

export default AppointmentsForm;
