import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
import { LocalLoader } from '../../../components/local-loader';
// features
import {
  makeSelectPostLoadBoards,
  makeSelectRequiredMinMaxPrice,
} from '../../../features/load-board/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { exportTelToLoadBoard } from '../../../common/actions';
// hocs
import { withAsyncGetDetailsForPostLoad } from '../../../hocs';
// ui
import { Box, Flex, StickedFlex } from '../../../ui';
// forms
import { Checkbox, Fieldset2 } from '../../';
import {
  sourceField,
  validationSchema,
  getInitialValues,
  getBookPriceFieldSettings,
} from './formik-settings';
//////////////////////////////////////////////////

const fieldsToPick = [
  GC.FIELD_RATE,
  GC.FIELD_MAX_PRICE,
  GC.FIELD_MIN_PRICE,
  GC.FIELD_LOAD_BOARD_TRUCK,
  GC.FIELD_LOAD_BOARD_LENGTH,
  GC.FIELD_LOAD_BOARD_LENGTH_UOM,
  GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER,
];

const omitFields = [
  GC.FIELD_RATE,
  GC.FIELD_LENGTH,
  GC.FIELD_MIN_PRICE,
  GC.FIELD_MAX_PRICE,
  GC.FIELD_LENGTH_UOM,
  GC.FIELD_TRUCK_TYPE,
  GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER,
];

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({
      guids,
      loadboards,
      telsPostData,
    }: Object) => getInitialValues(guids, loadboards, telsPostData),
    handleSubmit: (values: Object, { props }: Object) => {
      const { isEdit, telsPostData, exportTelToLoadBoard } = props;

      const { tels, source } = values;

      const fieldsToPickToUse = G.ifElse(isEdit, fieldsToPick, R.append(GC.FIELD_GUID, fieldsToPick));

      const mappedTels = R.map(R.pick(fieldsToPickToUse), tels);

      const postedShipments = telsPostData.map((item: Object, i: number) => R.mergeDeepRight(
        R.compose(
          R.assoc('types', source),
          R.omit(omitFields),
        )(item),
        R.propOr({}, i, mappedTels),
      ));

      exportTelToLoadBoard({ data: { postedShipments }, rePost: isEdit });
    },
    displayName: 'POST_TEL_FORM',
  }),
  pure,
);

const titleStyles = {
  mr: 20,
  width: 150,
  fontSize: 12,
  fontWeight: 'bold',
  color: G.getTheme('colors.dark.blue'),
};

const textStyles = {
  mr: 20,
  width: 150,
  fontSize: 12,
};

const BookPricesSection = (props: Object) => {
  const {
    values,
    rePost,
    telsInfo,
    setValues,
    handleChange,
  } = props;

  const source = G.getPropFromObject(GC.FIELD_SOURCE, values);
  const tels = G.getPropFromObject(GC.SYSTEM_LIST_TELS, values);
  const disabledTruckerToolsFields = G.notContain(GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS, source);
  const hasParadeFields = R.includes(GC.EXTERNAL_LOAD_BOARD_PARADE, source);
  const isNotDisabledTruckerToolsFields = G.isFalse(disabledTruckerToolsFields);

  const handleDisableFields = () => rePost;
  const handleDisableTruckerToolsFields = () => disabledTruckerToolsFields;

  const allChecked = R.compose(
    R.all(G.isTrue),
    R.map(R.path([GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER])),
  )(tels);

  const handleToggleAllPublishToCarrierCheckBox = (event: Object) => {
    const checked = R.path(['currentTarget', 'checked'], event);
    const newValues = R.map(R.assoc(GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER, checked), tels);
    setValues(R.assoc(GC.SYSTEM_LIST_TELS, newValues, values));
  };

  return (
    <Box
      pb={20}
      mt={10}
      maxHeight={400}
      maxWidth='90vw'
      overflowY='auto'
      border='1px solid'
      borderRadius='5px'
      borderColor={G.getTheme('colors.veryLightGrey')}
    >
      <StickedFlex
        p={10}
        top='0'
        zIndex={12}
        width='max-content'
        borderTopLeftRadius='5px'
        borderTopRightRadius='5px'
        bg={G.getTheme('colors.whiteGrey')}
      >
        {
          isNotDisabledTruckerToolsFields &&
          <Flex {...titleStyles} width={140}>
            <Checkbox
              id='all'
              type='checkbox'
              checked={allChecked}
              disabled={disabledTruckerToolsFields}
              onChange={handleToggleAllPublishToCarrierCheckBox}
            />
            <Box ml={10}>
              {G.getWindowLocale('titles:publish-to-carrier', 'Publish to Carrier')}
            </Box>
          </Flex>
        }
        <Box {...titleStyles} width={100}>{G.getWindowLocale('titles:tel-number', 'TEL #')}</Box>
        <Box {...titleStyles}>{G.getWindowLocale('titles:origin', 'Origin')}</Box>
        <Box {...titleStyles}>{G.getWindowLocale('titles:destination', 'Destination')}</Box>
        <Box {...titleStyles}>{G.getWindowLocale('titles:rate', 'Rate')}</Box>
        <Box {...titleStyles}>{G.getWindowLocale('titles:equipment', 'Equipment')}</Box>
        <Box {...titleStyles}>{G.getWindowLocale('titles:length', 'Length')}</Box>
        <Box {...titleStyles}>{G.getWindowLocale('titles:length-uom', 'Length UOM')}</Box>
        {
          hasParadeFields &&
          <Box {...titleStyles}>{G.getWindowLocale('titles:start-price', 'Start Price')}</Box>
        }
        {
          hasParadeFields &&
          <Box {...titleStyles} mr='0px'>{G.getWindowLocale('titles:max-price', 'Max Price')}</Box>
        }
      </StickedFlex>
      {
        telsInfo.map((item: Object, index: number) => {
          const { lastEvent, firstEvent, primaryReference, primaryReferenceValue } = item;

          const telNumber = R.pathOr(primaryReferenceValue, [GC.FIELD_VALUE], primaryReference);
          const origin = G.concatLocationFields(G.getPropFromObject(GC.SYSTEM_OBJECT_LOCATION, firstEvent));
          const destination = G.concatLocationFields(G.getPropFromObject(GC.SYSTEM_OBJECT_LOCATION, lastEvent));
          const publishToCarrierFieldName = `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER}`;
          const publishToCarrierPath = R.split('.', publishToCarrierFieldName);

          return (
            <Flex mt={20} px={10} key={index} width='max-content'>
              {
                isNotDisabledTruckerToolsFields &&
                <Box mr={20} width={140}>
                  <Checkbox
                    type='checkbox'
                    onChange={handleChange}
                    id={publishToCarrierFieldName}
                    disabled={disabledTruckerToolsFields}
                    checked={R.path(publishToCarrierPath, values)}
                  />
                </Box>
              }
              <TextComponent
                {...textStyles}
                width={100}
                maxWidth={100}
                title={telNumber}
                withEllipsis={true}
                display='inline-block'
              >
                {telNumber}
              </TextComponent>
              <TextComponent
                {...textStyles}
                maxWidth={150}
                title={origin}
                withEllipsis={true}
                display='inline-block'
              >
                {origin}
              </TextComponent>
              <TextComponent
                {...textStyles}
                maxWidth={150}
                title={destination}
                withEllipsis={true}
                display='inline-block'
              >
                {destination}
              </TextComponent>
              <Fieldset2
                {...G.getFormikProps(props)}
                fields={getBookPriceFieldSettings(index, source)}
                handlers={{ handleDisableFields, handleDisableTruckerToolsFields }}
              />
            </Flex>
          );
        })
      }
    </Box>
  );
};

const Form = (props: Object) => {
  const { isEdit, rePost, telsInfo, loadboards } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <Box>
        <Fieldset2
          {...G.getFormikProps(props)}
          handlers={{ handleDisableSource: () => isEdit }}
          fields={sourceField(R.map(({ type }: Object) => type, loadboards))}
        />
        <BookPricesSection {...G.getFormikProps(props)} rePost={rePost} telsInfo={telsInfo} />
      </Box>
      <FormFooter2 boxStyles={{ mt: 25 }} />
    </form>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loadboards: makeSelectPostLoadBoards(state),
  requiredMinMaxPrice: makeSelectRequiredMinMaxPrice(state),
});

const PostTelToLoadboardsForm = connect(mapStateToProps, { exportTelToLoadBoard })(enhance((props: Object) =>
  <Form {...props} rePost={false} />,
));

const postTelToLoadboardsWithAsyncTELDataEnhance = compose(
  withAsyncGetDetailsForPostLoad,
  withProps(({ detailsForPostLoad }: Object) => {
    const telsPostData = R.map(
      (item: Object) => ({
        ...item,
        rateCurrency: GC.CURRENCY_TYPE_USD,
      }),
      R.or(detailsForPostLoad, []),
    );

    const telsInfo = R.map(({ events, primaryReferenceValue }: Object) => ({
      primaryReferenceValue,
      [GC.SYSTEM_OBJECT_LAST_EVENT]: {
        [GC.SYSTEM_OBJECT_LOCATION]: R.path([GC.SYSTEM_OBJECT_LOCATION], R.head(events)),
      },
      [GC.SYSTEM_OBJECT_FIRST_EVENT]: {
        [GC.SYSTEM_OBJECT_LOCATION]: R.path([GC.SYSTEM_OBJECT_LOCATION], R.last(events)),
      },
    }),
      detailsForPostLoad,
    );

    return { telsPostData, telsInfo };
  }),
  pure,
);

export const PostTelToLoadboardsWithAsyncTELData = postTelToLoadboardsWithAsyncTELDataEnhance((props: Object) => (
  <LocalLoader minWidth={600} localLoaderOpen={G.isNilOrEmpty(props.detailsForPostLoad)}>
    <PostTelToLoadboardsForm {...props} />
  </LocalLoader>
));

export default PostTelToLoadboardsForm;
