import React from 'react';
import { pure } from 'react-recompose';
// forms
import { FormSection } from '../../formik/ui';
import { Fieldset2 } from '../../formik/fieldset2/fieldset';
// helpers
import * as G from '../../../helpers';
// settings
import {
  billToSectionSettings,
  remitToContactSettings,
  remitToSectionSettings,
  getBillToContactSettings,
} from '../../../settings';
//////////////////////////////////////////////////

export const BillToSection = pure((props: Object) => (
  <FormSection p={15}>
    <Fieldset2
      {...props}
      fields={billToSectionSettings}
      fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
    />
    <Fieldset2
      {...props}
      fields={getBillToContactSettings()}
      fieldsWrapperStyles={{ justifyContent: 'space-between' }}
    />
  </FormSection>
));

export const RemitToSection = pure((props: Object) => (
  <FormSection p={15}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={remitToSectionSettings}
      fieldsWrapperStyles={{ mt: 15, justifyContent: 'space-between' }}
    />
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={remitToContactSettings}
      fieldsWrapperStyles={{ justifyContent: 'space-between' }}
    />
  </FormSection>
));
