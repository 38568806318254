import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withProps, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { LocalLoader } from '../../../components/local-loader';
import { AdditionalCharge } from '../../../components/charge/do-new-charge';
// forms
import { Fieldset2 } from '../../formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
import {
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncGetSelectedRateByLoadGuid,
} from '../../../hocs';
// ui
import { Box, Flex, ActionButton } from '../../../ui';
// cancel clo
import {
  cancelReasonField,
  defaultChargeFields,
  defaultCancelCLOFields,
} from './field-settings';
//////////////////////////////////////////////////

const configsNamesArray = [
  GC.CLO_GENERAL_LOAD_CANCEL_REASON,
  GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_ACCESSORIAL,
  GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_CUSTOMER_RATE,
  GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_DRIVER_CARRIER_RATE,
];

const chargesEnhance = compose(
  withState(
    'isOpenedChargeSections',
    'setIsOpenedChargeSections',
    {
      telInvoiceCharge: false,
      cloInvoiceCharge: false,
    },
  ),
  withHandlers({
    handleRemoveAccessorials: (props: Object) => (sectionName: string) => {
      const { setFieldValue, isOpenedChargeSections, setIsOpenedChargeSections } = props;

      setFieldValue(sectionName, null);
      setIsOpenedChargeSections(R.assoc(sectionName, false, isOpenedChargeSections));
    },
    handleAddChargeComment: ({ setFieldValue }: Object) => ({ id, comments }: Object) =>
      setFieldValue(`${id}.${GC.FIELD_COMMENTS}`, comments),
    handleChangeChargeSelect: (props: Object) => (event: Object) => {
      const { values, setFieldValue, accessorialsConfigs } = props;

      const name = R.path(['currentTarget', GC.FIELD_NAME], event);
      const value = R.path(['currentTarget', GC.FIELD_VALUE], event);
      const accessorial = R.find(R.pathEq(value, [GC.FIELD_DISPLAYED_VALUE]), accessorialsConfigs);
      const accessorialFields = R.compose(
        G.renameKeys({ [GC.FIELD_ORIGINAL_CONFIG_GUID]: GC.FIELD_ACCESSORIAL_CONFIG_GUID }),
        R.pick(R.keys(defaultChargeFields)),
      )(R.or(accessorial, {}));
      const sectionName = R.compose(
        R.head,
        R.split('.'),
      )(name);
      const newValues = R.mergeRight(R.prop(sectionName, values), accessorialFields);

      return setFieldValue(sectionName, newValues);
    },
    handleChangeCharge: (props: Object) => (event: Object) => {
      const { values, setFieldValue } = props;

      const name = R.path(['currentTarget', GC.FIELD_NAME], event);
      const value = R.path(['currentTarget', GC.FIELD_VALUE], event);
      const splittedFieldName = R.split('.', name);
      const fieldName = R.last(splittedFieldName);
      const sectionName = R.head(splittedFieldName);

      if (R.equals(fieldName, GC.FIELD_RATE)) {
        const total = value;
        const chargeValues = R.mergeRight(
          R.prop(sectionName, values),
          { total, [fieldName]: value },
        );

        return setFieldValue(sectionName, chargeValues);
      }
    },
    handleOpenChargeSection: (props: Object) => (sectionName: string) => {
      const {
        asyncConfigs,
        setFieldValue,
        accessorialsConfigs,
        isOpenedChargeSections,
        setIsOpenedChargeSections,
      } = props;

      const chargeSectionNameValue = R.prop(sectionName, isOpenedChargeSections);
      const newChargeSectionsState = R.assoc(sectionName, R.not(chargeSectionNameValue), isOpenedChargeSections);

      if (G.isTrue(chargeSectionNameValue)) return;

      setIsOpenedChargeSections(newChargeSectionsState);
      const loadType = G.ifElse(
        R.equals(sectionName, 'cloInvoiceCharge'),
        GC.FIELD_CLO,
        GC.FIELD_TEL,
      );
      const defaultAccessorialGuid = G.getConfigValueFromStore(
        GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_ACCESSORIAL,
        asyncConfigs,
      );
      const defaultAccessorial = R.compose(
        R.path([defaultAccessorialGuid]),
        R.indexBy(R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID)),
      )(R.or(accessorialsConfigs, []));
      const accessorialFields = R.compose(
        G.renameKeys({ [GC.FIELD_ORIGINAL_CONFIG_GUID]: GC.FIELD_ACCESSORIAL_CONFIG_GUID }),
        R.pick(R.keys(defaultChargeFields)),
      )(R.or(defaultAccessorial, R.head(accessorialsConfigs)));
      const getCLOChargeRateFromConfigs = G.getConfigValueFromStore(
        GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_CUSTOMER_RATE,
        asyncConfigs,
      );
      const getTELChargeRateFromConfigs = G.getConfigValueFromStore(
        GC.CLO_GENERAL_CANCEL_CLO_DEFAULT_CHARGE_DRIVER_CARRIER_RATE,
        asyncConfigs,
      );
      const rate = G.ifElse(
        R.equals(loadType, GC.FIELD_CLO),
        getCLOChargeRateFromConfigs,
        getTELChargeRateFromConfigs,
      );
      const chargeState = {
        ...defaultChargeFields,
        ...accessorialFields,
        rate,
        id: sectionName,
        [GC.FIELD_TOTAL]: rate,
        [GC.FIELD_TYPE]: GC.CHARGE_TYPE_CANCEL_CLO,
      };

      return setFieldValue(sectionName, chargeState);
    },
  }),
  withChargeComments('simple'),
  pure,
);

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const buttonStyles = {
  px: '8px',
  height: 20,
  fontSize: 12,
  type: 'button',
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const chargeTitleStyles = {
  mt: 10,
  p: '5px',
  color: blueColor,
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
  bg: G.getTheme('colors.whiteGrey'),
};

const getAccessorialLabel = (item: Object) => G.ifElse(
  G.isTrue(R.prop(GC.FIELD_CHARGE_INTERNAL_EXPENSE, item)),
  `${R.prop(GC.FIELD_DISPLAYED_VALUE, item)} (${G.getWindowLocale('titles:internal-expense', 'Internal Expense')})`,
  R.prop(GC.FIELD_DISPLAYED_VALUE, item),
);

const AdditionalChargesSection = chargesEnhance((props: Object) => {
  const {
    values,
    selectedCLORate,
    selectedTELRate,
    handleChangeCharge,
    accessorialsConfigs,
    isOpenedChargeSections,
    handleAddChargeComment,
    handleOpenChargeSection,
    handleOpenChargeComment,
    handleRemoveAccessorials,
    handleChangeChargeSelect,
  } = props;

  const { cloInvoiceCharge, telInvoiceCharge } = isOpenedChargeSections;
  const accessorialOptions = R.compose(
    R.map((item: Object) => ({
      [GC.FIELD_LABEL]: getAccessorialLabel(item),
      [GC.FIELD_VALUE]: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
    })),
    R.filter(R.pathEq(GC.CHARGE_RATE_TYPE_FLAT, [GC.FIELD_CHARGE_RATE_TYPE])),
  )(R.or(accessorialsConfigs, []));

  return (
    <Box>
      <Flex p='10px 20px' width={340} justifyContent='space-between'>
        {
          R.and(G.isNotNilAndNotEmpty(selectedCLORate), G.isFalse(cloInvoiceCharge)) &&
          <ActionButton
            {...buttonStyles}
            onClick={() => handleOpenChargeSection('cloInvoiceCharge')}
          >
            {G.getWindowLocale('titles:add-clo-charge', 'Add CLO Charge')}
          </ActionButton>
        }
        {
          R.and(G.isNotNilAndNotEmpty(selectedTELRate), G.isFalse(telInvoiceCharge)) &&
          <ActionButton
            {...buttonStyles}
            onClick={() => handleOpenChargeSection('telInvoiceCharge')}
          >
            {G.getWindowLocale('titles:add-tel-charge', 'Add TEL Charge')}
          </ActionButton>
        }
      </Flex>
      <Box>
        {
          G.isTrue(cloInvoiceCharge) &&
          <Box>
            <Box {...chargeTitleStyles}>
              {G.getWindowLocale('titles:clo-charge', 'CLO Charge')}
            </Box>
            <Box p={15}>
              <AdditionalCharge
                loadType={GC.FIELD_CLO}
                keyToStateHead='cloInvoiceCharge'
                chargeState={values.cloInvoiceCharge}
                handleChangeCharge={handleChangeCharge}
                noFuelSelectOptions={accessorialOptions}
                handleAddChargeComment={handleAddChargeComment}
                handleOpenChargeComment={handleOpenChargeComment}
                handleChangeChargeSelect={handleChangeChargeSelect}
                handleRemoveAccessorials={handleRemoveAccessorials}
              />
            </Box>
          </Box>
        }
      </Box>
      <Box>
        {
          G.isTrue(telInvoiceCharge) &&
          <Box>
            <Box {...chargeTitleStyles}>
              {G.getWindowLocale('titles:tel-charge', 'TEL Charge')}
            </Box>
            <Box p={15}>
              <AdditionalCharge
                loadType={GC.FIELD_TEL}
                keyToStateHead='telInvoiceCharge'
                chargeState={values.telInvoiceCharge}
                handleChangeCharge={handleChangeCharge}
                noFuelSelectOptions={accessorialOptions}
                handleAddChargeComment={handleAddChargeComment}
                handleOpenChargeComment={handleOpenChargeComment}
                handleChangeChargeSelect={handleChangeChargeSelect}
                handleRemoveAccessorials={handleRemoveAccessorials}
              />
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
});

const enhance = compose(
  withProps(() => ({ configsNamesArray })),
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncGetSelectedRateByLoadGuid,
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const { telInvoiceCharge, cloInvoiceCharge } = values;

      let data = values;

      if (G.isNotNilAndNotEmpty(telInvoiceCharge)) {
        const telInvoiceChargeWithCorrectChargeType = R.assoc(
          GC.FIELD_TYPE,
          GC.CHARGE_TYPE_ADDITIONAL,
          telInvoiceCharge,
        );
        data = R.assoc('telInvoiceCharge', telInvoiceChargeWithCorrectChargeType, data);
      }

      if (G.isNotNilAndNotEmpty(cloInvoiceCharge)) {
        const cloInvoiceChargeWithCorrectChargeType = R.assoc(
          GC.FIELD_TYPE,
          GC.CHARGE_TYPE_ADDITIONAL,
          cloInvoiceCharge,
        );
        data = R.assoc('cloInvoiceCharge', cloInvoiceChargeWithCorrectChargeType, data);
      }

      submitAction(data);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCancelCLOFields,
      initialValues,
    ),
  }),
);

const CancelCLOForm = (props: Object) => {
  const {
    branchGuid,
    handleSubmit,
    asyncConfigs,
    selectedCLORate,
    selectedTELRate,
    accessorialsConfigs,
  } = props;

  const loadCancelReasons = R.map(
    (item: Object) => ({
      [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
      [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
    }),
    G.getFullOptionsFromDropdownConfigStore(asyncConfigs, GC.CLO_GENERAL_LOAD_CANCEL_REASON),
  );

  return (
    <LocalLoader minWidth={340} localLoaderOpen={G.isNilOrEmpty(asyncConfigs)}>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={cancelReasonField}
          loadCancelReasons={loadCancelReasons}
        />
        <AdditionalChargesSection
          {...G.getFormikProps(props)}
          branchGuid={branchGuid}
          asyncConfigs={asyncConfigs}
          selectedCLORate={selectedCLORate}
          selectedTELRate={selectedTELRate}
          accessorialsConfigs={accessorialsConfigs}
        />
        <FormFooter2 boxStyles={{ p: '10px 20px 25px 20px' }} />
      </form>
    </LocalLoader>
  );
};

export default enhance(CancelCLOForm);
