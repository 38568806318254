import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  STATE_OPTIONS,
  COUNTRY_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
  DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const generalInputWrapperStyles = {
  mb: 25,
  width: 230,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

const isRequired = ({ isTemplateForm }: Object) => R.not(isTemplateForm);

export const generalFieldSettings = [
  {
    type: 'select',
    isRequired: true,
    label: ['titles:template-type'],
    fieldName: GC.FIELD_TEMPLATE_TYPE,
    inputWrapperStyles: {
      ...generalInputWrapperStyles,
      display: ({ isTemplateForm }: Object) => G.ifElse(isTemplateForm, 'block', 'none'),
    },
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        [GC.FIELD_VALUE]: 'DRIVER',
        [GC.FIELD_LABEL]: G.getWindowLocale('titles:driver', 'Driver'),
      },
      {
        [GC.FIELD_VALUE]: 'VENDOR',
        [GC.FIELD_LABEL]: G.getWindowLocale('titles:vendor', 'Vendor'),
      },
    ],
  },
  {
    type: 'reactSelect',
    shouldCustomChange: true,
    label: ['titles:template'],
    fieldName: GC.FIELD_TEMPLATE_NAME,
    options: 'compensationTemplateOptions',
    inputWrapperStyles: {
      ...generalInputWrapperStyles,
      zIndex: 14,
      display: ({ isTemplateForm }: Object) => G.ifElse(isTemplateForm, 'none', 'block'),
    },
    customChangeHandler: (guid: string, _: Object, props: Object) => {
      const { values, resetForm, setValues, isEditMode, primaryObjectGuidKey, compensationTemplateList } = props;

      if (R.isNil(guid)) return resetForm();

      const selectedTemplate = R.compose(
        R.omit([GC.FIELD_GUID, GC.FIELD_TEMPLATE_TYPE]),
        R.prop(guid),
      )(compensationTemplateList);

      const primaryObjectGuid = R.pathOr(null, [primaryObjectGuidKey], values);

      let newValues = {
        ...selectedTemplate,
        primaryObjectGuid,
        [primaryObjectGuidKey]: primaryObjectGuid,
        [GC.FIELD_MODE]: R.pathOr('', [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], selectedTemplate),
        [GC.FIELD_SERVICE_TYPE]: R.pathOr('', [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], selectedTemplate),
      };

      if (G.isTrue(isEditMode)) {
        newValues = R.mergeRight(newValues, R.pick([GC.FIELD_GUID, GC.FIELD_VERSION], values));
      }

      setValues(newValues);
    },
  },
  {
    isRequired: true,
    label: ['titles:name'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_NAME,
    inputWrapperStyles: generalInputWrapperStyles,
  },
  {
    isRequired,
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    label: ['titles:effective-date'],
    inputWrapperStyles: generalInputWrapperStyles,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    isRequired,
    isClearable: true,
    type: 'datePicker',
    calendarInputWrapperStyles,
    minDate: G.getCurrentDate(),
    label: ['titles:expiration-date'],
    inputWrapperStyles: generalInputWrapperStyles,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
];

const zoneInputWrapperStyles = {
  mb: 25,
  width: 220,
};

export const zoneFieldSettings = (prefix: string) => [
  {
    type: 'addressAutocomplete',
    label: ['titles:search-city'],
    fieldName: `${prefix}.searchCity`,
    inputWrapperStyles: { ...zoneInputWrapperStyles, zIndex: 13 },
  },
  {
    type: 'multiItems',
    label: ['titles:cities'],
    inputWrapperStyles: zoneInputWrapperStyles,
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`,
    placeholder: G.getWindowLocale('titles:enter-city-and-press-enter', 'Enter City and Press Enter'),
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: STATE_OPTIONS,
    label: ['titles:states'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`,
    inputWrapperStyles: { ...zoneInputWrapperStyles, zIndex: 12 },
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: COUNTRY_OPTIONS,
    label: ['titles:countries'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`,
    inputWrapperStyles: { ...zoneInputWrapperStyles, zIndex: 12 },
  },
  {
    type: 'addressAutocomplete',
    label: ['titles:search-zip'],
    fieldName: `${prefix}.searchZip`,
    inputWrapperStyles: zoneInputWrapperStyles,
  },
  {
    isMulti: true,
    type: 'multiItems',
    label: ['titles:zip-codes'],
    inputWrapperStyles: zoneInputWrapperStyles,
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`,
    placeholder: G.getWindowLocale('titles:enter-zip-and-press-enter', 'Enter Zip and Press Enter'),
  },
  {
    label: ['titles:zip-from'],
    inputWrapperStyles: { width: 220 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`,
  },
  {
    label: ['titles:zip-to'],
    inputWrapperStyles: { width: 220 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`,
  },
];

export const compensationDetailsFieldSettings = [
  {
    isRequired,
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:rate'],
    fieldName: GC.FIELD_CHARGE_RATE,
  },
  {
    isRequired,
    type: 'select',
    inputWrapperStyles,
    label: ['titles:currency'],
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
  },
  {
    isRequired,
    type: 'select',
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    options: DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:rate-unit'],
    isRequired: ({ values, isTemplateForm }: Object) => {
      if (isTemplateForm) return false;

      const rateType = G.getPropFromObject(GC.FIELD_CHARGE_RATE_TYPE, values);

      return R.and(G.isNotNilAndNotEmpty(rateType), G.notEquals(rateType, GC.CHARGE_RATE_TYPE_FLAT));
    },
    options: GC.FIELD_CHARGE_RATE_UNIT,
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    optionsGroups: DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:rate-minimum'],
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
    disabled: (field: any, values: Object) => R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, values),
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:rate-maximum'],
    fieldName: GC.FIELD_CHARGE_MAX_RATE,
    disabled: (field: any, values: Object) => R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, values),
  },
  {
    isRequired,
    type: 'select',
    inputWrapperStyles,
    label: ['titles:mode'],
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:transit-days'],
    fieldName: GC.FIELD_TRANSIT_DAYS,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:service-type'],
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
  },
  {
    type: 'toggle',
    label: ['titles:get-fuel-from-order'],
    fieldName: GC.FIELD_GET_FUEL_FROM_ORDER,
  },
  {
    type: 'toggle',
    inputWrapperStyles: { width: 'calc(100% - 170px)' },
    label: ['titles:add-additional-charges-from-order'],
    fieldName: GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER,
  },
];

const weightRangeFieldSettings = [
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:min-weight'],
    fieldName: GC.FIELD_MIN_WEIGHT,
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:max-weight'],
    fieldName: GC.FIELD_MAX_WEIGHT,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:weight-uom'],
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  },
];

const temperatureRangeFieldSettings = [
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:min-temperature'],
    fieldName: GC.FIELD_MIN_TEMPERATURE,
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:max-temperature'],
    fieldName: GC.FIELD_MAX_TEMPERATURE,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:temperature-uom'],
    options: ITEM_TEMPERATURE_OPTIONS,
    fieldName: GC.FIELD_TEMPERATURE_UOM,
  },
];

const quantityRangeFieldSettings = [
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:min-quantity'],
    fieldName: GC.FIELD_MIN_QUANTITY,
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:max-quantity'],
    fieldName: GC.FIELD_MAX_QUANTITY,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:package-type'],
    fieldName: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  },
];

const distanceRangeFieldSettings = [
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:distance-range-from'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:distance-range-to'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:distance-range-uom'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM,
    options: G.addEmptyOptionToDropDown(DEFAULT_DISTANCE_UOM_OPTIONS),
  },
];

export const compensationRangeSections = [
  {
    title: 'titles:weight-range',
    sectionName: GC.FIELD_WEIGHT,
    fieldSettings: weightRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), weightRangeFieldSettings),
  },
  {
    title: 'titles:distance-range',
    sectionName: GC.FIELD_DISTANCE,
    fieldSettings: distanceRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), distanceRangeFieldSettings),
  },
  {
    title: 'titles:quantity-range',
    sectionName: GC.FIELD_QUANTITY,
    fieldSettings: quantityRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), quantityRangeFieldSettings),
  },
  {
    title: 'titles:temperature-range',
    sectionName: GC.FIELD_TEMPERATURE,
    fieldSettings: temperatureRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), temperatureRangeFieldSettings),
  },
];

const defaultWeightRangeFields = {
  [GC.FIELD_WEIGHT_UOM]: '',
  [GC.FIELD_MIN_WEIGHT]: '',
  [GC.FIELD_MAX_WEIGHT]: '',
};

const defaultDistanceRangeFields = {
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: '',
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: '',
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: '',
};

const defaultTemperatureRangeFields = {
  [GC.FIELD_MIN_TEMPERATURE]: '',
  [GC.FIELD_MAX_TEMPERATURE]: '',
  [GC.FIELD_TEMPERATURE_UOM]: '',
};

const defaultQuantityRangeFields = {
  [GC.FIELD_MIN_QUANTITY]: '',
  [GC.FIELD_MAX_QUANTITY]: '',
  [GC.FIELD_PACKAGE_TYPE]: '',
};

export const defaultRangeGroupFields = {
  [GC.FIELD_WEIGHT]: defaultWeightRangeFields,
  [GC.FIELD_DISTANCE]: defaultDistanceRangeFields,
  [GC.FIELD_QUANTITY]: defaultQuantityRangeFields,
  [GC.FIELD_TEMPERATURE]: defaultTemperatureRangeFields,
};

const zoneSchema = {
  [GC.FIELD_DRIVER_COMPENSATION_COUNTRIES]: G.yupArrayRequired,
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
};

const minMaxNumberValidation = Yup.number()
  .nullable(true)
  .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
  .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999));

export const getValidationSchemaObject = (props: Object) => Yup.lazy((values: Object) => {
  const { saveAsTemplate = false, isTemplateForm = false } = props;

  const {
    rateType,
    minWeight,
    maxWeight,
    minQuantity,
    maxQuantity,
    minTemperature,
    maxTemperature,
    distanceRangeTo,
    distanceRangeFrom,
  } = values;

  const isTemplate = R.or(saveAsTemplate, isTemplateForm);

  const commonValidation = G.ifElse(isTemplate, G.yupStringNotRequired, G.yupStringRequired);

  let schema = {
    [GC.FIELD_MODE]: commonValidation,
    [GC.FIELD_EFFECTIVE_DATE]: commonValidation,
    [GC.FIELD_EXPIRATION_DATE]: commonValidation,
    [GC.FIELD_TRANSIT_DAYS]: Yup.number()
      .nullable(true)
      .min(0, G.getShouldBeFromToCharLocaleTxt(0, 99))
      .max(99, G.getShouldBeFromToCharLocaleTxt(0, 99)),
    [GC.FIELD_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(85, G.getShouldBeFromToCharLocaleTxt(0, 85)),
    [GC.FIELD_ORIGIN]: Yup.object().shape(zoneSchema),
    [GC.FIELD_DESTINATION]: Yup.object().shape(zoneSchema),
    // range
    [GC.FIELD_MIN_WEIGHT]: minMaxNumberValidation,
    [GC.FIELD_MAX_WEIGHT]: minMaxNumberValidation,
    [GC.FIELD_MIN_QUANTITY]: minMaxNumberValidation,
    [GC.FIELD_MAX_QUANTITY]: minMaxNumberValidation,
    [GC.FIELD_MIN_TEMPERATURE]: minMaxNumberValidation,
    [GC.FIELD_MAX_TEMPERATURE]: minMaxNumberValidation,
    [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: minMaxNumberValidation,
    [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: minMaxNumberValidation,
    // rate
    [GC.FIELD_CURRENCY]: commonValidation,
    [GC.FIELD_CHARGE_RATE_TYPE]: commonValidation,
    [GC.FIELD_CHARGE_MIN_RATE]: minMaxNumberValidation,
    [GC.FIELD_CHARGE_MAX_RATE]: minMaxNumberValidation,
    [GC.FIELD_RATE_UNIT]: G.ifElse(
      G.isAllTrue(
        R.not(isTemplate),
        G.isNotNilAndNotEmpty(rateType),
        G.notEquals(rateType, GC.CHARGE_RATE_TYPE_FLAT),
      ),
      G.yupStringRequired,
      G.yupStringNotRequired,
    ),
    [GC.FIELD_CHARGE_RATE]: G.ifElse(isTemplate, G.yupNumberNotRequired, G.yupNumberRequired)
      .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
      .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999)),
  };

  if (isTemplateForm) {
    schema = R.assoc(GC.FIELD_TEMPLATE_TYPE, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([minWeight, maxWeight])) {
    schema = R.assoc(GC.FIELD_WEIGHT_UOM, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([minQuantity, maxQuantity])) {
    schema = R.assoc(GC.FIELD_PACKAGE_TYPE, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([minTemperature, maxTemperature])) {
    schema = R.assoc(GC.FIELD_TEMPERATURE_UOM, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([distanceRangeTo, distanceRangeFrom])) {
    schema = R.assoc(GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM, G.yupStringRequired, schema);
  }

  return Yup.object().shape(schema);
});

const defaultZoneValues = {
  [GC.FIELD_DRIVER_COMPENSATION_COUNTRIES]: null,
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO]: '',
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM]: '',
};

export const defaultValues = {
  ...defaultWeightRangeFields,
  ...defaultDistanceRangeFields,
  ...defaultQuantityRangeFields,
  ...defaultTemperatureRangeFields,
  [GC.FIELD_MODE]: '',
  [GC.FIELD_CURRENCY]: '',
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_TRANSIT_DAYS]: '',
  [GC.FIELD_SERVICE_TYPE]: '',
  [GC.FIELD_CHARGE_MAX_RATE]: '',
  [GC.FIELD_CHARGE_MIN_RATE]: '',
  [GC.FIELD_CHARGE_RATE_TYPE]: '',
  [GC.FIELD_CHARGE_RATE_UNIT]: '',
  [GC.FIELD_GET_FUEL_FROM_ORDER]: false,
  [GC.FIELD_DRIVER_COMPENSATION_NAME]: '',
  [GC.FIELD_ORIGIN]: { ...defaultZoneValues },
  [GC.FIELD_DESTINATION]: { ...defaultZoneValues },
  [GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE]: '',
  [GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE]: '',
  [GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER]: false,
};

