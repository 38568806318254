import React from 'react';
import * as R from 'ramda';
import { pure, compose, withProps } from 'react-recompose';
// components
import { LocalLoader } from '../../components/local-loader';
// forms
import { SelectDropdownForm } from './select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncConfigs, withAsyncGetTransportationModeGrouping } from '../../hocs';
//////////////////////////////////////////////////

const transformListGuidsToRequest = (values: Array, fullServicesOptions: Array) => {
  const indexedOptions = G.indexMappedDropdownOptionsByField(
    GC.FIELD_DROPDOWN_OPTION_GUID,
    G.mapDropdownConfigOptionsToObjects,
    fullServicesOptions,
  );
  const services = R.map(
    (item: string) => R.prop(item, indexedOptions),
    values,
  );

  return { services };
};

const enhance = compose(
  withProps(({ loadConfigs }: Object) => ({
    configsNamesArray: G.ifElse(R.isNil(loadConfigs), R.of(Array, GC.GENERAL_SERVICES)),
  })),
  withAsyncConfigs,
  withAsyncGetTransportationModeGrouping,
  pure,
);

const AsyncComponent = enhance((props: Object) => {
  const {
    loadConfigs,
    asyncConfigs,
    submitAction,
    transportationModeGroupingList,
    customerRateTransportationModeGuid,
  } = props;

  const configs = R.pathOr(asyncConfigs, ['configsByNames'], loadConfigs);

  const services = R.map(
    R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
    R.propOr([], 'defaultServices', props),
  );

  const { serviceOptions } = G.makeTransportationGroupingOptions(
    customerRateTransportationModeGuid,
    transportationModeGroupingList,
    configs,
    { services },
  );

  const fullServicesOptions = R.path(['dropdowns', GC.GENERAL_SERVICES, 'options'], configs);

  const initialValues = {
    option: services,
  };

  return (
    <LocalLoader localLoaderOpen={R.isNil(configs)}>
      <SelectDropdownForm
        isMulti={true}
        fieldWidth={350}
        optionRequired={false}
        options={serviceOptions}
        initialValues={initialValues}
        fieldLabel={G.getWindowLocale('titles:services', 'Services')}
        submitAction={(value: Array) => (
          submitAction(transformListGuidsToRequest(value, fullServicesOptions))
        )}
      />
    </LocalLoader>
  );
});

export const AsyncLoadServicesForm = (props: Object) => (
  <AsyncComponent {...props} callback={props.getTransportationModeGroupingListSuccess} />
);
