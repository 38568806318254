// helpers/constants
import * as GC from '../../../constants';
/////////////////////////////////////////////

export const cancelReasonField = [{
  type: 'reactSelect',

  options: 'loadCancelReasons',
  fieldName: GC.FIELD_LOAD_CANCEL_REASON_GUID,
  label: ['titles:select-reason', 'Select Reason'],
  inputWrapperStyles: { width: 300, m: '25px 0 10px 20px' },
}];

export const defaultChargeFields = {
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_RATE]: null,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_DISPLAYED_VALUE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_SEQUENCE_NUM]: 0,
  [GC.FIELD_CHARGE_NON_TAXABLE]: null,
  [GC.FIELD_ORIGINAL_CONFIG_GUID]: null,
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: null,
  [GC.FIELD_TYPE]: GC.CHARGE_TYPE_CANCEL_CLO,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
};

export const defaultCancelCLOFields = {
  [GC.FIELD_GUID]: null,
  telInvoiceCharge: null,
  cloInvoiceCharge: null,
  [GC.FIELD_LOAD_CANCEL_REASON_GUID]: null,
};
