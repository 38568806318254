import * as R from 'ramda';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

const renderBorderColor = (props: Object) => {
  const { error, touched, additionalBorderColor, useAdditionalBorderColor } = props;

  if (R.and(error, touched)) {
    return G.getTheme('forms.inputs.borderColorErr');
  }

  if (G.isTrue(useAdditionalBorderColor)) return additionalBorderColor;

  return G.getTheme('forms.inputs.borderColor');
};

export {
  renderBorderColor,
};
