import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// forms
import { SelectLocation } from '../select-location';
import { LocationTemplateSearch } from '../template-search/components/location-template-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, RelativeWrapper } from '../../../ui';
// bill-remit-to
import BillToForm from './bill-to-form';
import RemitToForm from './remit-to-form';
//////////////////////////////////////////////////

const enhance = compose(
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withHandlers({
    handleSearchLocation: (props: Object) => () => {
      const { openModal, closeModal, setInitialValues } = props;

      const searchTemplateRequest = (location: Object) => {
        const contactWithPhoneExtension = R.append(
          GC.FIELD_PHONE_EXTENSION,
          GC.GROUPED_FIELDS.CONTACT_ARR,
        );

        const contact = R.compose(
          R.map((item: Object) => G.ifElse(R.isNil(item), '', item)),
          R.pickAll(contactWithPhoneExtension),
          R.pathOr({}, [GC.FIELD_CONTACTS, 0]),
        )(location);

        const email = R.path([GC.FIELD_CONTACTS, 0, GC.FIELD_EMAIL], location);
        const emails = G.ifElse(G.isNotNilAndNotEmpty(email), R.of(Array, email), []);
        const contactWithMultiEmails = R.assoc(GC.FIELD_EMAILS, emails, contact);

        const locationWithContact = R.compose(
          R.mergeRight(contactWithMultiEmails),
          R.map((item: Object) => G.ifElse(R.isNil(item), '', item)),
          R.pickAll(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR_2),
        )(location);

        setInitialValues((prev: Object) => R.mergeRight(prev, locationWithContact));

        closeModal();
      };

      const modalContent = (
        <LocationTemplateSearch
          {...G.getLocationTemplateSearchRequestProps(props)}
          closeModal={closeModal}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
    handleSelectLocation: (props: Object) => () => {
      const { openModal, closeModal, initialValues, setInitialValues } = props;

      const searchTemplateRequest = (location: Object) => {
        const values = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR_2, location);

        setInitialValues((prev: Object) => R.mergeRight(prev, values));
        closeModal();
      };

      const modalContent = (
        <SelectLocation
          closeModal={closeModal}
          initialValues={initialValues}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
  }),
  pure,
);

export const BillToComponent = enhance((props: Object) => {
  const {
    closeModal,
    initialValues,
    searchedValues,
    updateBillToRequest,
    handleSelectLocation,
    handleSearchLocation,
  } = props;

  return (
    <Box>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:bill-to', 'Bill To')}
      >
        <RelativeWrapper pt='4px' ml={10} onClick={handleSearchLocation}>
          {I.search(G.getTheme('icons.iconColorWhite'))}
        </RelativeWrapper>
        <RelativeWrapper pt='4px' ml={10} onClick={handleSelectLocation}>
          {I.location(G.getTheme('icons.iconColorWhite'))}
        </RelativeWrapper>
      </FormGroupTitleMultiple>
      <BillToForm
        closeModal={closeModal}
        initialValues={initialValues}
        searchedValues={searchedValues}
        updateBillToRequest={updateBillToRequest}
      />
    </Box>
  );
});

export const RemitToComponent = enhance((props: Object) => {
  const {
    closeModal,
    initialValues,
    searchedValues,
    updateRemitToRequest,
    handleSelectLocation,
    handleSearchLocation,
  } = props;

  return (
    <Box>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:remit-to', 'Remit To')}
      >
        <RelativeWrapper pt='4px' ml={10} onClick={handleSearchLocation}>
          {I.search(G.getTheme('icons.iconColorWhite'))}
        </RelativeWrapper>
        <RelativeWrapper pt='4px' ml={10} onClick={handleSelectLocation}>
          {I.location(G.getTheme('icons.iconColorWhite'))}
        </RelativeWrapper>
      </FormGroupTitleMultiple>
      <RemitToForm
        closeModal={closeModal}
        initialValues={initialValues}
        searchedValues={searchedValues}
        updateRemitToRequest={updateRemitToRequest}
      />
    </Box>
  );
});

export const withEditBillTo = withHandlers({
  handleClickEditBillTo: (props: Object) => (billToProps: Object) => {
    const { openModal, closeModal, entityName } = props;

    const divisionGuid = R.path(['divisionGuid'], entityName);
    const loadType = G.ifElse(R.equals(GC.FIELD_CLO, entityName), GC.LOAD_TYPE_CLO, GC.LOAD_TYPE_TEL);

    const modalContent = (
      <BillToComponent
        {...billToProps}
        loadType={loadType}
        openModal={openModal}
        closeModal={closeModal}
        divisionGuid={divisionGuid}
      />
    );

    const modal = {
      p: '0px',
      component: modalContent,
      options: {
        width: 670,
        height: 'auto',
        overflow: 'auto',
        maxHeight: '90vh',
      },
    };

    openModal(modal);
  },
  pure,
});

// TODO: check search logic like withEditBillTo
export const withEditRemitTo = compose(
  connect(null, { openLoader, closeLoader, openModal, closeModal }),
  withHandlers({
    handleClickEditRemitTo: (props: Object) => () => {
      const { openModal, closeModal, entityName, branchGuid, updateRemitToRequest } = props;

      const divisionGuid = R.path([entityName, 'divisionGuid'], props);
      const loadType = G.ifElse(R.equals(GC.FIELD_CLO, entityName), GC.LOAD_TYPE_CLO, GC.LOAD_TYPE_TEL);

      const modalContent = (
        <RemitToComponent
          loadType={loadType}
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          divisionGuid={divisionGuid}
          updateRemitToRequest={updateRemitToRequest}
          initialValues={R.pathOr({}, ['values', GC.SYSTEM_OBJECT_REMIT_TO], props)}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          width: 655,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
