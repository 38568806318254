import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const inputWrapperStyles = { width: 300 };

const defaultFormikValues = {
  'value': null,
};

const defaultValidationSchema = Yup.object().shape({
  value: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 1000))
    .max(1000, G.getShouldBeFromToLocaleTxt(0, 1000)),
});

const getFieldSettings = (loc: string, inputStyles: Object = {}) => ([
  {
    inputStyles: R.mergeRight({
      fontSize: 14,
      padding: '5px 10px',
    }, inputStyles),
    label: [loc],
    type: 'textarea',
    fieldName: 'value',
    inputWrapperStyles,
  },
]);

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: ({ validationSchema }: Object) => R.or(validationSchema, defaultValidationSchema),
    mapPropsToValues: ({ initialValues }: Object) => R.mergeRight(defaultFormikValues, initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitHandler } = props;

      const value = R.pathOr('', ['value'], values);

      submitHandler(value);
    },
    displayName: 'SINGLE_TEXTAREA_FORM',
  }),
  pure,
);

const SingleTextareaForm = (props: Object) => {
  const { handleChange, handleSubmit, labelLocale, inputStyles } = props;

  const fieldSettings = getFieldSettings(labelLocale, inputStyles);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fieldSettings}
        onChange={handleChange}
        fieldsWrapperStyles={{ mt: 10 }}
      />
      <FormFooter2 boxStyles={{ mt: 20 }} />
    </form>
  );
};

export default enhance(SingleTextareaForm);
