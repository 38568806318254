import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS,
} from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, CancelButton } from '../../../ui';
// feature shared-accessorials/
import GeoFencingZoneForm from './geo-fencing-zone-form';
import FuelIndexVariablesSection from './fuel-index-variables';
import {
  addressPoint,
  accessorialFieldSettings,
  defaultFuelIndexVariableFields,
  defaultAccessorialFieldSettings,
  getAccessorialValidationSchemaObject,
} from './field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultAccessorialFieldSettings,
      initialValues,
    ),
    validationSchema: ({ fromSharedAccessorialList }: Object) => Yup.lazy(({ useFuelIndex }: Object) => (
      Yup.object().shape(getAccessorialValidationSchemaObject(useFuelIndex, fromSharedAccessorialList))
    )),
    handleSubmit: (values: Object, { props }: Object) =>
      props.submitAction(R.filter(G.isNotNilAndNotEmpty, values)),
  }),
  withHandlers({
    handleChangeUseFuelIndex: ({ values, setValues }: Object) => () => {
      const { useFuelIndex } = values;

      if (useFuelIndex) {
        setValues(R.mergeRight(
          values,
          {
            [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: false,
            [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: [],
          },
        ));
      } else {
        setValues(R.mergeRight(
          values,
          {
            [GC.FIELD_CHARGE_RATE]: '',
            [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: true,
            [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: R.of(Array, defaultFuelIndexVariableFields()),
          },
        ));
      }
    },
    handleChangeRateType: (props: Object) => (event: Object) => {
      const { values, setValues } = props;

      const rateType = R.path(['currentTarget', GC.FIELD_VALUE], event);
      let newValues = R.mergeRight(
        values,
        {
          rateType,
          [GC.FIELD_MAX_STOPS]: '',
          [GC.FIELD_MIN_STOPS]: '',
        },
      );

      if (R.equals(rateType, GC.CHARGE_RATE_TYPE_FLAT)) {
        newValues = R.mergeRight(
          newValues,
          {
            [GC.FIELD_CHARGE_MIN_RATE]: '',
            [GC.FIELD_CHARGE_MAX_RATE]: '',
          },
        );
      }

      setValues(newValues);
    },
    handleChangeAccessorial: (props: Object) => (fieldValue: string) => {
      const { values, setValues, accessorialConfigList } = props;

      if (G.isNilOrEmpty(fieldValue)) {
        return setValues(R.mergeRight(
          values,
          {
            [GC.FIELD_MAX_STOPS]: '',
            [GC.FIELD_MIN_STOPS]: '',
            [GC.FIELD_DEDUCTION]: false,
            [GC.FIELD_NON_TAXABLE]: false,
            [GC.FIELD_FUEL_RELATED]: false,
            [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: null,
          },
        ));
      }

      let selectedAccessorialConfig = R.compose(
        R.mergeRight({ [GC.FIELD_MIN_STOPS]: '', [GC.FIELD_MAX_STOPS]: '' }),
        R.omit([GC.FIELD_DISPLAYED_VALUE, GC.FIELD_ORIGINAL_CONFIG_GUID]),
        R.prop(fieldValue),
      )(accessorialConfigList);

      if (R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, selectedAccessorialConfig)) {
        selectedAccessorialConfig = R.mergeRight(
          selectedAccessorialConfig,
          {
            [GC.FIELD_CHARGE_MIN_RATE]: '',
            [GC.FIELD_CHARGE_MAX_RATE]: '',
          },
        );
      }

      setValues(R.mergeRight(values, selectedAccessorialConfig));
    },
    handleOpenGeoFencingZone: (props: Object) => () => {
      const { values, setValues, openModal, closeModal, setFieldValue } = props;

      const zoneFilter = R.compose(
        G.isNotNilAndNotEmpty,
        R.filter(G.isNotNilAndNotEmpty),
        R.pick(R.keys(addressPoint)),
      );
      const submitAction = ({ origin, destination }: Object) => {
        const newValues = R.mergeRight(
          values,
          {
            [GC.FIELD_ORIGIN]: G.ifElse(zoneFilter(origin), origin, null),
            useGeoFencingZone: R.or(zoneFilter(origin), zoneFilter(destination)),
            [GC.FIELD_DESTINATION]: G.ifElse(zoneFilter(destination), destination, null),
          },
        );

        closeModal();
        setValues(newValues);
      };
      const cancelAction = ({ origin, destination }: Object) => {
        closeModal();
        setFieldValue('useGeoFencingZone', R.or(zoneFilter(origin), zoneFilter(destination)));
      };
      const initialValues = R.compose(
        R.filter(G.isNotNilAndNotEmpty),
        R.pick([GC.FIELD_ORIGIN, GC.FIELD_DESTINATION]),
      )(values);
      const component = (
        <GeoFencingZoneForm
          cancelAction={cancelAction}
          submitAction={submitAction}
          initialValues={initialValues}
        />
      );
      const options = {
        p: 15,
        component,
        options: {
          title: G.getAddTitle(['titles:address-points', 'Address Points']),
        },
      };

      openModal(options);
    },
  }),
  pure,
);

const getRateSectionFields = (props: Object) => {
  const { entity, isVendor } = props;

  const { rateSection, vendorRateSection, carrierRateSection } = accessorialFieldSettings;

  if (R.equals(entity, 'carrier')) return carrierRateSection;

  if (isVendor) return vendorRateSection;

  return rateSection;
};

const AccessorialForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      handleCustomChange={props.handleChangeAccessorial}
      fieldsWrapperStyles={{ mt: 15, mb: 25, width: 980 }}
      accessorialConfigOptions={props.accessorialConfigOptions}
      justifyContent={G.ifElse(R.propEq('fromSharedAccessorialList', true, props), 'space-between', null)}
      fields={accessorialFieldSettings.accessorialSection(
        R.pick(['isVendor', 'isDriver', 'fromSharedAccessorialList'], props),
      )}
    />
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={getRateSectionFields(props)}
      fieldsWrapperStyles={{ width: 980 }}
      handleCustomChange={props.handleChangeRateType}
      rateUnit={R.pathOr(
        R.of(Array, GC.EMPTY_OPTION_OBJECT),
        [R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props)],
        DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
      )}
    />
    <Flex>
      <Fieldset2
        {...G.getFormikProps(props)}
        flexGrow='unset'
        handleCustomChange={props.handleChangeUseFuelIndex}
        handleOpenGeoFencingZone={props.handleOpenGeoFencingZone}
        fields={accessorialFieldSettings.districtSection(
          props.fromSharedAccessorialList,
          R.path(['values', GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX], props),
        )}
      />
      {
        R.path(['values', 'useGeoFencingZone'], props) &&
        <Flex ml={20} mb={25} cursor='pointer' onClick={props.handleOpenGeoFencingZone}>
          <Box fontWeight='bold' color={G.getTheme('colors.light.blue')}>
            {G.getWindowLocale('titles:edit-geo-fencing-zone', 'Edit Geo Fencing Zone')}
          </Box>
          <Box ml={10}>
            {I.pencil()}
          </Box>
        </Flex>
      }
    </Flex>
    {
      R.path(['values', GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX], props) &&
      <FieldArray
        name={GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES}
        render={(arrayHelpers: Object) => (
          <FuelIndexVariablesSection
            {...arrayHelpers}
            openLoader={props.openLoader}
            closeLoader={props.closeLoader}
            importFuelIndexVariables={props.importFuelIndexVariables}
            handleImportFuelIndexVariables={props.handleImportFuelIndexVariables}
          />
        )}
      />
    }
    <FormFooter2
      submitBtnStyles={{
        display: G.ifElse(R.path(['values', 'shared'], props), 'none'),
      }}
    />
  </form>
);

export const DefaultAccessorialForm = enhance((props: Object) => {
  const {
    handleSubmit,
    handleChangeRateType,
    handleChangeAccessorial,
    accessorialConfigOptions,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        handleCustomChange={handleChangeAccessorial}
        accessorialConfigOptions={accessorialConfigOptions}
        fieldsWrapperStyles={{ mt: 15, mb: 25, width: 980 }}
        fields={accessorialFieldSettings.accessorialSection()}
        handlers={{ handleCustomChange: handleChangeAccessorial }}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ width: 980 }}
        handleCustomChange={handleChangeRateType}
        fields={accessorialFieldSettings.defaultOrderAccessorialSection}
        rateUnit={R.pathOr(
          R.of(Array, GC.EMPTY_OPTION_OBJECT),
          [R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props)],
          DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS,
        )}
      />
      <FormFooter2 />
    </form>
  );
});

export default enhance(AccessorialForm);
