import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const numberValidation = Yup.number()
  .nullable(true)
  .typeError('${type}') // eslint-disable-line
  .positive(G.getShouldBePositiveLocaleTxt())
  .min(1, G.getShouldBeFromToLocaleTxt(1, 999999))
  .max(999999, G.getShouldBeFromToLocaleTxt(1, 999999));

const numberValidationRequired = Yup.number()
  .nullable(true)
  .required(G.getRequiredLocaleTxt())
  .typeError('${type}') // eslint-disable-line
  .positive(G.getShouldBePositiveLocaleTxt())
  .min(1, G.getShouldBeFromToLocaleTxt(1, 999999))
  .max(999999, G.getShouldBeFromToLocaleTxt(1, 999999));

export const hasLengthFields = (source: array) => {
  const sourcesWithLengthFields = [
    GC.EXTERNAL_LOAD_BOARD_DAT,
    GC.EXTERNAL_LOAD_BOARD_PARADE,
    GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
    GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
  ];

  return R.any((item: string) => R.includes(item, sourcesWithLengthFields), source);
};

const getValidationSchema = (source: Array, requiredMinMaxPrice: boolean) => {
  const truckType = Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt());

  const lengthUom = Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt());

  const length = numberValidationRequired;

  const rate = numberValidationRequired;

  let tels = Yup.array().of(Yup.object({ rate, truckType, length, lengthUom }));

  if (R.includes(GC.EXTERNAL_LOAD_BOARD_PARADE, source)) {
    const maxPrice = G.ifElse(
      G.isTrue(requiredMinMaxPrice),
      numberValidationRequired,
      numberValidation,
    );
    const startPrice = G.ifElse(
      G.isTrue(requiredMinMaxPrice),
      numberValidationRequired,
      numberValidation,
    );

    tels = Yup.array().of(Yup.object({ truckType, length, maxPrice, lengthUom, startPrice, rate }));
  }

  const schema = {
    tels,
    [GC.FIELD_LOAD_BOARD_SOURCE]: G.yupArrayRequired,
  };

  return schema;
};

export const lengthUomMap = {
  [GC.IMPERIAL_SYSTEM]: { value: GC.UOM_FOOT, label: GC.UOM_FOOT_LABEL },
  [GC.METRIC_SYSTEM]: { value: GC.UOM_METER, label: GC.UOM_METER_LABEL },
};

export const validationSchema = ({ requiredMinMaxPrice }: Object) =>
  Yup.lazy(({ source }: Object) => Yup.object().shape(getValidationSchema(source, requiredMinMaxPrice)));

export const getInitialValues = (telList: Array, loadboards: Array = [], telsPostData: Array = []) => {
  const states = R.path([0, 'states'], telsPostData);

  const source = R.map(
    ({ type }: Object) => type,
    G.ifElse(G.isNotNil(states), states, loadboards),
  );

  const tels = G.mapIndexed((guid: string, index: number) => ({
    guid,
    [GC.FIELD_RATE]: R.pathOr(null, [index, GC.FIELD_RATE], telsPostData),
    [GC.FIELD_LENGTH]: R.pathOr(0, [index, GC.FIELD_LENGTH], telsPostData),
    [GC.FIELD_MAX_PRICE]: R.pathOr(null, [index, GC.FIELD_MAX_PRICE], telsPostData),
    [GC.FIELD_MIN_PRICE]: R.pathOr(null, [index, GC.FIELD_MIN_PRICE], telsPostData),
    [GC.FIELD_LENGTH_UOM]: R.pathOr(null, [index, GC.FIELD_LENGTH_UOM], telsPostData),
    [GC.FIELD_LOAD_BOARD_TRUCK]: R.pathOr(null, [index, GC.FIELD_LOAD_BOARD_TRUCK], telsPostData),
    [GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER]: R.pathOr(
      false,
      [index, GC.FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER],
      telsPostData,
    ),
  }), telList);

  return { tels, source };
};

const inputWrapperStyles = { mr: 20, width: 150 };

const showField = (source: Object, fieldName: string) => {
  if (G.isNilOrEmpty(source)) return 'none';

  if (R.or(R.equals(fieldName, GC.FIELD_MIN_PRICE), R.equals(fieldName, GC.FIELD_MAX_PRICE))) {
    return G.ifElse(
      R.includes(GC.EXTERNAL_LOAD_BOARD_PARADE, source),
      'block',
      'none',
    );
  }

  return 'block';
};

const customChangeHandler = (event: Object, { fieldName }: Object, { setFieldValue }: Object) => {
  const fieldValue = R.path(['currentTarget', GC.FIELD_VALUE], event);
  const value = G.ifElse(R.isEmpty(fieldValue), null, fieldValue);

  setFieldValue(fieldName, value);
};

export const getBookPriceFieldSettings = (index: number, source: Object) => [
  {
    type: 'text',
    customChangeHandler,
    shouldCustomChange: true,
    fieldName: `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_RATE}`,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      // display: showField(source, GC.FIELD_RATE),
    },
  },
  {
    type: 'select',
    customDisabledFunction: 'handleDisableFields',
    options: G.addEmptyOptionToDropDown(GC.truckOptions),
    inputWrapperStyles: { ...inputWrapperStyles, mr: 0 },
    fieldName: `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_LOAD_BOARD_TRUCK}`,
  },
  {
    type: 'text',
    fieldName: `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_LENGTH}`,
    inputWrapperStyles: { ...inputWrapperStyles, ml: 20, display: showField(source) },
  },
  {
    type: 'select',
    options: G.addEmptyOptionToDropDown(R.values(lengthUomMap)),
    inputWrapperStyles: { ...inputWrapperStyles, display: showField(source) },
    fieldName: `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_LOAD_BOARD_LENGTH_UOM}`,
  },
  {
    type: 'text',
    customChangeHandler,
    shouldCustomChange: true,
    fieldName: `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_MIN_PRICE}`,
    inputWrapperStyles: { ...inputWrapperStyles, display: showField(source, GC.FIELD_MIN_PRICE) },
  },
  {
    type: 'text',
    customChangeHandler,
    shouldCustomChange: true,
    fieldName: `${GC.SYSTEM_LIST_TELS}.${index}.${GC.FIELD_MAX_PRICE}`,
    inputWrapperStyles: { ...inputWrapperStyles, mr: 0, display: showField(source, GC.FIELD_MAX_PRICE) },
  },
];

export const sourceField = (loadboards: Array) => [
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    label: ['titles:source', 'Source'],
    fieldName: GC.FIELD_LOAD_BOARD_SOURCE,
    customDisabledFunction: 'handleDisableSource',
    inputWrapperStyles: { my: 15, width: 225, zIndex: 13 },
    options: R.filter(
      ({ value }: Object) => R.includes(value, loadboards),
      R.remove(5, 1, GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS),
    ),
  },
];
