import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const CREATE_DRIVER_INVOICES = 'createDriverInvoices';
const CREATE_VENDOR_INVOICES = 'createVendorInvoices';

const defaultFormikValues = {
  [CREATE_DRIVER_INVOICES]: true,
  [CREATE_VENDOR_INVOICES]: true,
};

const commonProps = {
  type: 'toggle',
  shouldCustomChange: true,
  inputWrapperStyles: {
    mb: 25,
    width: 270,
    justifyContent: 'space-between',
  },
};

const fieldSettings = [
  {
    ...commonProps,
    fieldName: CREATE_DRIVER_INVOICES,
    label: ['titles:create-driver-invoices', 'Create Driver Invoices'],
  },
  {
    ...commonProps,
    fieldName: CREATE_VENDOR_INVOICES,
    label: ['titles:create-vendor-invoices', 'Create Vendor Invoices'],
  },
];

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => defaultFormikValues,
    handleSubmit: (values: Object, { props }: Object) => {
      const { telGuid, submitAction } = props;

      submitAction(R.assoc(GC.FIELD_TEL_GUID, telGuid, values));
    },
  }),
  withHandlers({
    handleCustomChange: (props: Object) => ({ target }: Object) => {
      const { values, setValues } = props;

      const { id } = target;

      let valuesToSet = R.assoc(id, R.not(G.getPropFromObject(id, values)), values);

      if (G.isAllFalse(...R.values(valuesToSet))) {
        const propToChange = G.ifElse(
          R.equals(id, CREATE_DRIVER_INVOICES),
          CREATE_VENDOR_INVOICES,
          CREATE_DRIVER_INVOICES,
        );

        valuesToSet = R.assoc(propToChange, true, valuesToSet);
      }

      setValues(valuesToSet);
    },
  }),
  pure,
);

export const TelInvoiceByCurrencyForm = enhance((props: Object) => {
  const { handleSubmit, handleCustomChange } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fieldSettings}
        handleCustomChange={handleCustomChange}
      />
      <FormFooter2 />
    </form>
  );
});
