import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../';

// helpers/constants
import * as G from '../../../helpers';
// forms/multi-emails-form
import { fields, defaultValues, validationSchema } from './settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'MULTI_EMAILS_FORM',
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;
      submitAction(values);
    },
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultValues,
      props.initialValues,
    ),
  }),
  pure,
);

const MultiEmailsForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...props}
      fields={fields}
      flexDirection='column'
      onChange={props.handleChange}
      justifyContent='space-between'
    />
    <FormFooter2
      boxStyles={{ pt: 10, justifyContent: 'space-around' }}
    />
  </form>
));

export default MultiEmailsForm;
