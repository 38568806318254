import * as Yup from 'yup';
import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// forms
import { Fieldset2 } from '../';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import {
  ITEM_DIMENSIONS_OPTIONS,
  getRaleBillingEnumOptions,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
} from '../../helpers/options';
// ui
import { Box } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const defaultContainerFields = {
  [GC.FIELD_WIDTH]: null,
  [GC.FIELD_HEIGHT]: null,
  [GC.FIELD_LENGTH]: null,
  [GC.FIELD_WEIGHT_UOM]: null,
  [GC.FIELD_TEMPLATE_ID]: null,
  [GC.FIELD_DIMENSIONS_UOM]: null,
  [GC.FIELD_CONTAINER_NUMBER]: null,
  [GC.FIELD_CONTAINER_INITIAL]: null,
  [GC.FIELD_CONTAINER_TYPE_GUID]: null,
  [GC.FIELD_RAIL_BILLING_STATUS]: null,
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: null,
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: null,
};

const containerTypeFieldsToPick = [
  GC.FIELD_WIDTH,
  GC.FIELD_HEIGHT,
  GC.FIELD_LENGTH,
  GC.FIELD_WEIGHT_UOM,
  GC.FIELD_DIMENSIONS_UOM,
  GC.FIELD_EMPTY_CONTAINER_WEIGHT,
];

const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const getValidationSchema = ({ width, height, length }: Object) => {
  let schema = {
    [GC.FIELD_WEIGHT_UOM]: fieldRequired,
    [GC.FIELD_CONTAINER_NUMBER]: fieldRequired,
    [GC.FIELD_FULL_CONTAINER_WEIGHT]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .typeError(G.getShouldBeNumericLocaleTxt())
      .positive(G.getShouldBePositiveLocaleTxt()),
  };

  if (G.isOneNotNilOrNotEmpty([width, height, length])) {
    schema = R.assoc(GC.FIELD_DIMENSIONS_UOM, fieldRequired, schema);
  }

  return schema;
};

const inputWrapperStyles1 = { mr: 20, mb: 25, width: 100 };
const inputWrapperStyles2 = { mr: 20, mb: 25, width: 160 };
const inputWrapperStyles3 = { mr: 20, mb: 25, width: 130 };

const fieldSettings = [
  {
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_INITIAL,
    inputWrapperStyles: inputWrapperStyles2,
    label: ['titles:container-initial', 'Container Initial'],
  },
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CONTAINER_NUMBER,
    inputWrapperStyles: inputWrapperStyles2,
    label: ['titles:container-number', 'Container Number'],
  },
  {
    type: 'select',
    shouldCustomChange: true,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { values, setValues, containerTypes } = props;

      const value = G.getEventTargetValue(event);

      if (G.isNilOrEmpty(value)) return;

      const newValues = R.compose(
        G.mapObjectNullFieldsToEmptyStrings,
        R.pick(containerTypeFieldsToPick),
        R.find(R.propEq(value, GC.FIELD_GUID)),
      )(containerTypes);

      const newValuesWithFullWeight = R.assoc(
        GC.FIELD_FULL_CONTAINER_WEIGHT,
        R.prop(GC.FIELD_EMPTY_CONTAINER_WEIGHT, newValues),
        newValues,
      );

      const newValuesWithContainerType = R.assoc(
        GC.FIELD_CONTAINER_TYPE_GUID,
        value,
        newValuesWithFullWeight,
      );

      return setValues(R.mergeRight(values, newValuesWithContainerType));
    },
    options: 'containerTypeOptions',
    fieldName: GC.FIELD_CONTAINER_TYPE_GUID,
    label: ['titles:container-type', 'Container Type'],
    inputWrapperStyles: { ...inputWrapperStyles2, mr: 0 },
  },
  {
    type: 'number',
    inputWrapperStyles: inputWrapperStyles1,
    fieldName: GC.FIELD_EMPTY_CONTAINER_WEIGHT,
    label: ['titles:empty-weight', 'Empty Weight'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles: inputWrapperStyles1,
    fieldName: GC.FIELD_FULL_CONTAINER_WEIGHT,
    label: ['titles:full-weight', 'Full Weight'],
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_WEIGHT_UOM,
    inputWrapperStyles: inputWrapperStyles3,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    label: ['titles:weight-uom', 'Weight UOM'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_RAIL_BILLING_STATUS,
    label: ['titles:rail-billing', 'Rail Billing'],
    inputWrapperStyles: { ...inputWrapperStyles3, mr: 0 },
    options: G.prependEmptyLabelValueOption(getRaleBillingEnumOptions()),
  },
  {
    type: 'number',
    fieldName: GC.FIELD_WIDTH,
    label: ['titles:width', 'Width'],
    inputWrapperStyles: inputWrapperStyles1,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_HEIGHT,
    label: ['titles:height', 'Height'],
    inputWrapperStyles: inputWrapperStyles1,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_LENGTH,
    label: ['titles:length', 'Length'],
    inputWrapperStyles: inputWrapperStyles1,
  },
  {
    type: 'select',
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_DIMENSIONS_UOM,
    label: ['titles:dimensions-uom', 'Dimensions UOM'],
    inputWrapperStyles: { ...inputWrapperStyles2, mr: 0 },
  },
];

const ContainerForm = (props: Object) => {
  const { closeModal, handleSubmit, containerTypes } = props;

  const formikProps = G.getFormikProps(props);
  const containerTypeOptions = G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(containerTypes));

  return (
    <Box mt={15} width={520}>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...formikProps}
          fields={fieldSettings}
          containerTypes={containerTypes}
          containerTypeOptions={containerTypeOptions}
        />
        <FormFooter2 closeModal={closeModal} />
      </form>
    </Box>
  );
};

const enhanceContainerTypes = compose(
  withState('containerTypes', 'setContainerTypes', R.prop('containerTypes')),
  withHandlers({
    handleGetContainerTypes: ({ setContainerTypes }: Object) => async () => {
      const res = await sendRequest('get', endpointsMap.containerTypeList);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setContainerTypes(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { containerTypes, handleGetContainerTypes } = this.props;

      if (G.isNotNilAndNotEmpty(containerTypes)) return;

      handleGetContainerTypes();
    },
  }),
  pure,
);

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues, searchedValues }: Object) =>
    G.setInitialFormikValues(defaultContainerFields, initialValues, searchedValues),
  validationSchema: () => Yup.lazy((values: Object) => Yup.object().shape(getValidationSchema(values))),
  handleSubmit: (values: Object, { props }: Object) => {
    const { submitAction } = props;

    submitAction(values);
  },
});

const enhance = compose(
  enhanceContainerTypes,
  enhanceForm,
  pure,
);

export default enhance(ContainerForm);

