import React from 'react';
import * as R from 'ramda';
import { CountryDropdown } from 'react-country-region-selector';
// forms
import { CountrySelectWrapper } from './ui';
//////////////////////////////////////////////////

class CountryDropdownSelect extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched } = this.props;
    setFieldTouched(id, true);
  };

  handleChange = (value: string) => {
    const { id, setFieldValue } = this.props;
    setFieldValue(id, value);
  };

  render() {
    const {
      id,
      width,
      value,
      additionalBorderColor,
      useAdditionalBorderColor,
    } = this.props;

    return (
      <CountrySelectWrapper
        width={width}
        additionalBorderColor={additionalBorderColor}
        useAdditionalBorderColor={useAdditionalBorderColor}
      >
        <CountryDropdown
          id={id}
          value={R.or(value, '')}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
      </CountrySelectWrapper>
    );
  }
}

export default CountryDropdownSelect;
