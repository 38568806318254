import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// features
import { CarrierNameSection } from '../../../features/rate/carrier/carrier-section';
// forms
import { Fieldset2 } from '../../formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// mass-carrier-rate-form
import { fields, defaultValues, validationSchema } from './settings';
//////////////////////////////////////////////////

const fieldsWrapperStyles = {
  mt: 10,
  p: '15px 15px 0 15px',
  flexDirection: 'column',
};

const configsNamesArray = [
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
];

const enhance = compose(
  withProps(() => ({ configsNamesArray })),
  withAsyncConfigs,
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'MASS_CREATE_CARRIER_RATE_FORM',
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const data = R.compose(
        R.assoc(
          GC.FIELD_CARRIER_GUID,
          R.pathOr(null, [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT, GC.FIELD_CARRIER_GUID], values),
        ),
        R.pick([GC.FIELD_MODE, GC.FIELD_SERVICE_TYPE]),
      )(values);

      submitAction(data);
    },
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultValues,
      props.initialValues,
    ),
  }),
  pure,
);

export const MassCreateCarrierRateForm = enhance((props: Object) => {
  const { handleSubmit } = props;

  const { serviceType, transportationMode } = G.getModeServiceFromAsyncConfigsForRateSelect(props);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        serviceTypeOptions={serviceType}
        fieldsWrapperStyles={fieldsWrapperStyles}
        transportationModeOptions={transportationMode}
      />
      <CarrierNameSection {...props} />
      <FormFooter2 boxStyles={{ p: '0 15px 15px 15px' }} />
    </form>
  );
});

export default MassCreateCarrierRateForm;
