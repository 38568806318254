import React from 'react';
import * as R from 'ramda';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../components/text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { MultiEmailInput } from './ui';
// ui
import { Box, Flex, RelativeBox } from '../../ui';
//////////////////////////////////////////////////

const addNewValue = (props: Object) => {
  const { id, value, inputValue, setInputValue, setFieldValue } = props;

  const inputValueToUse = R.trim(inputValue);

  if (G.isNotNilAndNotEmpty(inputValueToUse)) {
    setInputValue('');
    setFieldValue(id, R.append(inputValueToUse, value));
  }
};

const enhance = compose(
  withState('inputValue', 'setInputValue', ''),
  withHandlers({
    handleKeyDown: (props: Object) => (event: Object) => {
      const eventKeyCodes = [GC.EVENT_KEY_CODE_TAB, GC.EVENT_KEY_CODE_ENTER, GC.EVENT_KEY_CODE_COMMA];

      if (R.includes(G.getPropFromObject('keyCode', event), eventKeyCodes)) {
        event.preventDefault();

        addNewValue(props);
      }
    },
    handleChangeEmailInput: ({ setInputValue }: Object) => (event: Object) => {
      setInputValue(G.getEventTargetValue(event));
    },
    handleDeleteItem: ({ id, value, setFieldValue }: Object) => (removedValue: string) =>
      setFieldValue(id, R.filter((item: Object) => G.notEquals(item, removedValue), value)),
    handlePaste: (props: Object) => (event: Object) => {
      const { id, value, setFieldValue } = props;

      event.preventDefault();

      const paste = R.prop('clipboardData', event).getData('text');
      const items = R.uniq(R.split(', ', paste));

      if (G.isNotNilAndNotEmpty(items)) {
        const toBeAdded = R.filter((item: string) => G.notContain(item, value), items);

        setFieldValue(id, R.concat(value, toBeAdded));
      }
    },
    handleOnOuterClick: (props: Object) => () => addNewValue(props),
  }),
  pure,
);

export const MultiItemsInput = (props: Object) => {
  const {
    id,
    value,
    width,
    inputValue,
    handlePaste,
    placeholder,
    handleKeyDown,
    setFieldTouched,
    handleDeleteItem,
    handleOnOuterClick,
    handleChangeEmailInput,
  } = props;

  const blueColor = G.getTheme('colors.light.blue');
  const greyColor = G.getTheme('colors.light.grey');

  return (
    <Flex
      p='5px 10px'
      height='auto'
      width={width}
      minHeight={30}
      flexWrap='wrap'
      borderRadius='4px'
      background={G.getTheme('colors.white')}
      border={`1px solid ${G.getTheme('colors.dark.grey')}`}
    >
      {R.or(value, []).map((item: string, index: number) => (
        <Flex
          mr={10}
          my='5px'
          key={index}
          fontSize={12}
          color={blueColor}
        >
          <TextComponent
            title={item}
            fontSize={12}
            withEllipsis={true}
            maxWidth={`calc(${width} - 41px)`}
          >
            {item}
          </TextComponent>
          <Box
            ml='3px'
            px='4px'
            fontSize={14}
            cursor='pointer'
            color={greyColor}
            border='1px solid'
            borderRadius='50%'
            borderColor={blueColor}
            onClick={() => handleDeleteItem(item)}
          >
            &times;
          </Box>
        </Flex>
      ))}
      <OuterClick width='100%' as={RelativeBox} onOuterClick={handleOnOuterClick}>
        <MultiEmailInput
          id={id}
          width='100%'
          value={inputValue}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          onChange={handleChangeEmailInput}
          placeholder={G.getWindowLocale(placeholder, '')}
          onBlur={() => G.isFunction(setFieldTouched) && setFieldTouched(id, true, false)}
        />
      </OuterClick>
    </Flex>
  );
};

export default enhance(MultiItemsInput);
