import * as R from 'ramda';
import {
  put,
  call,
  takeLatest } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature template-search
import * as A from './actions';
//////////////////////////////////////////////////

export const createSearchCriteria = (data: Object) => (
  R.compose(
    R.values,
    R.mapObjIndexed((stringValue: string, propertyName: string) => ({
      stringValue,
      propertyName,
      dataType: 'string',
      dateRelative: false,
      operation: 'contain',
    })),
    R.filter((value: string) => G.isNotNilAndNotEmpty(value)),
  )(data)
);

function* handleGetItemListBySearchRequestSaga({ payload }: Object) {
  try {
    const searchCriteria = R.or(createSearchCriteria(payload.data), []);
    const reqBody = {
      searchCriteria,
      [GC.FIELD_BRANCH_GUID]: payload.branchGuid,
      customerGuid: R.path(['customerGuid'], payload),
    };
    const options = {
      data: R.or(G.setSearchCriteria({ reqBody }), reqBody),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.listItems, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListBySearchSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListBySearchRequestSaga fail', true);
    }
    if (G.isNotNilAndNotEmpty(payload.onEndSagaAction)) yield call(payload.onEndSagaAction);
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetItemListBySearchRequestSaga exception');
    if (G.isNotNilAndNotEmpty(payload.onEndSagaAction)) yield call(payload.onEndSagaAction);
  }
}

function* handleGetLocationListBySearchRequestSaga({ payload }: Object) {
  try {
    const searchCriteria = R.or(createSearchCriteria(payload.data), []);
    const reqBody = {
      searchCriteria,
      [GC.FIELD_BRANCH_GUID]: payload.branchGuid,
      customerGuid: R.path(['customerGuid'], payload),
    };
    const options = {
      data: R.or(G.setSearchCriteria({ reqBody }), reqBody),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.listLocations, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLocationListBySearchSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLocationListBySearchRequestSaga fail', true);
    }
    if (G.isNotNilAndNotEmpty(payload.onEndSagaAction)) yield call(payload.onEndSagaAction);
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLocationListBySearchRequest exception');
    if (G.isNotNilAndNotEmpty(payload.onEndSagaAction)) yield call(payload.onEndSagaAction);
  }
}

function* handleGetContainerListBySearchRequestSaga({ payload }: Object) {
  try {
    const searchCriteria = R.or(createSearchCriteria(payload.data), []);
    const reqBody = {
      searchCriteria,
      [GC.FIELD_BRANCH_GUID]: payload.branchGuid,
      customerGuid: R.path(['customerGuid'], payload),
    };
    const options = {
      data: R.or(G.setSearchCriteria({ reqBody }), reqBody),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.listContainerTemplate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getContainerListBySearchSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetContainerListBySearchRequestSaga fail', true);
    }
    if (G.isNotNilAndNotEmpty(payload.onEndSagaAction)) yield call(payload.onEndSagaAction);
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetContainerListBySearchRequestSaga exception');
    if (G.isNotNilAndNotEmpty(payload.onEndSagaAction)) yield call(payload.onEndSagaAction);
  }
}

function* TemplateSearchWatcherSaga() {
  yield takeLatest(A.getItemListBySearchRequest, handleGetItemListBySearchRequestSaga);
  yield takeLatest(A.getLocationListBySearchRequest, handleGetLocationListBySearchRequestSaga);
  yield takeLatest(A.getContainerListBySearchRequest, handleGetContainerListBySearchRequestSaga);
}

export default TemplateSearchWatcherSaga;
