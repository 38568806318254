import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { LocalLoader, withLocalLoaderHoc } from '../../../components/local-loader';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Label } from '../../ui';
import { FormSection } from '../../formik/ui';
import { Fieldset2 } from '../../formik/fieldset2/fieldset';
// hocs
import { withAsyncConfigs } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, ReactSelect, AbsoluteBox, SimpleWrapper } from '../../../ui';
// feature template-search
import { Wrapper } from './ui';
import { clearTemplatesStore } from './actions';
//////////////////////////////////////////////////

const TemplateSections = pure((props: Object) => {
  const { templateFields, optionsForSelect } = props;

  return (
    <FormSection>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={templateFields}
        fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
      />
    </FormSection>
  );
});

const enhance = compose(
  withAsyncConfigs,
  withLocalLoaderHoc,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues, searchedValues, defaultTemplateFields }: Object) => G.setInitialFormikValues(
      defaultTemplateFields,
      initialValues,
      searchedValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        branchGuid,
        customerGuid,
        templateFields,
        handleOpenLocalLoader,
        getListTemplatesAction,
        handleCloseLocalLoader,
      } = props;

      const fieldsNames = R.map(({ fieldName }: Object) => fieldName, templateFields);
      const data = {};

      R.forEachObjIndexed((value: string, key: string) => {
        let newKey = key;

        if (R.includes(':', key)) {
          newKey = R.join('.', R.split(':', key));
        }

        data[newKey] = G.ifElse(R.includes(key, fieldsNames), value, null);
      }, values);

      handleOpenLocalLoader();

      getListTemplatesAction({
        data,
        branchGuid,
        customerGuid,
        onEndSagaAction: handleCloseLocalLoader,
      });
    },
    displayName: 'TemplateSearchForm',
  }),
  withState('instance', 'setInstance', null),
  withState('isMenuOpen', 'setIsMenuOpen', false),
  withHandlers({
    handleClearFormData: (props: Object) => () => {
      const {
        setValues,
        setInstance,
        clearTemplatesStore,
        defaultTemplateFields,
      } = props;

      setValues(R.map(() => '', defaultTemplateFields));
      setInstance(null);
      clearTemplatesStore();
    },
  }),
  withHandlers(() => {
    let timeout;

    return {
      handleCustomChange: (props: Object) => (e: Object) => {
        const {
          submitForm,
          setInstance,
          handleChange,
          setIsMenuOpen,
        } = props;

        clearTimeout(timeout);
        handleChange(e);

        timeout = setTimeout(() => {
          setInstance(null);
          setIsMenuOpen(true);
          submitForm();
        }, 500);
      },
      handleSearchAll: (props: Object) => () => {
        const {
          branchGuid,
          customerGuid,
          setIsMenuOpen,
          handleClearFormData,
          handleOpenLocalLoader,
          getListTemplatesAction,
          handleCloseLocalLoader,
        } = props;

        setIsMenuOpen(true);
        handleClearFormData();
        handleOpenLocalLoader();

        getListTemplatesAction({
          data: [],
          branchGuid,
          customerGuid,
          onEndSagaAction: handleCloseLocalLoader,
        });
      },
      handleSetCustomFieldValue: (props: Object) => (id: string, value: string) => {
        const {
          submitForm,
          setInstance,
          setFieldValue,
        } = props;

        clearTimeout(timeout);
        setFieldValue(id, value);

        timeout = setTimeout(() => {
          setInstance(null);
          submitForm();
        }, 500);
      },
      handleCustomSetValues: ({ setValues, submitForm }: Object) => (values: Object) => {
        clearTimeout(timeout);
        setValues(values);

        timeout = setTimeout(() => submitForm(), 500);
      },
      handleChangeLocation: ({ setInstance, setIsMenuOpen }: Object) => (value: Array) => {
        setInstance(value);
        setIsMenuOpen(false);
      },
      handleTemplateSearchRequest: ({ instance, searchTemplateRequest }: Object) => () => {
        if (G.isNilOrEmpty(instance)) {
          return toastr.info(G.getWindowLocale('messages:select-template', 'Please, select a template!'));
        }

        searchTemplateRequest(G.getValueFromObject(instance));
      },
    };
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.clearTemplatesStore();
    },
  }),
  pure,
);

const TemplateSearch = (props: Object) => {
  const {
    instance,
    isMenuOpen,
    asyncConfigs,
    handleSubmit,
    templateList,
    setIsMenuOpen,
    selectOptions,
    handleSearchAll,
    localLoaderOpen,
    handleCustomChange,
    handleClearFormData,
    handleChangeLocation,
    handleCustomSetValues,
    handleSetCustomFieldValue,
    handleTemplateSearchRequest,
  } = props;

  return (
    <Wrapper>
      <FormFooter2
        boxStyles={{ p: '10px 15px 15px' }}
        submitAction={handleTemplateSearchRequest}
        submitBtnText={G.getWindowLocale('actions:save', 'Save')}
      />
      <SimpleWrapper pr={15} alignItems='flex-start'>
        <SimpleWrapper p={15} mr={15}>
          <form onSubmit={handleSubmit}>
            <TemplateSections
              {...props}
              setValues={handleCustomSetValues}
              handleChange={handleCustomChange}
              setFieldValue={handleSetCustomFieldValue}
              optionsForSelect={{
                [GC.TEMPLATES_LOCATION_TYPE]: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
                  asyncConfigs,
                  GC.TEMPLATES_LOCATION_TYPE,
                  true,
                ),
              }}
            />
          </form>
        </SimpleWrapper>
        <LocalLoader
          width={250}
          minHeight={60}
          height='max-content'
          localLoaderOpen={localLoaderOpen}
        >
          <SimpleWrapper
            mt={17}
            overflow='visible'
            height='max-content'
            flexDirection='column'
            alignItems='flex-start'
          >
            <Label
              pl='5px'
              mb='1px'
              width={250}
              fontSize={11}
              className='not-required'
              color={G.getTheme('colors.darkGrey')}
            >
              {`${G.getWindowLocale('titles:results', 'Results')} (${R.length(templateList)})`}
            </Label>
            <ReactSelect
              width={250}
              value={instance}
              options={selectOptions}
              menuIsOpen={isMenuOpen}
              onChange={handleChangeLocation}
              shouldNotGetValueFromOptions={true}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              isDisabled={G.isNilOrEmpty(templateList)}
              placeholder={G.getWindowLocale('actions:select', 'Select...')}
            />
          </SimpleWrapper>
        </LocalLoader>
      </SimpleWrapper>
      <FormGroupTitleMultiple
        mb='0px'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:search-template', 'Search Template')}
      >
        <Flex
          ml={10}
          pt='2px'
          cursor='pointer'
          onClick={handleClearFormData}
        >
          {I.clean(G.getTheme('icons.iconColorWhite'))}
        </Flex>
        <AbsoluteBox
          right={18}
          height={20}
          p='5px 10px'
          cursor='pointer'
          borderRadius='2px'
          width='max-content'
          justifyContent='center'
          onClick={handleSearchAll}
          color={G.getTheme('icons.iconColor')}
          bg={G.getTheme('icons.iconColorWhite')}
        >
          {G.getWindowLocale('actions:search-all', 'Search All')}
        </AbsoluteBox>
      </FormGroupTitleMultiple>
    </Wrapper>
  );
};

export default connect(null, {
  clearTemplatesStore,
})(enhance(TemplateSearch));
