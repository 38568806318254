import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature template-search
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  items: [],
  locations: [],
  containers: [],
};

const clearTemplatesStore = () => initialState;

const getItemListBySearchSuccess = (state: Object, data: Object) => (
  P.$set('items', data, state)
);

const getLocationListBySearchSuccess = (state: Object, data: Object) => (
  P.$set('locations', data, state)
);

const getContainerListBySearchSuccess = (state: Object, data: Object) => (
  P.$set('containers', data, state)
);


export default createReducer({
  [A.clearTemplatesStore]: clearTemplatesStore,
  [A.getItemListBySearchSuccess]: getItemListBySearchSuccess,
  [A.getLocationListBySearchSuccess]: getLocationListBySearchSuccess,
  [A.getContainerListBySearchSuccess]: getContainerListBySearchSuccess,
}, initialState);
