import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = (state: Object) => state.templates;

const makeSelectItemTemplates = () => createSelector(
  store,
  ({ items }: Object) => items,
);

const makeSelectLocationTemplates = () => createSelector(
  store,
  ({ locations }: Object) => locations,
);

const makeSelectContainerTemplates = () => createSelector(
  store,
  ({ containers }: Object) => containers,
);

export {
  store,
  makeSelectItemTemplates,
  makeSelectLocationTemplates,
  makeSelectContainerTemplates,
};
