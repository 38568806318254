import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// forms
import { Fieldset2 } from '../../formik';
  // helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// bill-remit-to
import { withEditBillTo, withEditRemitTo } from './';
//////////////////////////////////////////////////

const renderAddressInfo = (values: Object) => {
  if (G.isNotNilAndNotEmpty(values)) {
    const address = G.renderAddressInfoWithNameAndCode(R.omit(GC.FIELD_TEMPLATE_ID, values));
    return (
      <TextComponent title={address} display='block' maxWidth='100%' withEllipsis={true}>
        {address}
      </TextComponent>
    );
  }
  return null;
};

const renderLocationInfo = (values: Object) => {
  if (G.isNotNilAndNotEmpty(values)) {
    const location = G.concatLocationFields(values);
    return (
      <TextComponent title={location} display='block' maxWidth='100%' withEllipsis={true}>
        {location}
      </TextComponent>
    );
  }
  return null;
};

const renderContactInfo = (values: Object) => {
  if (G.isNotNilAndNotEmpty(values)) {
    const contact = G.concatContactFields(values, [GC.FIELD_CONTACT_NAME, GC.FIELD_PHONE, GC.FIELD_EMAILS]);
    return (
      <TextComponent title={contact} display='block' maxWidth='100%' withEllipsis={true}>
        {contact}
      </TextComponent>
    );
  }
  return null;
};

const getDataForBillTo = (props: Object) => ({
  updateBillToRequest: props.updateBillToRequest,
  initialValues: R.pathOr({}, ['values', GC.SYSTEM_OBJECT_BILL_TO], props),
  branchGuid: R.pathOr(
    R.pathOr(R.pathOr('', ['branchGuid'], props), [GC.FIELD_CLO, GC.FIELD_BRANCH_GUID], props),
    [GC.FIELD_CLO, GC.FIELD_BRANCH, GC.FIELD_GUID],
    props,
  ),
});

const InfoWrapper = ({ children }: Object) => (
  <Box
    p='8px'
    mr={20}
    minWidth={200}
    maxWidth={320}
    borderRadius='8px'
    bg={G.getTheme('colors.light.lightGrey')}
  >
    {children}
  </Box>
);

const SeparateBillToField = (props: Object) => {
  const { handleChangeSeparateBillTo } = props;

  return (
    <Flex>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={[
          {
            type: 'toggle',
            shouldCustomChange: true,
            inputWrapperStyles: { mr: 10, width: 155 },
            customChangeHandler: handleChangeSeparateBillTo,
            label: ['titles:separate-from-order', 'Separate From Order'],
            fieldName: `${GC.SYSTEM_OBJECT_BILL_TO}.${GC.FIELD_SEPARATE_INVOICE_BILL_TO}`,
          },
        ]}
      />
    </Flex>
  );
};

export const BillToInfo = withEditBillTo((props: Object) => {
  const { values: { billTo }, handleClickEditBillTo } = props;

  return (
    <InfoWrapper>
      <Flex mb='8px' justifyContent='space-between'>
        <Box mr={10} fontWeight={600}>
          {G.getWindowLocale('titles:bill-to', 'Bill To', { caseAction: 'upperCase' })}:
        </Box>
        <SeparateBillToField {...props} />
        <Box onClick={() => handleClickEditBillTo(getDataForBillTo(props))}>
          {I.renderEditIcon(G.getTheme('icons.iconColor'))}
        </Box>
      </Flex>
      {renderAddressInfo(billTo)}
      {renderLocationInfo(billTo)}
      {renderContactInfo(billTo)}
    </InfoWrapper>
  );
});

export const RemitToInfo = withEditRemitTo((props: Object) => {
  const { values: { remitTo }, handleClickEditRemitTo } = props;

  return (
    <InfoWrapper>
      <Flex justifyContent='space-between'>
        <Box mb='5px' fontWeight={600}>
          {G.getWindowLocale('titles:remit-to', 'Remit To')}:
        </Box>
        <Box onClick={handleClickEditRemitTo}>
          {I.renderEditIcon(G.getTheme('icons.iconColor'))}
        </Box>
      </Flex>
      {renderAddressInfo(remitTo)}
      {renderLocationInfo(remitTo)}
      {renderContactInfo(remitTo)}
    </InfoWrapper>
  );
});

export const PayToInfo = ({ payTo }: Object) => (
  <InfoWrapper>
    <Flex justifyContent='space-between'>
      <Box mb='5px' fontWeight={600}>
        {G.getWindowLocale('titles:pay-to', 'Pay To')}:
      </Box>
    </Flex>
    {renderAddressInfo(payTo)}
    {renderLocationInfo(payTo)}
  </InfoWrapper>
);
