import * as Yup from 'yup';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const validationSchema = Yup.object().shape({
  [GC.FIELD_EMAILS]: Yup.array().nullable(true),
});

export const defaultValues = {
  [GC.FIELD_EMAILS]: null,
};

export const fields = [
  {
    inputWrapperStyles: {
      my: 15,
      width: 370,
    },
    type: 'multiEmail',
    label: ['titles:emails'],
    fieldName: GC.FIELD_EMAILS,
  },
];
