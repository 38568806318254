import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const clearTemplatesStore = createAction('clearTemplatesStore');
export const getItemListBySearchRequest = createAction('getItemListBySearchRequest');
export const getItemListBySearchSuccess = createAction('getItemListBySearchSuccess');
export const getLocationListBySearchRequest = createAction('getLocationListBySearchRequest');
export const getLocationListBySearchSuccess = createAction('getLocationListBySearchSuccess');
export const getContainerListBySearchRequest = createAction('getContainerListBySearchRequest');
export const getContainerListBySearchSuccess = createAction('getContainerListBySearchSuccess');
