import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const defaultFormikValues = {
  [GC.FIELD_CHARGE_COMMENTS]: null,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CHARGE_COMMENTS]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 140))
    .max(140, G.getShouldBeFromToLocaleTxt(0, 140)),
});

const inputWrapperStyles = { width: 300 };

const fieldSettings = [
  {
    type: 'textarea',
    inputWrapperStyles,
    fieldName: GC.FIELD_CHARGE_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: (props: Object) => R.mergeRight(defaultFormikValues, props.initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { type, charge, closeModal, handleAddChargeComment } = props;

      const comments = R.prop(GC.FIELD_CHARGE_COMMENTS, values);

      closeModal();

      if (R.equals(type, 'master')) {
        return handleAddChargeComment({ chargeIndex: charge, comments });
      }

      handleAddChargeComment(R.assoc(GC.FIELD_CHARGE_COMMENTS, comments, charge));
    },
    displayName: 'CHARGE_COMMENTS_FORM',
  }),
  pure,
);

const ChargeCommentsForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      onChange={props.handleChange}
      fieldsWrapperStyles={{ mt: 10 }}
    />
    <FormFooter2
      boxStyles={{ mt: 20 }}
      submitBtnText={G.getWindowLocale('actions.add', 'Add')}
    />
  </form>
);

export default enhance(ChargeCommentsForm);
