import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature master-invoice
import { fields, defaultValues, validationSchema } from './settings';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  documentTemplates: makeSelectDocumentTemplates(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'SELECT_PRINT_TEMPLATE_FORM',
    handleSubmit: ({ templateGuid }: Object, { props }: Object) => {
      const { submitAction, documentTemplates, printableSection } = props;

      const format = R.compose(
        R.path([GC.FIELD_FORMAT]),
        R.find(R.propEq(templateGuid, GC.FIELD_GUID)),
        R.pathOr([], [printableSection]),
      )(documentTemplates);

      submitAction({ templateGuid }, format);
    },
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultValues,
      props.initialValues,
    ),
  }),
  pure,
);

const getOptions = (documentTemplates: Array, printableSection: string) => G.addEmptyOptionToDropDown(
  G.mapNameGuidToLabelValue(R.pathOr([], [printableSection], documentTemplates)),
);

const SelectPrintTemplateForm = enhance((props: Object) => {
  const { handleSubmit, printableSection, documentTemplates } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={fields}
          fieldsWrapperStyles={{ mt: 10 }}
          documentTemplateOptions={getOptions(documentTemplates, printableSection)}
        />
      </Box>
      <FormFooter2 />
    </form>
  );
});

export default SelectPrintTemplateForm;
