import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// accessorial-form
import {
  geoFencingZoneFieldSettings,
  geoFencingZoneValidationSchema,
  defaultGeoFencingZoneFieldSettings,
} from './field-settings';
//////////////////////////////////////////////////

const Section = (props: Object) => (
  <Box
    width={460}
    ml={props.ml}
    p='15px 20px'
    overflow='auto'
    borderRadius='8px'
    border='1px solid'
    maxHeight='calc(100vh - 170px)'
    borderColor={G.getTheme('colors.dark.blue')}
  >
    <Box ml={10} mb={25} fontWeight='bold' color={G.getTheme('colors.light.blue')}>
      {props.title}
    </Box>
    <Fieldset2
      {...props}
      fields={geoFencingZoneFieldSettings(props.zoneType)}
      fieldsWrapperStyles={{ justifyContent: 'space-between' }}
    />
  </Box>
);

const GeoFencingZoneForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Flex width={980} justifyContent='space-between'>
      <Section
        {...G.getFormikProps(props)}
        zoneType={GC.FIELD_ORIGIN}
        setValues={props.handleSetAddressPointValues}
        title={G.getWindowLocale('titles:origin', 'Origin')}
      />
      <Section
        {...G.getFormikProps(props)}
        zoneType={GC.FIELD_DESTINATION}
        setValues={props.handleSetAddressPointValues}
        title={G.getWindowLocale('titles:destination', 'Destination')}
      />
    </Flex>
    <FormFooter2 boxStyles={{ mt: 15 }} cancelAction={() => props.cancelAction(props.values)} />
  </form>
);

const enhance = compose(
  withFormik({
    validationSchema: geoFencingZoneValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const data = R.map(R.omit(['searchZip', 'searchCity']), values);

      props.submitAction(data);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultGeoFencingZoneFieldSettings,
      initialValues,
    ),
  }),
  withHandlers({
    handleSetAddressPointValues: (props: Object) => (entity: Object) => {
      const filteredEntity = R.filter(G.isNotNilAndNotEmpty, entity);

      if (G.isNilOrEmpty(filteredEntity)) return;

      const { values, setValues } = props;
      const { zip, label, city, state, country } = entity;

      let sectionValues = null;
      const pathArray = R.split('.', label);
      const sectionName = R.head(pathArray);
      const searchedFieldName = R.last(pathArray);
      const section = G.getPropFromObject(sectionName, values);

      if (R.equals(searchedFieldName, 'searchCity')) {
        const cities = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(city),
        )(section.cities);
        const states = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(state),
        )(section.states);
        const countries = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(country),
        )(section.countries);
        sectionValues = R.mergeRight(section, { cities, states, countries, searchCity: null });
      }

      if (R.equals(searchedFieldName, 'searchZip')) {
        const zipCodes = R.compose(
          R.uniq,
          R.filter(G.isNotNilAndNotEmpty),
          R.append(zip),
        )(section.zipCodes);
        sectionValues = R.mergeRight(section, { zipCodes, searchZip: null });
      }

      const newValues = R.assoc(sectionName, sectionValues, values);

      setValues(newValues);
    },
  }),
  pure,
);

export default enhance(GeoFencingZoneForm);
