import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncConfigs } from '../../hocs';
// ui
import { Box } from '../../ui';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_MARGIN_VIOLATION_NOTE]: null,
  [GC.FIELD_MARGIN_VIOLATION_REASON]: null,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_MARGIN_VIOLATION_NOTE]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
  [GC.FIELD_MARGIN_VIOLATION_REASON]: G.yupStringRequired,
});

const inputWrapperStyles = { width: 350 };

export const fields = [
  {
    type: 'select',
    options: 'options',
    fieldName: GC.FIELD_MARGIN_VIOLATION_REASON,
    label: ['titles:violation-reason', 'Violation Reason'],
    inputWrapperStyles: { ...inputWrapperStyles, m: '15px 0 25px' },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_MARGIN_VIOLATION_NOTE,
    label: ['titles:violation-note', 'Violation Note'],
    inputWrapperStyles: { ...inputWrapperStyles, mb: '5px' },
  },
];

const getOptions = ({ asyncConfigs }: Object) => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
  asyncConfigs,
  GC.RATE_ENGINE_MARGIN_VIOLATION_REASON,
  true,
);

const enhance = compose(
  withProps(() => ({ configsNamesArray: [GC.RATE_ENGINE_MARGIN_VIOLATION_REASON] })),
  withAsyncConfigs,
  withFormik({
    validationSchema,
    enableReinitialize: true,
    displayName: 'APPROVE_RATE_FORM',
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
  }),
  pure,
);

export const ApproveRateForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box pb={12}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        options={getOptions(props)}
        onChange={props.handleChange}
      />
    </Box>
    <FormFooter2 />
  </form>
));
