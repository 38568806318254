import React from 'react';
import * as R from 'ramda';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { MultiValuesInput } from './ui';
// ui
import { Box, Flex, RelativeBox } from '../../../ui';
//////////////////////////////////////////////////

const maxWidthCalc = (value: any) => {
  if (G.isNumber(value)) {
    return `calc(${value}px - 41px)`;
  }

  return `calc(${value} - 41px)`;
};

const addNewValue = (props: Object) => {
  const { id, value, inputValue, setInputValue, setFieldValue } = props;

  const inputValueToUse = R.trim(inputValue);

  if (G.isNotNilAndNotEmpty(inputValueToUse)) {
    setInputValue('');
    setFieldValue(id, R.append(inputValueToUse, value));
  }
};

const enhance = compose(
  withState('inputValue', 'setInputValue', ''),
  withHandlers({
    handleKeyDown: (props: Object) => (event: Object) => {
      const eventKeyCodes = [GC.EVENT_KEY_CODE_TAB, GC.EVENT_KEY_CODE_ENTER, GC.EVENT_KEY_CODE_COMMA];

      if (R.includes(event.keyCode, eventKeyCodes)) {
        event.preventDefault();

        addNewValue(props);
      }
    },
    handleChangeItemInput: ({ setInputValue }: Object) => (event: Object) => {
      setInputValue(R.path(['target', 'value'], event));
    },
    handleDeleteItem: ({ id, value, setFieldValue }: Object) => (removedValue: string) =>
      setFieldValue(id, R.filter((item: Object) => G.notEquals(item, removedValue), value)),
    handlePaste: (props: Object) => (event: Object) => {
      event.preventDefault();

      const { id, value, setFieldValue } = props;

      const paste = R.prop('clipboardData', event).getData('text');
      const items = R.uniq(R.split(', ', paste));

      if (G.isNotNilAndNotEmpty(items)) {
        const toBeAdded = R.filter((item: string) => G.notContain(item, value), items);

        setFieldValue(id, R.concat(value, toBeAdded));
      }
    },
    handleOnOuterClick: (props: Object) => () => addNewValue(props),
  }),
  pure,
);

export const MultiItemsInput = (props: Object) => {
  const {
    id,
    value,
    width,
    disabled,
    minHeight,
    inputValue,
    handlePaste,
    borderColor,
    placeholder,
    borderRadius,
    handleKeyDown,
    setFieldTouched,
    handleDeleteItem,
    handleOnOuterClick,
    handleChangeItemInput,
  } = props;

  return (
    <Flex
      p='5px 10px'
      height='auto'
      width={width}
      flexWrap='wrap'
      border='1px solid'
      borderRadius={borderRadius}
      minHeight={R.or(minHeight, 30)}
      bg={G.ifElse(disabled, 'light.lightGrey', 'white')}
      borderColor={R.or(borderColor, G.getTheme('forms.inputs.borderColor'))}
    >
      {R.or(value, []).map((item: string, index: number) => (
        <Flex
          mr={10}
          my='5px'
          key={index}
          fontSize={12}
          color={G.getTheme('colors.light.blue')}
        >
          <TextComponent
            key={index}
            title={item}
            fontSize={12}
            withEllipsis={true}
            maxWidth={maxWidthCalc(width)}
          >
            {item}
          </TextComponent>
          <Box
            ml='3px'
            px='4px'
            fontSize={14}
            cursor='pointer'
            borderRadius='50%'
            onClick={() => handleDeleteItem(item)}
            color={G.getTheme('colors.light.grey')}
            border={`1px solid ${G.getTheme('colors.light.blue')}`}
          >
            &times;
          </Box>
        </Flex>
      ))}
      <OuterClick width='100%' as={RelativeBox} onOuterClick={handleOnOuterClick}>
        <MultiValuesInput
          id={id}
          width='100%'
          value={inputValue}
          disabled={disabled}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          onChange={handleChangeItemInput}
          placeholder={R.or(placeholder, '')}
          onBlur={() => G.isFunction(setFieldTouched) && setFieldTouched(id, true, false)}
        />
      </OuterClick>
    </Flex>
  );
};

export default enhance(MultiItemsInput);
