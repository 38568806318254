import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
//components
import { FormFooter2 } from '../../components/form-footer';
// hocs
import { withAsyncRolesByUserType } from '../../hocs';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const validationSchemaObject = {
  [GC.FIELD_USER_LOGIN_ID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_USER_PASSWORD]: Yup.string()
    .min(4)
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_USER_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getFieldIsNotValidEmailTxt()),
  [GC.FIELD_ROLE_ROLES_GUIDS]: Yup.array().nullable(true),
};

const defaultStyles = {
  inputWrapperStyles: { mb: 25, mt: 15, width: 370 },
};

const defaultValues = {
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_USER_EMAIL]: null,
  [GC.FIELD_USER_PASSWORD]: null,
  [GC.FIELD_USER_LOGIN_ID]: null,
  [GC.FIELD_ROLE_ROLES_GUIDS]: null,
};

const formFields = {
  [GC.FIELD_USER_LOGIN_ID]: {
    ...defaultStyles,
    type: 'text',
    isRequired: true,
    autoComplete: 'off',
    fieldName: GC.FIELD_USER_LOGIN_ID,
    label: G.getWindowLocale('titles:user-id', 'User ID'),
  },
  [GC.FIELD_USER_PASSWORD]: {
    ...defaultStyles,
    type: 'password',
    isRequired: true,
    autoComplete: 'off',
    fieldName: GC.FIELD_USER_PASSWORD,
    label: G.getWindowLocale('titles:password', 'Password'),
  },
  [GC.FIELD_USER_EMAIL]: {
    ...defaultStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_USER_EMAIL,
    label: G.getWindowLocale('titles:email', 'Email'),
  },
  [GC.FIELD_ROLE_ROLES_GUIDS]: {
    ...defaultStyles,
    isMulti: true,
    type: 'reactSelect',
    options: GC.FIELD_ROLE_ROLES_GUIDS,
    fieldName: GC.FIELD_ROLE_ROLES_GUIDS,
    inputWrapperStyles: { mb: 0, mt: 15, width: 370 },
    label: G.getWindowLocale('titles:roles', 'Roles'),
  },
};

const enhance = compose(
  withAsyncRolesByUserType({ [GC.FIELD_ROLE_ROLE_TYPE]: null }),
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
    validationSchema: Yup.object().shape(validationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, submitAction } = props;

      closeModal();
      submitAction({ values });
    },
  }),
  pure,
);

const CreateUserForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      rolesGuids={props.rolesGuids}
      fields={R.values(formFields)}
    />
    <FormFooter2 boxStyles={{ pt: 20 }} />
  </form>
);

export default enhance(CreateUserForm);
