import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
import { SelectFleetEntity, withSelectedFleetEntity } from '../../components/select-fleet-entity';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../formik/fieldset2/fieldset';
//////////////////////////////////////////////////

const getFieldSettings = ({ isTruck, selectedFleetEntity, setSelectedFleetEntity }: Object) => [
  {
    isRequired: true,
    type: 'customComponent',
    fieldName: GC.FIELD_UNIT_ID,
    inputWrapperStyles: { mb: 25, width: 270 },
    label: G.ifElse(isTruck, ['titles:truck', 'Truck'], ['titles:trailer', 'Trailer']),
    Component: ({ name, value, hasError, setFieldValue }: Object) => (
      <SelectFleetEntity
        value={value}
        fieldName={name}
        isTruck={isTruck}
        isClearable={true}
        hasError={hasError}
        handleSelect={setFieldValue}
        selectedFleetEntity={selectedFleetEntity}
        setSelectedFleetEntity={setSelectedFleetEntity}
      />
    ),
  },
];

const enhance = compose(
  withSelectedFleetEntity,
  withFormik({
    mapPropsToValues: () => ({ [GC.FIELD_UNIT_ID]: '' }),
    validationSchema: Yup.object().shape({ [GC.FIELD_UNIT_ID]: G.yupStringRequired }),
    handleSubmit: ({ unitId }: Object, { props: { submitAction } }: Object) => submitAction(unitId),
  }),
  pure,
);

export const SelectTruckOrTrailerForm = enhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        fields={getFieldSettings(props)}
        fieldsWrapperStyles={{ pt: 15, px: '0px', justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
});
