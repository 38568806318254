import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withProps, withState } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// features
import { ContactsArray } from '../../features/location/components/contacts-array';
import { BusinessHoursSection } from '../../features/location/components/business-hours-section';
// hocs
import { withAsyncSequence } from '../../hocs';
// components/forms
import { Fieldset2 } from '../formik';
import { withLocationCustomChangeHandler } from '../hocs/with-location-custom-change-handler';
import {
  defaultLatLngFields,
  defaultLocationFields,
  branchContactSectionFieldSettings,
  branchLocationSectionFieldSettings,
  branchLocationValidationSchemaObject,
} from '../settings/location-formik-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('storedValues', 'setStoredValues', {}),
  withProps(() => ({
    sequenceConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
    autogenerateConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    configsNamesArray: [
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    ],
  })),
  withAsyncSequence,
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(branchLocationValidationSchemaObject),
    mapPropsToValues: (props: Object) => {
      const { storedValues, initialValues, asyncSequence, searchedValues } = props;

      const defaultFields = G.ifElse(
        G.isNotNilAndNotEmpty(asyncSequence),
        R.assoc(GC.FIELD_TEMPLATE_ID, asyncSequence, defaultLocationFields),
        defaultLocationFields,
      );

      return G.setInitialFormikValues(
        R.assoc(GC.FIELD_CONTACTS, [], R.mergeRight(defaultFields, defaultLatLngFields)),
        R.mergeRight(storedValues, initialValues),
        searchedValues,
      );
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const { asyncSequence, submitHandler } = props;

      const valuesToUse = R.omit(
        [GC.FIELD_FAX, GC.FIELD_PHONE, GC.FIELD_EMAIL, GC.FIELD_CONTACT_NAME, GC.FIELD_PHONE_EXTENSION],
        values,
      );

      if (R.and(
        R.propEq(asyncSequence, GC.FIELD_TEMPLATE_ID, valuesToUse),
        G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, values)),
      )) {
        return submitHandler(R.assoc(GC.FIELD_TEMPLATE_ID, null, valuesToUse));
      }

      submitHandler(valuesToUse);
    },
  }),
  withLocationCustomChangeHandler,
  pure,
);

const BranchLocationForm = (props: Object) => {
  const { values, handleSubmit, optionsForSelect, handleCustomChange } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        handleChange={handleCustomChange}
        fields={branchLocationSectionFieldSettings}
        fieldsWrapperStyles={{ mt: 25, mb: 20, px: 15, justifyContent: 'space-between' }}
      />
      <ContactsArray
        {...props}
        contactRowFields={branchContactSectionFieldSettings}
        contacts={R.pathOr([], [GC.FIELD_CONTACTS], values)}
      />
      <BusinessHoursSection
        {...props}
        iconsML={20}
        maxWidth={430}
        componentType='event'
        useFormikSetFieldValue={true}
        operationHour={R.pathOr([], [GC.FIELD_OPERATION_HOUR], values)}
      />
      <FormFooter2 boxStyles={{ p: 15 }} />
    </form>
  );
};

export default enhance(BranchLocationForm);
