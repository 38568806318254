import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  COUNTRY_OPTIONS,
  getSharedAccessorialAutoApplyToTypeOptions,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  DEFAULT_VENDOR_ACCESSORIAL_RATE_TYPE_OPTIONS,
  DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '220px',
  afterTop: '15px',
  errorTop: '110%',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
  inputsFlexDirection: 'column',
};

const inputWrapperStyles = {
  ml: 20,
  mb: 25,
  width: 230,
};

const geoFencingZoneInputWrapperStyles = {
  mb: 25,
  width: 200,
};

export const defaultFuelIndexVariableFields = () => (
  {
    id: G.genShortId(),
    [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_RATE]: null,
    [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_TO]: null,
    [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_FROM]: null,
  }
);

export const defaultAccessorialFieldSettings = {
  [GC.FIELD_NAME]: null,
  useGeoFencingZone: false,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_MAX_STOPS]: null,
  [GC.FIELD_MIN_STOPS]: null,
  [GC.FIELD_CHARGE_RATE]: null,
  [GC.FIELD_CHARGE_MIN_RATE]: null,
  [GC.FIELD_CHARGE_MAX_RATE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: null,
  [GC.FIELD_ACCESSORIAL_DISTRICT]: null,
  [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: false,
  [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: null,
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: [],
};

const fuelIndexVariablesValidationSchemaObject = {
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_TO]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999))
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_FROM]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999))
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_CHARGE_RATE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(0, G.getShouldBeFromToLocaleTxt(0, 999999))
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999))
    .typeError('Should be a ${type}'), // eslint-disable-line
};

const accessorialValidationSchemaObject = {
  [GC.FIELD_CHARGE_RATE_UNIT]: Yup.string().nullable(true),
  [GC.FIELD_ACCESSORIAL_DISTRICT]: Yup.string().nullable(true),
  [GC.FIELD_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CURRENCY]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CHARGE_RATE_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_MIN_STOPS]: Yup.number()
    .nullable(true)
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(99, G.getShouldBeLessOrEqualLocaleTxt(99)),
  [GC.FIELD_MAX_STOPS]: Yup.number()
    .nullable(true)
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(99, G.getShouldBeLessOrEqualLocaleTxt(99)),
  [GC.FIELD_CHARGE_MIN_RATE]: Yup.number()
    .nullable(true)
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999)),
  [GC.FIELD_CHARGE_MAX_RATE]: Yup.number()
    .nullable(true)
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999)),
  [GC.FIELD_CHARGE_RATE]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(9999999, G.getShouldBeLessOrEqualLocaleTxt(999999)),
};

export const getAccessorialValidationSchemaObject = (
  useFuelIndex: boolean,
  fromSharedAccessorialList: boolean = false,
) => {
  let schema = accessorialValidationSchemaObject;

  if (G.isFalse(fromSharedAccessorialList)) schema = R.dissoc(GC.FIELD_NAME, schema);

  if (useFuelIndex) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CHARGE_RATE]: Yup.string().nullable(true),
        [GC.FIELD_ACCESSORIAL_DISTRICT]: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt()),
        [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES]: Yup.array()
          .nullable(true)
          .of(Yup.object(fuelIndexVariablesValidationSchemaObject)),
      },
    );
  }

  return schema;
};

export const fuelIndexVariableFields = [
  {
    isRequired: true,
    label: ['titles:rate'],
    inputWrapperStyles: { mr: 20, width: 230 },
    fieldName: GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_RATE,
  },
  {
    isRequired: true,
    label: ['titles:index-from'],
    inputWrapperStyles: { mr: 20, width: 230 },
    fieldName: GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_FROM,
  },
  {
    isRequired: true,
    label: ['titles:index-to'],
    inputWrapperStyles: { width: 230 },
    fieldName: GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_TO,
  },
];

const fieldName = {
  type: 'text',
  isRequired: true,
  label: ['titles:name'],
  fieldName: GC.FIELD_NAME,
  inputWrapperStyles: { mr: 20, width: 230 },
};

const autoApplyToField = {
  ...defaultStyles,
  width: 180,
  zIndex: 10,
  isMulti: true,
  type: 'reactSelect',
  label: ['titles:auto-apply-to'],
  fieldName: GC.FIELD_AUTO_APPLY_TO,
  options: getSharedAccessorialAutoApplyToTypeOptions(),
  inputWrapperStyles: { ml: 20, width: 230, zIndex: 12 },
};

const accessorialSection = [
  {
    isRequired: true,
    type: 'reactSelect',
    shouldCustomChange: true,
    label: ['titles:accessorial'],
    options: 'accessorialConfigOptions',
    inputWrapperStyles: { width: 230, zIndex: 12 },
    fieldName: GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID,
    disabled: (field: any, { shared }: Object) => shared,
  },
  {
    type: 'toggle',
    disabled: true,
    inputWrapperStyles: { ml: 20 },
    label: ['titles:fuel-related'],
    fieldName: GC.FIELD_FUEL_RELATED,
  },
  {
    type: 'toggle',
    disabled: true,
    alignItems: 'center',
    label: ['titles:non-taxable'],
    inputWrapperStyles: { ml: 8 },
    fieldName: GC.FIELD_NON_TAXABLE,
  },
];

const accessorialDeduction = {
  type: 'toggle',
  label: ['titles:deduction'],
  inputWrapperStyles: { ml: 10 },
  fieldName: GC.FIELD_DEDUCTION,
};

const onlyIncludeToInvoice = {
  type: 'toggle',
  inputWrapperStyles: { ml: 10 },
  fieldName: GC.FIELD_ONLY_INCLUDE_TO_INVOICE,
  label: ['titles:only-include-to-invoice', 'Only Include To Invoice'],
};

const rateSection = [
  {
    step: 'any',
    type: 'number',
    isRequired: true,
    label: ['titles:rate'],
    inputWrapperStyles: { mb: 25, width: 230 },
    fieldName: GC.FIELD_CHARGE_RATE,
    disabled: (field: any, { shared, useFuelIndex }: Object) => R.or(
      shared,
      useFuelIndex,
    ),
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:currency'],
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
    disabled: (field: any, { shared }: Object) => shared,
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    disabled: (field: any, { shared }: Object) => shared,
    options: DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:rate-unit'],
    options: GC.FIELD_CHARGE_RATE_UNIT,
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    disabled: (field: any, { shared }: Object) => shared,
    optionsGroups: DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
  },
  {
    step: 'any',
    type: 'number',
    label: ['titles:rate-minimum'],
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
    inputWrapperStyles: { mb: 25, width: 230 },
    disabled: (field: any, values: Object) => R.or(
      values.shared,
      R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, values),
    ),
  },
  {
    step: 'any',
    type: 'number',
    inputWrapperStyles,
    label: ['titles:rate-maximum'],
    fieldName: GC.FIELD_CHARGE_MAX_RATE,
    disabled: (field: any, values: Object) => R.or(
      values.shared,
      R.propEq(GC.CHARGE_RATE_TYPE_FLAT, GC.FIELD_CHARGE_RATE_TYPE, values),
    ),
  },
  {
    ...defaultStyles,
    step: 'any',
    type: 'number',
    label: ['titles:min-stops'],
    fieldName: GC.FIELD_MIN_STOPS,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values }: Object) => G.ifElse(
        R.propEq(GC.CHARGE_RATE_TYPE_STOP, GC.FIELD_RATE_TYPE, values),
        'flex',
        'none',
      ),
    },
  },
  {
    step: 'any',
    type: 'number',
    label: ['titles:max-stops'],
    fieldName: GC.FIELD_MAX_STOPS,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: ({ values }: Object) => G.ifElse(
        R.propEq(GC.CHARGE_RATE_TYPE_STOP, GC.FIELD_RATE_TYPE, values),
        'flex',
        'none',
      ),
    },
  },
];

const carrierRateSection = R.insert(
  2,
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    disabled: (field: any, { shared }: Object) => shared,
    options: R.reject(
      R.propEq(GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE, 'value'),
      DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
    ),
  },
  R.reject(R.propEq(GC.FIELD_CHARGE_RATE_TYPE, 'fieldName'), rateSection),
);

const vendorRateSection = R.insert(
  2,
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    disabled: (field: any, { shared }: Object) => shared,
    options: DEFAULT_VENDOR_ACCESSORIAL_RATE_TYPE_OPTIONS,
  },
  R.reject(R.propEq(GC.FIELD_CHARGE_RATE_TYPE, 'fieldName'), rateSection),
);

export const districtOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: 'US',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_US,
  },
  {
    label: 'Midwest',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_MIDWEST,
  },
  {
    label: 'East Cost',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_EAST_COST,
  },
  {
    label: 'Gulf Coast',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_GULF_COAST,
  },
  {
    label: 'West Coast',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_WEST_COAST,
  },
  {
    label: 'California',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_CALIFORNIA,
  },
  {
    label: 'New England',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_NEW_ENGLAND,
  },
  {
    label: 'Rocky Mountain',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_ROCKY_MOUNTAIN,
  },
  {
    label: 'Lower Atlantic',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_LOWER_ATLANTIC,
  },
  {
    label: 'Central Atlantic',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_CENTRAL_ATLANTIC,
  },
  {
    label: 'West Coast Except California',
    value: GC.ACCESSORIAL_DISTRICT_TYPE_WEST_COAST_EXCEPT_CALIFORNIA,
  },
];

const districtSection = (useGeoFencingZone: boolean, useFuelIndex: boolean) => [
  {
    type: 'select',
    options: districtOptions,
    label: ['titles:district'],
    fieldName: GC.FIELD_ACCESSORIAL_DISTRICT,
    inputWrapperStyles: { mb: 25, width: 230 },
    disabled: (field: any, { shared }: Object) => shared,
    isRequired: G.ifElse(G.isTrue(useFuelIndex), true, false),
  },
  {
    type: 'toggle',
    shouldCustomChange: true,
    label: ['titles:use-fuel-index'],
    inputWrapperStyles: { mb: 25, ml: 10 },
    fieldName: GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX,
    disabled: (field: any, { shared }: Object) => shared,
  },
  {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: 'useGeoFencingZone',
    label: ['titles:use-geo-fencing-zone'],
    inputWrapperStyles: {
      mb: 25,
      ml: 10,
      display: G.ifElse(useGeoFencingZone, 'flex', 'none'),
    },
    customChangeHandler: (event: Object, _: any, props: Object) => {
      const { values, setValues, handleChange, handleOpenGeoFencingZone } = props;

      if (R.pathEq(true, ['currentTarget', 'checked'], event)) {
        handleOpenGeoFencingZone();
      } else {
        setValues(R.mergeRight(values, { [GC.FIELD_ORIGIN]: null, [GC.FIELD_DESTINATION]: null }));
      }

      handleChange(event);
    },
  },
];

const getAccessorialSection = (options: Object) => {
  if (G.isNilOrEmpty(options)) return accessorialSection;

  const { isDriver, isVendor, fromSharedAccessorialList } = options;

  if (fromSharedAccessorialList) return [fieldName, ...accessorialSection, autoApplyToField];

  if (isVendor) return R.concat(accessorialSection, [accessorialDeduction, onlyIncludeToInvoice]);

  if (isDriver) return R.append(accessorialDeduction, accessorialSection);
};

export const accessorialFieldSettings = {
  rateSection,
  districtSection,
  vendorRateSection,
  carrierRateSection,
  accessorialSection: getAccessorialSection,
  defaultOrderAccessorialSection: R.dropLast(4, carrierRateSection),
};

// geo fencing zone
export const geoFencingZoneFieldSettings = (prefix: string) => [
  {
    type: 'addressAutocomplete',
    label: ['titles:search-city'],
    fieldName: `${prefix}.searchCity`,
    inputWrapperStyles: { ...geoFencingZoneInputWrapperStyles, zIndex: 11 },
  },
  {
    type: 'multiItems',
    label: ['titles:cities'],
    inputWrapperStyles: geoFencingZoneInputWrapperStyles,
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`,
    placeholder: G.getWindowLocale('titles:enter-city-and-press-enter', 'Enter City and Press Enter'),
  },
  {
    type: 'multiItems',
    label: ['titles:states'],
    inputWrapperStyles: geoFencingZoneInputWrapperStyles,
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`,
    placeholder: G.getWindowLocale('titles:enter-state-and-press-enter', 'Enter State and Press Enter'),
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: COUNTRY_OPTIONS,
    label: ['titles:countries'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`,
    inputWrapperStyles: { ...geoFencingZoneInputWrapperStyles, zIndex: 11 },
  },
  {
    type: 'addressAutocomplete',
    label: ['titles:search-zip'],
    fieldName: `${prefix}.searchZip`,
    inputWrapperStyles: geoFencingZoneInputWrapperStyles,
  },
  {
    isMulti: true,
    type: 'multiItems',
    label: ['titles:zip-codes'],
    inputWrapperStyles: geoFencingZoneInputWrapperStyles,
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`,
    placeholder: G.getWindowLocale('titles:enter-zip-and-press-enter', 'Enter Zip and Press Enter'),
  },
  {
    label: ['titles:zip-from'],
    inputWrapperStyles: { width: 200 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`,
  },
  {
    label: ['titles:zip-to'],
    inputWrapperStyles: { width: 200 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`,
  },
];

export const addressPoint = {
  [GC.FIELD_GEO_FENCING_ZONE_CITIES]: [],
  [GC.FIELD_GEO_FENCING_ZONE_STATES]: [],
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES]: [],
  [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES]: null,
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: null,
  [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: null,
};

export const defaultGeoFencingZoneFieldSettings = {
  [GC.FIELD_ORIGIN]: addressPoint,
  [GC.FIELD_DESTINATION]: addressPoint,
};

export const geoFencingZoneValidationSchema = () => Yup.lazy(({ origin, destination }: Object) => {
  const getShouldRequired = R.compose(
    R.lt(0),
    R.length,
    R.values,
    R.filter(G.isNotNilAndNotEmpty),
    R.pick(R.keys(addressPoint)),
  );

  return Yup.object().shape({
    [GC.FIELD_ORIGIN]: Yup.object().nullable(true).shape({
      [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES]: G.ifElse(
        getShouldRequired(origin),
        G.yupArrayRequired,
        G.yupArrayNotRequired,
      ),
    }),
    [GC.FIELD_DESTINATION]: Yup.object().nullable(true).shape({
      [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES]: G.ifElse(
        getShouldRequired(destination),
        G.yupArrayRequired,
        G.yupArrayNotRequired,
      ),
    }),
  });
});
