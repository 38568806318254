import styled from 'styled-components';
//////////////////////////////////////////////////

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  & .Select-value-label, .Select-option {
    white-space: pre-line;
  };
  & .Select-control {
    height: max-content;
  };
  & .Select-multi-value-wrapper {
    word-break: break-word;
  }
  & .Select--single > .Select-control .Select-value {
    padding-right: 0;   
    position: initial;
  };
  & .Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
    padding-right: 0;
  };
  & .Select-value {
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 14px !important;
    position: initial !important;
  }
  &.Select--multi .Select-value {
    display: flex;
    align-items: center;
  }
`;
