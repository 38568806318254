import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const validationSchema = Yup.object().shape({
  [GC.FIELD_TEMPLATE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});

export const defaultValues = {
  [GC.FIELD_TEMPLATE_GUID]: null,
};

export const fields = [
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_TEMPLATE_GUID,
    options: 'documentTemplateOptions',
    label: ['titles:template', 'Template'],
    inputWrapperStyles: { mb: 25, width: 270 },
  },
];
