import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// forms
import { Fieldset2 } from '../';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const fieldSettings = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_TEMPLATE_NAME,
  },
];

const defaultFields = {
  [GC.FIELD_TEMPLATE_NAME]: null,
};

export const validationSchema = () => {
  const schema = {
    [GC.FIELD_TEMPLATE_NAME]: G.yupStringRequired,
  };

  return Yup.object().shape(schema);
};

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const SaveOrderTemplateForm = (props: Object) => {
  const { handleSubmit } = props;

  const formikProps = G.getFormikProps(props);

  return (
    <Box mt={15} width={270}>
      <form onSubmit={handleSubmit}>
        <Fieldset2 {...formikProps} fields={fieldSettings} />
        <FormFooter2 />
      </form>
    </Box>
  );
};

export default enhance(SaveOrderTemplateForm);
