import * as R from 'ramda';
import React, { Fragment } from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// forms
import { Input } from './ui';
// ui
import { AbsoluteBox } from '../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('passwordShown', 'setPasswordShown', false),
  withHandlers({
    handleShowPassword: (props: Object) => () => {
      const { passwordShown, setPasswordShown } = props;

      setPasswordShown(R.not(passwordShown));
    },
  }),
);

const PasswordInput = ((props: Object) => {
  const { isMobile, passwordShown, handleShowPassword, ...restProps } = props;

  return (
    <Fragment>
      <Input {...restProps} type={G.ifElse(G.isTrue(passwordShown), 'text', 'password')} />
      <AbsoluteBox
        top='50%'
        zIndex='3'
        cursor='pointer'
        transform='translateY(-50%)'
        onClick={handleShowPassword}
        right={G.ifElse(isMobile, 30, 10)}
        width={G.ifElse(isMobile, 40, 20)}
        height={G.ifElse(isMobile, 40, 20)}
      >
        {G.ifElse(G.isTrue(passwordShown), I.eyeShow, I.eyeHide)(G.getTheme('colors.darkGrey'))}
      </AbsoluteBox>
    </Fragment>
  );
});

export default enhance(PasswordInput);
