// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultItemFields = {
  [GC.FIELD_ITEM_ID]: null,
  [GC.FIELD_DESCRIPTION]: null,
};

export const defaultContainerFields = {
  [GC.FIELD_TEMPLATE_ID]: null,
  [GC.FIELD_CONTAINER_NUMBER]: null,
  [GC.FIELD_CONTAINER_INITIAL]: null,
};

export const defaultLocationFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_ADDRESS_1]: null,
  [GC.FIELD_ADDRESS_2]: null,
  [GC.FIELD_TEMPLATE_ID]: null,
  [GC.FIELD_LOCATION_NAME]: null,
  [GC.FIELD_LOCATION_TYPE]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

export const itemFields = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_ID,
    label: ['titles:item-id', 'Item ID'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: { ...inputWrapperStyles, mb: 0 },
  },
];

export const containerFields = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:template-id', 'Template ID'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_CONTAINER_NUMBER,
    label: ['titles:container-number', 'Container Number'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_INITIAL,
    inputWrapperStyles: { ...inputWrapperStyles, mb: 0 },
    label: ['titles:container-initial', 'Container Initial'],
  },
];

export const locationFields = [
  {
    type: 'select',
    inputWrapperStyles,
    options: GC.TEMPLATES_LOCATION_TYPE,
    label: ['titles:location-type', 'Location Type'],
    fieldName: `${GC.FIELD_LOCATION_TYPE}:${GC.FIELD_DROPDOWN_OPTION_GUID}`,
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:template-id', 'Template Id'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address1', 'Address1'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ADDRESS_2,
    label: ['titles:address2', 'Address2'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_CITY,
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ZIP,
    label: ['titles:postal-code', 'Postal Code'],
  },
  {
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
    inputWrapperStyles: { ...inputWrapperStyles, mb: 0 },
  },
];
