import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { Map } from '../../../components/map';
import { CustomMarker } from '../../../components/map/ui';
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, RelativeBox, AbsoluteBox } from '../../../ui';
// select-location-form
import { selectLocLatLngFields, selectLocAddressFields, defaultSelectLocationsFields } from './settings';
//////////////////////////////////////////////////

const geocodeAndSetValues = async (props: Object) => {
  const { latitude, longitude, setAddress, setFieldValue, setLocationToSave } = props;

  const coords = { latitude, longitude };

  const result = await G.googleMapsGeocode(coords, 'forms SelectLocation');

  if (G.isNilOrEmpty(result)) return;

  const fields = G.getGoogleGeocodeByAddressDataFromResult(result);

  const { address1, formattedAddress } = fields;

  setAddress(formattedAddress);

  if (G.isNotNilAndNotEmpty(setFieldValue)) {
    setFieldValue(GC.FIELD_ADDRESS, address1);
  }

  setLocationToSave(R.mergeRight(fields, { latitude, longitude }));
};

const enhance = compose(
  withState('address', 'setAddress', '-'),
  withState('locationToSave', 'setLocationToSave', null),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultSelectLocationsFields,
      R.mergeRight(initialValues, {
        [GC.FIELD_LATITUDE]: R.pathOr(38.755157, [GC.FIELD_LATITUDE], initialValues),
        [GC.FIELD_LONGITUDE]: R.pathOr(-98.269035, [GC.FIELD_LONGITUDE], initialValues),
      }),
    ),
  }),
  withHandlers(() => {
    let ref = null;

    const timeout = { changeCenter: null, customChange: null };

    return {
      handleSetRef: () => (r: Object) => {
        if (R.and(r, R.isNil(ref))) ref = r;
      },
      handleCenterChanged: (props: Object) => () => {
        const { values, setValues, setAddress, setFieldValue, setLocationToSave } = props;

        const center = ref.getCenter();

        clearTimeout(timeout.changeCenter);

        timeout.changeCenter = setTimeout(() => {
          if (center) {
            const latitude = center.lat();
            const longitude = center.lng();

            setValues(R.mergeRight(values, { latitude, longitude }));
            geocodeAndSetValues({ latitude, longitude, setAddress, setFieldValue, setLocationToSave });
          }
        }, 300);
      },
      handleCustomChange: (props: Object) => (e: Object) => {
        const { values, setValues, setAddress, handleChange, setFieldValue, setLocationToSave } = props;

        clearTimeout(timeout.customChange);

        handleChange(e);

        timeout.customChange = setTimeout(() => {
          const label = R.path(['target', 'id'], e);

          let length = 0;

          const stringValues = R.compose(
            R.map((value: string) => {
              let valueToReturn = value;

              if (R.isEmpty(value)) {
                valueToReturn = '0';
              }

              length = R.length(valueToReturn);

              return valueToReturn;
            }),
            R.take(2),
            R.split('.'),
          )(G.getEventTargetValue(e));

          let value = parseFloat(R.join('.', stringValues), 10);

          const condition = G.isAllTrue([
            G.isNumber(value),
            G.isNotNaN(value),
            R.gte(R.length(stringValues), 2),
          ]);

          if (condition) {
            value = value.toFixed(length);
          } else {
            value = G.ifElse(G.isNaN(value), 0, value);
          }

          const newValues = R.assoc(label, value, values);

          setValues(newValues);

          const { latitude, longitude } = newValues;

          geocodeAndSetValues({
            latitude,
            longitude,
            setAddress,
            setFieldValue,
            setLocationToSave,
          });
        }, 300);
      },
      handleSetValues: (props: Object) => (values: Object, id: string) => {
        const { setValues, setAddress, setLocationToSave } = props;

        const { address1, latitude, longitude } = values;

        if (R.equals(id, GC.FIELD_ADDRESS)) {
          geocodeAndSetValues({
            latitude,
            longitude,
            setAddress,
            setLocationToSave,
          });
        }

        setValues(R.assoc(GC.FIELD_ADDRESS, address1, values));
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      const { values, setAddress, setLocationToSave } = this.props;

      const { latitude, longitude } = values;

      geocodeAndSetValues({
        latitude,
        longitude,
        setAddress,
        setLocationToSave,
      });
    },
  }),
);

export const SelectLocation = enhance((props: Object) => {
  const {
    values,
    address,
    handleSetRef,
    locationToSave,
    handleSetValues,
    handleCustomChange,
    handleCenterChanged,
    searchTemplateRequest,
  } = props;

  const { latitude, longitude } = values;

  return (
    <RelativeBox>
      <form onSubmit={() => searchTemplateRequest(locationToSave)}>
        <RelativeBox
          p={10}
          zIndex={11}
          width={720}
          bg={G.getTheme('colors.mainBlue')}
        >
          <Flex my={10} justifyContent='space-between'>
            <Fieldset2
              {...G.getFormikProps(props)}
              setValues={handleSetValues}
              fields={selectLocAddressFields}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={selectLocLatLngFields}
              handleChange={handleCustomChange}
            />
          </Flex>
          <Box lineHeight='24px' color={G.getTheme('colors.white')}>{address}</Box>
        </RelativeBox>
        <Box p={10}>
          <Map
            width={700}
            height={500}
            defaultZoom={15}
            handleSetRef={handleSetRef}
            handleCenterChanged={handleCenterChanged}
            center={{
              lat: Number(latitude),
              lng: Number(longitude),
            }}
          />
          <AbsoluteBox bg='red' color='red' top='50%' left='50%' zIndex={20}>
            <AbsoluteBox top='0' left='50%' transform='translate(-50%, calc(-100% - 10px))'>
              <CustomMarker color='red' active={false} />
            </AbsoluteBox>
          </AbsoluteBox>
          <FormFooter2 />
        </Box>
      </form>
    </RelativeBox>
  );
});
