import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Fieldset2 } from '../../';
//////////////////////////////////////////////////

const OPTION = 'option';

const getValidationSchema = (optionRequired: any, isMulti: boolean = false) => {
  if (G.isFalse(optionRequired)) return {};

  if (isMulti) return { [OPTION]: G.yupArrayRequired };

  return {
    [OPTION]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
  };
};

export const getFields = ({
  width,
  label,
  options,
  isMulti,
  useMultiSelectMaxHeight,
  valueContainerMaxHeight,
}: Object) => [
  {
    label,
    isMulti,
    options,
    fieldName: OPTION,
    type: 'reactSelect',
    useMultiSelectMaxHeight,
    valueContainerMaxHeight,
    inputWrapperStyles: { mb: 25, width },
  },
];

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SINGLE_SELECT_DROPDOWN',
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values[OPTION]),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues({ [OPTION]: null }, initialValues),
    validationSchema: ({ isMulti, optionRequired }: Object) =>
      Yup.object().shape(getValidationSchema(optionRequired, isMulti)),
  }),
  pure,
);

export const SelectDropdownForm = enhance((props: Object) => {
  const {
    options,
    handleSubmit,
    cancelAction,
    isMulti = false,
    fieldWidth = 300,
    fieldLabel = null,
    fieldsWrapperStyles,
    valueContainerMaxHeight = 200,
    useMultiSelectMaxHeight = false,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...props}
        fieldsWrapperStyles={{ pt: 15, ...fieldsWrapperStyles }}
        fields={getFields({
          options,
          isMulti,
          width: fieldWidth,
          label: fieldLabel,
          useMultiSelectMaxHeight,
          valueContainerMaxHeight,
        })}
      />
      <FormFooter2 closeModal={cancelAction} />
    </form>
  );
});
