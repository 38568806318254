import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form, Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 130,
};

const initialFields = {
  [GC.FIELD_GUID]: '',
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: '',
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: '',
};

const fields = [
  {
    type: 'timeInput',
    label: ['titles:early-time', 'Early Time'],
    fieldName: GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
    inputWrapperStyles: { ...inputWrapperStyles, mr: 10 },
  },
  {
    type: 'timeInput',
    inputWrapperStyles,
    label: ['titles:late-time', 'Late Time'],
    fieldName: GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
  },
];

const validationSchema = Yup.object().shape({
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: Yup.string()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .notRequired(),
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: Yup.string()
    .nullable(true)
    .typeError('${value}') // eslint-disable-line
    .notRequired(),
});

const convertTime = (fieldName: string, values: Object) => G.convertTimeToConfigFormat(
  R.path([fieldName], values), GC.DEFAULT_TIME_FORMAT,
);

const enhance = compose(
  withFormik({
    validationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      initialFields,
      {
        ...initialValues,
        [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: convertTime(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, initialValues),
        [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: convertTime(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, initialValues),
      },
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const dataForRequest = {
        ...values,
        [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]:
          G.convertTimeToFormat(R.prop(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, values)),
        [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]:
          G.convertTimeToFormat(R.prop(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, values)),
      };

      submitAction(dataForRequest);
    },
  }),
  pure,
);

const AppointmentsTime = enhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        fieldsWrapperStyles={{ mt: 15 }}
      />
      <FormFooter2 />
    </Form>
  );
});

export default AppointmentsTime;
