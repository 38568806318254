import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ReactSelect } from '../../ui';
//////////////////////////////////////////////////

class SelectInput extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched, shouldValidate } = this.props;
    const validate = G.ifElse(G.isNotNilAndNotEmpty(shouldValidate), shouldValidate, true);
    setFieldTouched(id, true, validate);
  };

  handleChange = (value: any) => {
    const inputValue = G.ifElse(
      R.isNil(value),
      {
        value: null,
        label: null,
      },
      value,
    );

    const { id, isMulti, handlers, setFieldValue, shouldHandle, shouldCustomChange } = this.props;

    const handleChangeSelect = R.path(['handleChangeSelect'], handlers);
    if (G.isTrue(isMulti)) {
      const data = R.map(
        (item: Object) => R.prop('value', item),
        inputValue,
      );
      setFieldValue(id, data);
    } else {
      setFieldValue(id, R.prop('value', inputValue));
    }
    if (R.and(G.isTrue(shouldHandle), R.is(Function, handleChangeSelect))) {
      if (G.isTrue(isMulti)) {
        const data = R.map(
          (item: Object) => R.prop('value', item),
          inputValue,
        );
        handleChangeSelect(id, data);
      } else {
        handleChangeSelect(id, R.prop('value', inputValue));
      }
    }
    if (shouldCustomChange) {
      const handleCustomChange = R.path(['handleCustomChange'], handlers);
      let fieldValue;
      if (G.isTrue(isMulti)) {
        fieldValue = R.map(
          (item: Object) => R.prop('value', item),
          inputValue,
        );
      } else {
        fieldValue = R.prop('value', inputValue);
      }
      const field = { [id]: fieldValue };
      handleCustomChange({
        field,
        name: id,
        value: fieldValue,
      });
    }
  };

  render() {
    return (
      <ReactSelect
        height={30}
        id={this.props.id}
        value={this.props.value}
        onBlur={this.handleBlur}
        onKeyDown={this.onKeyDown}
        onChange={this.handleChange}
        isMulti={this.props.isMulti}
        options={this.props.options}
        hasError={this.props.hasError}
        placeholder={this.props.placeholder}
        isDisabled={this.props.disabled || false}
        additionalStyles={this.props.reactSelectStyles}
        closeMenuOnScroll={this.props.closeMenuOnScroll}
        closeMenuOnSelect={this.props.closeMenuOnSelect}
        useMenuPortalTarget={this.props.useMenuPortalTarget}
      />
    );
  }
}

export default SelectInput;
