import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Fieldset2 } from '../../';
//////////////////////////////////////////////////

const VALUE = 'value';

const getValidationSchema = (valueRequired: any) => G.ifElse(
  G.isFalse(valueRequired),
  {},
  {
    [VALUE]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
  },
);

const getFields = (label: any, fieldWidth: number) => [
  {
    type: 'text',
    label: [label],
    isRequired: true,
    fieldName: VALUE,
    inputWrapperStyles: { width: fieldWidth },
  },
];

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SINGLE_INPUT',
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values[VALUE]),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues({ [VALUE]: null }, initialValues),
    validationSchema: (props: Object) => Yup.object()
      .shape(getValidationSchema(R.pathOr(true, ['valueRequired'], props))),
  }),
  pure,
);

export const SingleInputForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      onChange={props.handleChange}
      fieldsWrapperStyles={{ mt: 10 }}
      fields={getFields(R.propOr(null, 'fieldLabel', props), R.propOr(300, 'fieldWidth', props))}
    />
    <FormFooter2 boxStyles={{ mt: 20 }} />
  </form>
));
