import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// schemes
import * as VS from '../../../schemes';
// settings
import { defaultPayTermFields, defaultContactFields, defaultLocationFields } from '../../../settings';
// bill-remit-to
import { BillToSection } from './sections';
//////////////////////////////////////////////////

const validationSchemaObject = Yup.object()
  .shape(VS.validationSchemaLocationObject)
  .shape(VS.validationSchemaPayTermObject)
  .shape(VS.validationSchemaContactObject);

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues, searchedValues }: Object) => G.setInitialLocationFormikValues(
      {
        ...defaultPayTermFields,
        ...defaultContactFields,
        ...defaultLocationFields,
      },
      initialValues,
      searchedValues,
    ),
    validationSchema: validationSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal, updateBillToRequest } = props;

      updateBillToRequest(values);
      closeModal();
    },
    displayName: 'BILL_TO_FORM',
  }),
  pure,
);

export const BillToForm = (props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <BillToSection {...G.getFormikProps(props)} />
      <FormFooter2 boxStyles={{ p: '0 15px 15px 15px' }} />
    </form>
  );
};

export default enhance(BillToForm);
