import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-search
import TemplateSearch from '../';
import { makeSelectContainerTemplates } from '../selectors';
import { getContainerListBySearchRequest } from '../actions';
import { containerFields, defaultContainerFields } from '../settings';
//////////////////////////////////////////////////

const fieldsToUse = [
  GC.FIELD_WIDTH,
  GC.FIELD_HEIGHT,
  GC.FIELD_LENGTH,
  GC.FIELD_WEIGHT_UOM,
  GC.FIELD_TEMPLATE_ID,
  GC.FIELD_DIMENSIONS_UOM,
  GC.FIELD_CONTAINER_NUMBER,
  GC.FIELD_CONTAINER_INITIAL,
  GC.FIELD_CONTAINER_TYPE_GUID,
  GC.FIELD_FULL_CONTAINER_WEIGHT,
  GC.FIELD_EMPTY_CONTAINER_WEIGHT,
];

const makeTemplateSelectOptions = (templates: Array) => (
  R.map((template: Object) => {
    const data = R.filter(
      (item: string) => G.notEquals(item, null),
      R.pick(fieldsToUse, template),
    );
    const secondString = G.createStringFromArray(
      [G.getPropFromObject(GC.FIELD_CONTAINER_NUMBER, data), G.getPropFromObject(GC.FIELD_CONTAINER_INITIAL, data)],
    );

    return ({
      value: data,
      label: `${G.cutString(G.getPropFromObject(GC.FIELD_TEMPLATE_ID, data), 30, true)}
        ${G.cutString(secondString, 30, true)}
      `,
    });
  }, templates)
);

const Component = (props: Object) => (
  <TemplateSearch
    {...props}
    templateFields={containerFields}
    templateList={props.containerTemplates}
    defaultTemplateFields={defaultContainerFields}
    getListTemplatesAction={props.getContainerListBySearchRequest}
    selectOptions={makeTemplateSelectOptions(props.containerTemplates)} />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  containerTemplates: makeSelectContainerTemplates(state),
});

export const ContainerTemplateSearch = connect(mapStateToProps, {
  getContainerListBySearchRequest,
})(Component);
