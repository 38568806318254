import * as R from 'ramda';
import styled from 'styled-components';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import { Fieldset2 } from '../../formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature shared-accessorials
import {
  fuelIndexVariableFields,
  defaultFuelIndexVariableFields,
} from './field-settings';
//////////////////////////////////////////////////

export const UploadWrapper = styled.label`
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  width: max-content;
  align-items: center;

  & input[type=file] {
    display: none;
  }
`;

const blackColor = G.getTheme('colors.light.black');
const lightblueColor = G.getTheme('colors.light.blue');

const importFuelIndexesEnhance = compose(
  withHandlers({
    handleImportFuelIndexVariables: (props: Object) => async (event: Object) => {
      const { values, openLoader, closeLoader, setFieldValue } = props;

      const file = R.path(['currentTarget', 'files', 0], event);

      if (G.isNilOrEmpty(file)) return;

      G.callFunction(openLoader);
      const endpoint = endpointsMap.importIndex;
      const formData = new window.FormData();
      formData.append('file', file);
      const res = await sendRequest('post', endpoint, { data: formData });
      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const indexes = R.compose(
          R.concat(R.pathOr([], [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES], values)),
          R.map((item: Object) => R.assoc(GC.FIELD_ID, G.genShortId(), item)),
          R.filter(G.isNotNilAndNotEmpty),
        )(R.or(data, []));

        setFieldValue(GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES, indexes);
      } else {
        G.handleFailResponseSimple(res, true, 'handleImportFuelIndexVariables fail');
      }

      G.callFunction(closeLoader);
    },
    handleDownloadTemplate: ({ openLoader, closeLoader }: Object) => async () => {
      G.callFunction(openLoader);

      const options = { resType: 'arraybuffer' };

      const res = await sendRequest('get', endpointsMap.downloadIndexTemplate, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  pure,
);

const ImportFuelIndexesSection = importFuelIndexesEnhance((props: Object) => (
  <Fragment>
    <UploadWrapper>
      <input
        value=''
        type='file'
        id='fuel-index-variables-file'
        onChange={props.handleImportFuelIndexVariables}
      />
      <Box fontWeight='bold' color={lightblueColor}>
        {G.getWindowLocale('titles:import-fuel-index-variables', 'Import Fuel Index Variables')}
      </Box>
      <Box ml={10}>
        {I.downloadDocument(blackColor)}
      </Box>
    </UploadWrapper>
    <Flex ml={20} cursor='pointer' onClick={props.handleDownloadTemplate}>
      <Box fontWeight='bold' color={lightblueColor}>
        {G.getWindowLocale('titles:download-template', 'Download Template')}
      </Box>
      <Box ml={10}>
        {I.downloadDocument(blackColor)}
      </Box>
    </Flex>
  </Fragment>
));

const FuelIndexVariablesSection = (props: Object) => {
  const {
    push,
    form,
    remove,
    openLoader,
    closeLoader,
    importFuelIndexVariables,
  } = props;

  const { values, setFieldValue } = form;

  const fuelIndexVariables = R.path(['values', GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES], form);
  const arrayLength = R.length(fuelIndexVariables);
  const shared = G.getPropFromObject('shared', values);

  const handleAddRow = () => {
    if (shared) return;

    push(defaultFuelIndexVariableFields());
  };

  const handleRemoveRow = (rowIndex: number) => {
    const hideFuelIndexVariableSection = R.compose(
      R.equals(1),
      R.length,
      R.prop(GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES),
    )(values);

    if (hideFuelIndexVariableSection) {
      setFieldValue(GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX, false);
    }

    remove(rowIndex);
  };

  return (
    <Box
    // TODO: add styles for thinner scroll
      mb={10}
      maxHeight='calc(100vh - 420px)'
      overflow={G.ifElse(R.gt(arrayLength, 7), 'auto')}
    >
      <Flex mb={20}>
        <Flex
          cursor='pointer'
          width='max-content'
          onClick={handleAddRow}
        >
          <Box fontWeight='bold' color={lightblueColor}>
            {G.getWindowLocale('titles:fuel-index-variables', 'Fuel Index Variables')}
          </Box>
          {
            R.not(shared) &&
            <Box ml={10}>
              {I.plusRound()}
            </Box>
          }
        </Flex>
        {
          G.isTrue(importFuelIndexVariables) &&
          <ImportFuelIndexesSection
            openLoader={openLoader}
            closeLoader={closeLoader}
            {...R.pick(['values', 'setFieldValue'], form)}
          />
        }
      </Flex>
      {
        G.isNotNilAndNotEmpty(fuelIndexVariables) &&
        fuelIndexVariables.map((_: any, index: number) => (
          <Flex key={index}>
            {
              R.not(shared) &&
              <Box
                mr={10}
                mb={20}
                cursor='pointer'
                onClick={() => handleRemoveRow(index)}
              >
                {I.trash()}
              </Box>
            }
            <Fieldset2
              {...G.getFormikProps(form)}
              itemIndex={index}
              fieldsetType='array'
              arrayLength={arrayLength}
              fields={fuelIndexVariableFields}
              arrayName={GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES}
              fieldsWrapperStyles={{ mb: G.ifElse(R.equals(R.inc(index), arrayLength), 15, 25) }}
            />
          </Flex>
        ))
      }
    </Box>
  );
};

export default FuelIndexVariablesSection;
