import React from 'react';
import * as R from 'ramda';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
// forms
import { MultiValuesInput } from './ui';
// ui
import { Box, Flex, RelativeBox } from '../../../ui';
//////////////////////////////////////////////////

const isInList = (currentEmail: string, emails: Object) => R.includes(currentEmail, emails);

const isEmail = (email: string) => GC.EMAIL_REGEXP.test(email);

const isValid = (props: Object, inputValue: any) => {
  const { emails, setError } = props;

  let error = null;

  if (isInList(inputValue, emails)) {
    error = `
      ${inputValue}
      ${G.getWindowLocale('titles:has-already-been-added', 'has already been added')}
    `;
  }

  if (R.not(isEmail(inputValue))) {
    error = `
      ${inputValue}
      ${G.getWindowLocale('titles:is-not-email', 'is not a valid email address')}
    `;
  }

  if (G.isNotNilAndNotEmpty(error)) {
    setError(error);

    return false;
  }

  return true;
};

const maxWidthCalc = (value: any) => {
  if (G.isNumber(value)) {
    return `calc(${value}px - 41px)`;
  }

  return `calc(${value} - 41px)`;
};

const getBorderColor = (hasError: boolean, borderColor: string) => {
  if (G.isTrue(hasError)) return G.getTheme('forms.inputs.borderColorErr');

  return R.or(borderColor, G.getTheme('forms.inputs.borderColor'));
};

const addNewEmail = (props: Object) => {
  const { emails, inputValue, setEmails, setInputValue } = props;

  const inputValueToUse = R.trim(inputValue);

  if (G.isNotNilAndNotEmpty(inputValueToUse)) {
    if (isValid(props, inputValueToUse)) {
      setInputValue('');
      setEmails(R.append(inputValueToUse, emails));
    }
  }
};

const enhance = compose(
  withState('error', 'setError', null),
  withState('inputValue', 'setInputValue', ''),
  withState('emails', 'setEmails', ({ value }: Object) =>
    G.ifElse(
      G.isNotNilAndNotEmpty(value),
      G.ifElse(G.isArray(value), value, R.of(Array, value)),
      [],
    ),
  ),
  withHandlers({
    handleKeyDown: (props: Object) => (event: Object) => {
      const eventKeyCodes = [GC.EVENT_KEY_CODE_TAB, GC.EVENT_KEY_CODE_ENTER, GC.EVENT_KEY_CODE_COMMA];

      if (R.includes(R.prop('keyCode', event), eventKeyCodes)) {
        event.preventDefault();

        addNewEmail(props);
      }
    },
    handleChangeEmailInput: (props: Object) => (event: Object) => {
      const { setError, setInputValue } = props;

      setError(null);
      setInputValue(R.path(['target', 'value'], event));
    },
    handleDeleteEmail: (props: Object) => (email: string) => {
      const { emails, setEmails } = props;

      setEmails(R.filter((item: Object) => G.notEquals(item, email), emails));
    },
    handlePaste: (props: Object) => (event: Object) => {
      event.preventDefault();

      const { emails, setEmails } = props;

      const paste = R.prop('clipboardData', event).getData('text');
      const emailsToUse = R.uniq(paste.match(GC.EMAIL_REGEXP_GLOBAL));

      if (G.isNotNilAndNotEmpty(emailsToUse)) {
        const toBeAdded = R.filter((email: string) => R.not(isInList(email, emails)), emailsToUse);

        setEmails([...emails, ...toBeAdded]);
      }
    },
    handleOverrideEmails: (props: Object) => () => {
      const { value, setEmails, shouldOverrideEmails } = props;

      if (G.isTrue(shouldOverrideEmails)) {
        const emails = G.ifElse(
          G.isNotNilAndNotEmpty(value),
          G.ifElse(G.isArray(value), value, R.of(Array, value)),
          [],
        );

        setEmails(emails);
      }
    },
    handleOnOuterClick: (props: Object) => () => addNewEmail(props),
  }),
  withPropsOnChange(['error'], ({ id, error, setFieldError }: Object) => setFieldError(id, error)),
  withPropsOnChange(['emails'], ({ id, emails, setFieldValue }: Object) => setFieldValue(id, emails)),
  withComponentDidUpdatePropCallback({
    propName: 'value',
    callbackName: 'handleOverrideEmails',
  }),
  pure,
);

const MultiEmailInput = (props: Object) => {
  const {
    id,
    width,
    emails,
    hasError,
    disabled,
    minHeight,
    inputValue,
    borderColor,
    handlePaste,
    borderRadius,
    handleKeyDown,
    setFieldTouched,
    handleDeleteEmail,
    handleOnOuterClick,
    handleChangeEmailInput,
  } = props;

  return (
    <Flex
      p='5px 10px'
      height='auto'
      width={width}
      flexWrap='wrap'
      border='1px solid'
      borderRadius={borderRadius}
      minHeight={R.or(minHeight, 30)}
      background={G.getTheme('colors.white')}
      borderColor={getBorderColor(hasError, borderColor)}
    >
      {emails.map((item: string, index: number) => (
        <Flex
          mr={10}
          my='5px'
          key={index}
          width='100%'
          fontSize={12}
          color={G.getTheme('colors.light.blue')}
        >
          <TextComponent
            key={index}
            title={item}
            fontSize={12}
            withEllipsis={true}
            maxWidth={maxWidthCalc(width)}
          >
            {item}
          </TextComponent>
          <Box
            ml='3px'
            px='4px'
            fontSize={14}
            cursor='pointer'
            borderRadius='50%'
            color={G.getTheme('colors.light.grey')}
            onClick={() => handleDeleteEmail(item)}
            border={`1px solid ${G.getTheme('colors.light.blue')}`}
          >
            &times;
          </Box>
        </Flex>
      ))}
      <OuterClick width='100%' as={RelativeBox} onOuterClick={handleOnOuterClick}>
        <MultiValuesInput
          id={id}
          width='100%'
          value={inputValue}
          disabled={disabled}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          onChange={handleChangeEmailInput}
          onBlur={() => G.isFunction(setFieldTouched) && setFieldTouched(id, true, false)}
          placeholder={G.getWindowLocale('titles:enter-email-and-press-enter', 'Enter email and press Enter')}
        />
      </OuterClick>
    </Flex>
  );
};

export default enhance(MultiEmailInput);
