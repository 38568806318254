import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Form, Fieldset2, UploadWrapper } from '../../';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedFlex, AbsoluteBox, ActionButton } from '../../../ui';
// feature dispatch-detail
import {
  getDocumentFields,
  defaultDocumentsFields,
  getDocumentsFormValidationSchemaObject,
} from './formik-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('fileErrors', 'setFileErrors', null),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultDocumentsFields,
      props.initialValues,
      props.searchedValues,
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getDocumentsFormValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const {
        setFileErrors,
        availableDocumentTypes,
        handleActionLoadDocument,
      } = props;

      const callback = (errors: Object) => {
        setSubmitting(false);
        setFileErrors(errors);
      };

      let documentType = G.ifElse(
        R.and(
          R.is(String, values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE]),
          R.isEmpty(values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE]),
        ),
        null,
        values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE],
      );

      if (R.is(String, documentType)) {
        documentType = G.transformGuidToRequest(values[GC.FIELD_DOCUMENT_DOCUMENT_TYPE], availableDocumentTypes);
      }

      let data = R.assoc(GC.FIELD_DOCUMENT_DOCUMENT_TYPE, documentType, values);

      if (G.isNilOrEmpty(values[GC.FIELD_DOCUMENT_URL])) {
        data = R.omit([GC.FIELD_DOCUMENT_URL], data);
      }

      handleActionLoadDocument(data, callback);
    },
  }),
  withHandlers({
    handleAddFile: ({ values, setFieldValue }: Object) => (e: Object, index: number) => {
      const targetFiles = e.currentTarget.files;
      const prevFiles = R.prop(GC.FIELD_DOCUMENT_UPLOAD, values);

      let newFiles = [];

      for (let i = 0, count = targetFiles.length; i < count; i++) {
        newFiles = R.append(targetFiles[i], newFiles);
      }

      let files;

      if (G.isNotNil(R.path([index], prevFiles))) {
        if (R.propEq(1, 'length', newFiles)) {
          files = R.set(R.lensIndex(index), R.head(newFiles), prevFiles);
        } else {
          files = R.compose(
            R.concat(newFiles),
            R.remove(index, 1),
          )(prevFiles);
        }
      } else {
        files = R.concat(prevFiles, newFiles);
      }

      setFieldValue(GC.FIELD_DOCUMENT_UPLOAD, files);
    },
    handleRemoveFile: ({ values, setFieldValue }: Object) => (e: Object, index: number) => {
      e.preventDefault();
      setFieldValue(GC.FIELD_DOCUMENT_UPLOAD, R.remove(index, 1, R.prop(GC.FIELD_DOCUMENT_UPLOAD, values)));
    },
  }),
  pure,
);

const Footer = ({ closeModal, fileErrors }: Object) => {
  const darkBlueColor = G.getTheme('colors.dark.blue');

  if (G.isNotNil(fileErrors)) {
    return (
      <Flex mt={15} justifyContent='space-around'>
        <ActionButton
          width='50%'
          height={32}
          fontSize={14}
          bgColor='none'
          padding='4px 8px'
          background='none'
          border='1px solid'
          borderRadius='5px'
          onClick={closeModal}
          textColor={darkBlueColor}
          borderColor={darkBlueColor}
        >
          {G.getWindowLocale('titles:ok', 'OK', { caseAction: 'upperCase' })}
        </ActionButton>
      </Flex>
    );
  }

  return <FormFooter2 boxStyles={{ mt: 15 }} />;
};

const getAvailableDocTypeOptions = (props: Object) => (
  R.compose(
    R.map((item: Object) => ({
      label: item.displayedValue,
      value: G.getParentGuidOrGuidFromObject(item),
    })),
    R.pathOr([], [GC.AVAILABLE_DOCUMENT_TYPES]),
  )(props)
);

const getEventGuidOptions = (props: Object) => {
  const loadEventIndex = G.ifElse(
    R.equals(props.entityName, GC.FIELD_TEL),
    GC.FIELD_TEL_EVENT_INDEX,
    GC.FIELD_CLO_EVENT_INDEX,
  );

  let events = R.sortBy((item: Object) => R.prop(loadEventIndex, item), R.path(['load', 'events'], props));

  if (R.propEq(GC.DOCUMENT_PROOF_TYPE_POP, GC.FIELD_DOCUMENT_PROOF_TYPE, R.path(['values'], props))) {
    events = events.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_PICKUP));
  }

  if (R.propEq(GC.DOCUMENT_PROOF_TYPE_POD, GC.FIELD_DOCUMENT_PROOF_TYPE, R.path(['values'], props))) {
    events = events.filter((event: Object) => R.equals(event.eventType, GC.EVENT_TYPE_DROP));
  }

  let entityName = props.entityName;

  if (R.pathEq(GC.FIELD_CLO, ['load', 'loadType'], props)) {
    events = events.filter((event: Object) => G.isTrue(event.cloEvent));
    entityName = GC.FIELD_CLO;
  }

  return G.createStopOptions(events, R.equals(entityName, GC.FIELD_TEL));
};

const DocumentsArray = (props: Object) => {
  const {
    id,
    values,
    fileErrors,
    handleAddFile,
    handleRemoveFile,
  } = props;

  const list = R.compose(
    R.append(null),
    R.pathOr([], [GC.FIELD_DOCUMENT_UPLOAD]),
  )(values);

  const hasErrors = G.isNotNilAndNotEmpty(fileErrors);

  return (
    <FieldArray
      name={GC.FIELD_DOCUMENT_UPLOAD}
      render={() => (
        <Flex
          pt={15}
          overflow='auto'
          maxHeight={250}
          borderTop='1px solid'
          flexDirection='column'
          borderColor={G.getTheme('colors.light.grey')}
        >
          {
            list.map((file: any, i: number) => {
              const displayText = G.ifElse(
                G.isString(file),
                file,
                R.pathOr(
                  G.getWindowLocale('titles:select-file', 'Select File'),
                  [GC.FIELD_NAME],
                  file,
                ),
              );

              const fileError = G.isNotNilAndNotEmpty(R.path([i], fileErrors));
              const mb = G.ifElse(R.or(fileError, R.isNil(file)), '0', '8px');
              const statusIcon = G.ifElse(fileError, I.uiFalse, I.uiTrue);

              const component = (
                <UploadWrapper
                  key={i}
                  mb={mb}
                  width={400}
                  display='flex'
                  useAdditionalBorderColor={true}
                  additionalBorderColor={G.getTheme('colors.dark.grey')}
                >
                  {
                    hasErrors && G.isNotNil(file) &&
                    <Box ml='6px'>{statusIcon()}</Box>
                  }
                  <input
                    id={id}
                    value=''
                    type='file'
                    multiple={true}
                    title={displayText}
                    onChange={(e: Object) => handleAddFile(e, i)}
                  />
                  <TextComponent
                    px='10px'
                    display='block'
                    withEllipsis={true}
                    title={displayText}
                    width={`calc(100% - ${G.ifElse(hasErrors, 50, 30)}px)`}
                  >
                    {displayText}
                  </TextComponent>
                  {
                    G.isNotNilAndNotEmpty(file) &&
                    <AbsoluteBox
                      p='7px'
                      top='0px'
                      right={20}
                      onClick={(e: Object) => handleRemoveFile(e, i)}
                    >
                      {I.trash(G.getTheme('icons.iconColor'))}
                    </AbsoluteBox>
                  }
                </UploadWrapper>
              );

              if (R.and(fileError, G.isNotNil(file))) {
                return (
                  <Flex flexDirection='column'>
                    {component}
                    <TextComponent
                      mb='8px'
                      width='100%'
                      fontSize={10}
                      display='block'
                      withEllipsis={true}
                      title={fileErrors[i]}
                      color={G.getTheme('colors.light.mainRed')}
                    >
                      {fileErrors[i]}
                    </TextComponent>
                  </Flex>
                );
              }

              if (R.isNil(file)) {
                return (
                  <StickedFlex
                    bottom='-1px'
                    minHeight={33}
                    zIndex='unset'
                    bg={G.getTheme('colors.light.mainLight')}
                  >
                    {component}
                  </StickedFlex>
                );
              }

              return component;
            })
          }
        </Flex>
      )}
    />
  );
};

const getFields = (values: Object, onlyGeneralFile: any) => {
  const fields = getDocumentFields(values);

  if (G.isTrue(onlyGeneralFile)) return R.remove(2, 4, fields);

  return R.init(fields);
};

const AddLoadDocumentsForm = (props: Object) => {
  const {
    values,
    fileErrors,
    closeModal,
    handleSubmit,
    onlyGeneralFile,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        eventsOptions={getEventGuidOptions(props)}
        fields={getFields(values, onlyGeneralFile)}
        fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
        availableDocumentTypes={getAvailableDocTypeOptions(props)}
      />
      {
        G.isNilOrEmpty(R.path(['values', GC.FIELD_DOCUMENT_URL], props)) &&
        <DocumentsArray {...props} />
      }
      <Footer fileErrors={fileErrors} closeModal={closeModal} />
    </Form>
  );
};

export default enhance(AddLoadDocumentsForm);
