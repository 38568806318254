import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// schemes
import * as VS from '../../../schemes';
// settings
import { defaultContactFields, defaultLocationFields } from '../../../settings';
// bill-remit-to
import { RemitToSection } from './sections';
//////////////////////////////////////////////////

const validationSchemaObject = Yup.object()
  .shape(VS.validationSchemaLocationObject)
  .shape(VS.validationSchemaContactObject);

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialLocationFormikValues(
      {
        ...defaultContactFields,
        ...defaultLocationFields,
      },
      props.initialValues,
      props.searchedValues,
    ),
    validationSchema: validationSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => {
      props.updateRemitToRequest(values);
      props.closeModal();
    },
    displayName: 'REMIT_TO_FORM',
  }),
  pure,
);

const RemitToForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <RemitToSection {...G.getFormikProps(props)} optionsForSelect={props.optionsForSelect} />
    <FormFooter2 boxStyles={{ p: '0 15px 15px' }} />
  </form>
);

export default enhance(RemitToForm);
