import React from 'react';
import * as R from 'ramda';
import { getIn } from 'formik';
import { OuterClick } from 'react-outer-click';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { Switcher } from '../../components/switcher';
import { TextComponent } from '../../components/text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { PHONE_COUNTRIES } from '../../helpers/options';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, ReactAsyncSelect } from '../../ui';
// forms
import Calendar from './calendar';
import SelectInput from './select';
import { Toggle } from '../restyled';
import MultiEmailInput from './email';
import MultiItemsInput from './multi-items';
import CountryDropdownSelect from './country';
import PlacesAutocompleteInput from './places';
import DatePickerMuiFormik from './datepicker-mui';
import PasswordInput from './fieldset2/password-input';
import { MaskedTimeInput } from './fieldset2/date-time-input';
import {
  Info,
  Label,
  Input,
  Error,
  Textarea,
  CleanWrapper,
  renderOptions,
  SelectWrapper,
  ToggleWrapper,
} from '../ui';
import {
  Fieldset,
  InputSelect,
  FieldsGroup,
  UploadWrapper,
  InputsWrapper,
  ReactSelectWrapper,
  PhoneNumberInputWrapper,
} from './ui';
//////////////////////////////////////////////////

const onEnterKeyPressHandler = (props: Object, e: Object) => {
  const { keyCode, charCode } = e;
  const code = R.or(keyCode, charCode);
  const { onEnterKeyPressHandler } = props;

  if (R.and(R.equals(code, GC.EVENT_KEY_CODE_ENTER), G.isFunction(onEnterKeyPressHandler))) {
    return onEnterKeyPressHandler(e);
  }
};

export const setOptions = (
  options: any,
  optionsForSelect: Object,
  setOptionsFunc: Function,
  field: any,
  values: any,
) => {
  if (R.and(G.isNotNilAndNotEmpty(setOptionsFunc), G.isTrue(field.shouldSetOptions))) {
    return setOptionsFunc(field);
  }

  if (R.is(String, options)) {
    return R.pathOr([], [options], optionsForSelect);
  }

  if (R.is(Function, options)) {
    return options(values, optionsForSelect);
  }

  return G.ifElse(R.is(Array, options), options, []);
};

export const FieldComponent = pure((props: Object) => {
  const {
    type,
    width,
    onChange,
    autofocus,
    fieldName,
    setFieldValue,
    setFieldTouched,
    shouldCustomChange,
  } = props;

  const inputAutofocus = G.ifElse(G.isTrue(autofocus), true, false);

  const borderStyles = {
    borderRadius: '4px',
    useAdditionalBorderColor: true,
    additionalBorderColor: G.getTheme('colors.dark.grey'),
  };

  if (R.equals(type, 'password')) {
    return <PasswordInput {...props} {...borderStyles} />;
  }

  if (R.equals(type, 'textarea')) {
    return <Textarea {...props} {...borderStyles} />;
  }

  if (R.equals(type, 'phoneNumber')) {
    const { error, touched } = props;

    return (
      <PhoneNumberInputWrapper
        {...borderStyles}
        width={width}
        error={error}
        touched={touched}
      >
        <PhoneInput
          {...props}
          country='US'
          flags={flags}
          countries={PHONE_COUNTRIES}
          onBlur={() => setFieldTouched(fieldName, true)}
          onChange={(value: Object) => setFieldValue(fieldName, R.or(value, ''))}
          placeholder={G.getWindowLocale('titles:enter-phone-number', 'Enter phone number')}
        />
      </PhoneNumberInputWrapper>
    );
  }

  if (R.equals(type, 'searchselect')) {
    return (
      <ReactSelectWrapper width={width}>
        <SelectInput {...props} />
      </ReactSelectWrapper>
    );
  }

  if (R.equals(type, 'multiselect')) {
    return (
      <ReactSelectWrapper width={width}>
        <SelectInput {...props} isMulti={true} />
      </ReactSelectWrapper>
    );
  }

  if (R.equals(type, 'complexSelect')) {
    return (
      <ReactSelectWrapper width={width}>
        <SelectInput {...props} />
      </ReactSelectWrapper>
    );
  }

  if (R.equals(type, 'toggle')) {
    const { value, title, checked } = props;

    return (
      <ToggleWrapper title={title} width={width}>
        <Toggle
          {...props}
          icons={false}
          checked={G.ifElse(
            G.isNotNilAndNotEmpty(checked),
            checked,
            value,
          )}
        />
      </ToggleWrapper>
    );
  }

  if (R.equals(type, 'timeInput')) {
    return <MaskedTimeInput {...props} {...borderStyles} height={30} />;
  }

  if (R.equals(type, 'calendar')) {
    return <Calendar {...props} {...borderStyles} />;
  }

  if (R.equals(type, 'datePicker')) {
    const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);

    if (G.isFalse(configUseMuiCalendar)) {
      return <Calendar {...props} {...borderStyles} />;
    }

    return <DatePickerMuiFormik {...props} borderRadius='4px' borderColor={G.getTheme('colors.dark.grey')} />;
  }

  if (R.equals(type, 'select')) {
    const { options, afterTop, isWrapped, afterRight } = props;

    if (G.isFalse(isWrapped)) {
      return (
        <InputSelect {...props} {...borderStyles}>
          {renderOptions(options)}
        </InputSelect>
      );
    }

    return (
      <SelectWrapper width={width} afterTop={afterTop} afterRight={afterRight}>
        <InputSelect {...props} {...borderStyles}>
          {renderOptions(options)}
        </InputSelect>
      </SelectWrapper>
    );
  }

  if (R.equals(type, 'countrySelect')) {
    return (
      <CountryDropdownSelect {...props} {...borderStyles} />
    );
  }

  if (R.equals(type, 'addressAutocomplete')) {
    return (
      <PlacesAutocompleteInput {...props} {...borderStyles} />
    );
  }

  if (R.equals(type, 'file')) {
    const { id, value, fileName } = props;

    return (
      <UploadWrapper {...borderStyles} width={width}>
        <input
          id={id}
          value=''
          type='file'
          title={R.pathOr('', ['value', 'name'], props)}
          onChange={(event: Object) => setFieldValue(fieldName, event.currentTarget.files[0])}
        />
        <TextComponent
          px={10}
          display='block'
          withEllipsis={true}
          width='calc(100% - 30px)'
          title={R.pathOr(fileName, ['value', 'name'], props)}
        >
          {R.pathOr(fileName, ['value', 'name'], props)}
        </TextComponent>
        {
          G.isNotNilAndNotEmpty(value)
          && (
            <CleanWrapper
              onClick={(event: Object) => {
                event.preventDefault();
                setFieldValue(fieldName, null);
              }}
            >
              {I.trash(G.getTheme('icons.iconColor'))}
            </CleanWrapper>
          )
        }
      </UploadWrapper>
    );
  }

  if (R.equals(type, 'multiEmail')) {
    return <MultiEmailInput {...props} borderRadius='4px' borderColor={G.getTheme('colors.dark.grey')} />;
  }

  if (R.equals(type, 'multiItems')) {
    return <MultiItemsInput {...props} {...borderStyles} />;
  }

  if (R.equals(type, 'multiswitch')) {
    return (
      <Switcher
        {...props}
        onSwitch={(value: Object) => setFieldValue(fieldName, value)}
      />
    );
  }

  if (R.equals(type, 'asyncSearch')) {
    return <ReactAsyncSelect {...props} />;
  }

  if (R.equals(type, 'number')) {
    return (
      <Input
        {...props}
        {...borderStyles}
        autoFocus={inputAutofocus}
        onKeyPress={(e: Object) => onEnterKeyPressHandler(props, e)}
        onChange={(e: Object) => {
          if (shouldCustomChange) {
            return onChange(e);
          }

          setFieldValue(fieldName, e.currentTarget.value);
        }}
      />
    );
  }

  if (R.equals(type, 'custom')) {
    const { component } = props;

    return component(props);
  }

  return (
    <Input
      {...R.dissoc('onEnterKeyPressHandler', props)}
      {...borderStyles}
      autoFocus={inputAutofocus}
      onKeyPress={(e: Object) => onEnterKeyPressHandler(props, e)}
    />
  );
});

export const setDisabled = (field: any, values: Object, handlers: Object) => {
  let disabled = field.disabled;

  if (G.isNotNilAndNotEmpty(field.customDisabledFunction)) {
    disabled = handlers[field.customDisabledFunction](values);
  }

  if (G.isFunction(field.disabled)) {
    disabled = field.disabled(field, values);
  }

  return disabled;
};

export const setDisplay = (item: any, { values, ...props }: Object) => {
  let display = R.or(item.fieldGroupDisplay, props.fieldsGroupDisplay);

  if (G.isFunction(item.fieldGroupDisplay)) {
    display = item.fieldGroupDisplay(item, values, props);
  }

  return display;
};

// TODO: remove after testing
// export const setMulti = (field: any, values: Object, handlers: Object) => {
//   let multi = field.multi;

//   if (G.isNotNilAndNotEmpty(field.customMultiType)) {
//     multi = handlers[field.customMultiType](values);
//   }

//   return multi;
// };

export const spreadStyles = (item: Object) => G.ifElse(
  R.is(Object, item.styles),
  item.styles,
  {},
);

export const renderError = (errors: Object, touched: Object, item: Object) => {
  const {
    position,
    errorTop,
    fieldName,
    errorLeft,
    errorWidth,
    errorTitle,
    errorMargin,
    errorPosition,
    errorOverflow,
    errorFontSize,
    errorTextAlign,
    errorWhiteSpace,
    errorTextOverflow,
  } = item;

  const error = getIn(errors, fieldName);
  const touch = getIn(touched, fieldName);

  if (R.and(G.isNotNilAndNotEmpty(error), R.or(G.isTrue(touch), G.isArray(touch)))) {
    return (
      <Error
        {...spreadStyles(item)}
        m={errorMargin}
        position={position}
        errorTop={errorTop}
        errorLeft={errorLeft}
        errorWidth={errorWidth}
        errorOverflow={errorOverflow}
        errorPosition={errorPosition}
        errorTextAlign={errorTextAlign}
        title={R.and(errorTitle, error)}
        errorWhiteSpace={errorWhiteSpace}
        fontSize={R.or(errorFontSize, 11)}
        errorTextOverflow={errorTextOverflow}
      >
        {error}
      </Error>
    );
  }

  return null;
};

export const Inputs = (props: Object) => {
  const {
    errors,
    values,
    touched,
    handlers,
    setErrors,
    setValues,
    handleBlur,
    handleEnter,
    handleChange,
    fieldSettings,
    setFieldValue,
    setFieldError,
    getLoadOptions,
    setFieldTouched,
    optionsForSelect,
    setOptionsFunction,
    onEnterKeyPressHandler,
    customSelectLocationFunction,
    handleAsyncSearchSelectChange,
  } = props;

  if (R.equals(fieldSettings.type, 'multi')) {
    return (
      <InputsWrapper jc={fieldSettings.jc} width={fieldSettings.width}>
        {
          fieldSettings.fields.map((item: Object) => (
            <Box key={item.fieldName} position='relative' zIndex={R.pathOr('unset', ['zIndex'], item)}>
              <FieldComponent
                pr='10px'
                pl='10px'
                {...item}
                values={values}
                errors={errors}
                handlers={handlers}
                id={item.fieldName}
                onBlur={handleBlur}
                name={item.fieldName}
                setErrors={setErrors}
                setValues={setValues}
                onChange={handleChange}
                handleEnter={handleEnter}
                width={item.width || 'auto'}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                setFieldTouched={setFieldTouched}
                // TODO: remove after testing
                // multi={setMulti(item, values, handlers)}
                value={R.path([item.fieldName], values)}
                disabled={setDisabled(item, values, handlers)}
                onEnterKeyPressHandler={onEnterKeyPressHandler}
                customSelectLocationFunction={customSelectLocationFunction}
                hasError={R.and(errors[item.fieldName], touched[item.fieldName])}
                options={setOptions(item.options, optionsForSelect, setOptionsFunction, item, values)}
              />
              {renderError(props.errors, props.touched, item)}
            </Box>
          ))
        }
      </InputsWrapper>
    );
  }

  return (
    <FieldComponent
      pl='10px'
      pr='10px'
      errors={errors}
      values={values}
      {...fieldSettings}
      onBlur={handleBlur}
      handlers={handlers}
      setErrors={setErrors}
      setValues={setValues}
      onChange={handleChange}
      fileName={props.fileName}
      id={fieldSettings.fieldName}
      setFieldError={setFieldError}
      setFieldValue={setFieldValue}
      name={fieldSettings.fieldName}
      getLoadOptions={getLoadOptions}
      setFieldTouched={setFieldTouched}
      width={fieldSettings.width || 'auto'}
      onEnterKeyPressHandler={onEnterKeyPressHandler}
      // TODO: remove after testing
      // multi={setMulti(fieldSettings, values, handlers)}
      disabled={setDisabled(fieldSettings, values, handlers)}
      customSelectLocationFunction={customSelectLocationFunction}
      value={R.path(R.split('.', fieldSettings.fieldName), values)}
      handleAsyncSearchSelectChange={handleAsyncSearchSelectChange}
      hasError={R.and(errors[fieldSettings.fieldName], touched[fieldSettings.fieldName])}
      options={setOptions(
        fieldSettings.options,
        optionsForSelect,
        setOptionsFunction,
        fieldSettings,
        values,
      )}
    />
  );
};

const getChangeHandler = (item: Object, props: Object) => {
  const { handleChange, handleCustomChange } = props;
  const { shouldCustomChange, customChangeHandler } = item;

  if (R.and(G.isTrue(shouldCustomChange), G.isFunction(customChangeHandler))) {
    return (event: Object) => customChangeHandler(event, item, props);
  }

  if (R.and(G.isTrue(shouldCustomChange), G.isFunction(handleCustomChange))) {
    return (e: Object) => handleCustomChange(e, item.fieldName);
  }

  return handleChange;
};

const enhance = compose(
  withState('zIndex', 'setZIndex', (props: Object) => {
    const zIndex = R.path(['itemZIndex'], props);

    return zIndex;
  }),
  withHandlers({
    handleClick: (props: Object) => () => {
      props.setZIndex(1000);
    },
    handleOuterClick: (props: Object) => () => {
      const { setZIndex } = props;

      const zIndex = R.path(['itemZIndex'], props);

      setZIndex(zIndex);
    },
  }),
);

const FieldsGroupWithClickZIndex = enhance((props: Object) => {
  const { children, handleClick, handleOuterClick } = props;

  return (
    <OuterClick {...props} as={FieldsGroup} onClick={handleClick} onOuterClick={handleOuterClick}>
      {children}
    </OuterClick>
  );
});

export const FieldsetComponent = pure((props: Object) => {
  const {
    fields,
    flexGrow,
    labelsColor,
    fieldsetWidth,
    flexDirection,
    justifyContent,
    fieldsetPadding,
    fieldSetFlexWrap,
    fieldsGroupWidth,
    fieldsGroupMargin,
  } = props;

  return (
    <Fieldset
      p={fieldsetPadding}
      flexGrow={flexGrow}
      width={fieldsetWidth}
      flexWrap={fieldSetFlexWrap}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
    >
      {fields.map((item: Object, index: number) => {
        const { withClickZIndex } = item;

        const FieldsGroupComponent = G.ifElse(
          G.isTrue(withClickZIndex),
          FieldsGroupWithClickZIndex,
          FieldsGroup,
        );

        return (
          <FieldsGroupComponent
            key={index}
            mr={item.mr || null}
            flexWrap={item.flexWrap}
            alignItems={item.alignItems}
            width={props.fieldsGroupWidth}
            display={setDisplay(item, props)}
            flexDirection={item.flexDirection}
            justifyContent={item.justifyContent}
            my={item.fieldsGroupMargin || fieldsGroupMargin || '5px'}
            zIndex={R.prop('zIndex', item) || R.subtract(fields.length, index)}
            itemZIndex={R.prop('zIndex', item) || R.subtract(fields.length, index)}
          >
            <Flex
              pl={item.labelPl || '10px'}
              width={item.labelWidth || '30%'}
              display={G.ifElse(item.labelDisplay, item.labelDisplay, 'flex')}
            >
              <Label
                htmlFor={item.fieldName}
                fontWeight={item.labelFontWeight}
                fontSize={props.labelFontSize || 14}
                className={G.ifElse(item.isRequired, 'required', 'not-required')}
                color={G.getThemeColor(
                  item.labelColor || labelsColor,
                  G.getTheme('colors.darkGrey'),
                )}
              >
                {item.label || G.getWindowLocale(item.loc)}
              </Label>
              {
                G.isNotNilAndNotEmpty(item.info)
                && (
                  <Box ml='8px'>
                    <Info text={G.getWindowLocale(item.info)} />
                  </Box>
                )
              }
            </Flex>
            <InputsWrapper
              width={item.inputsWidth}
              m={item.inputWrapMargin || '5px'}
              alignItems={item.inputsAlignItems}
              flexDirection={item.inputsFlexDirection}
              maxWidth={G.ifElse(
                G.isNotNilAndNotEmpty(item.info),
                fieldsGroupWidth,
                item.width,
              )}
            >
              <Inputs
                fieldSettings={item}
                values={props.values}
                errors={props.errors}
                touched={props.touched}
                handlers={props.handlers}
                fileName={props.fileName}
                setValues={props.setValues}
                setErrors={props.setErrors}
                handleBlur={props.handleBlur}
                handleEnter={props.handleEnter}
                setFieldValue={props.setFieldValue}
                setFieldError={props.setFieldError}
                getLoadOptions={props.getLoadOptions}
                setFieldTouched={props.setFieldTouched}
                optionsForSelect={props.optionsForSelect}
                handleChange={getChangeHandler(item, props)}
                setOptionsFunction={props.setOptionsFunction}
                onEnterKeyPressHandler={props.onEnterKeyPressHandler}
                customSelectLocationFunction={props.customSelectLocationFunction}
                handleAsyncSearchSelectChange={props.handleAsyncSearchSelectChange}
              />
              {renderError(props.errors, props.touched, item)}
            </InputsWrapper>
          </FieldsGroupComponent>
        );
      })}
    </Fieldset>
  );
});

