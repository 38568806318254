import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, branch, compose, withState, withProps, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { FormSection } from '../';
// hocs
import { withAsyncSequence } from '../../hocs';
// components/forms
import { Fieldset2 } from '../formik';
import { withLocationCustomChangeHandler } from '../hocs/with-location-custom-change-handler';
import {
  defaultLatLngFields,
  defaultContactFields,
  defaultLocationFields,
  contractBillToLocationSettings,
  getBillToLocationSectionSettings,
  getLocationValidationSchemaObject,
  contractBillToLocationValidationSchemaObject,
} from '../settings/location-formik-settings';
//////////////////////////////////////////////////

const getSettings = (formType: string, addBillToFromCreateBranchFrom: boolean) => G.ifElse(
  R.equals(formType, GC.LOCATION_FORM_TYPE_CONTRACT_BILL_TO_LOCATION),
  contractBillToLocationSettings,
  getBillToLocationSectionSettings(addBillToFromCreateBranchFrom),
);

const LocationSections = pure((props: Object) => {
  const {
    formType,
    optionsForSelect,
    handleCustomChange,
    addBillToFromCreateBranchFrom = false,
  } = props;

  return (
    <FormSection>
      {getSettings(formType, addBillToFromCreateBranchFrom).map(({ fields }: Object, index: number) => (
        <Fieldset2
          {...G.getFormikProps(props)}
          {...optionsForSelect}
          key={index}
          fields={fields}
          handleChange={handleCustomChange}
          fieldsWrapperStyles={{ mt: 25, px: 15, justifyContent: 'space-between' }}
        />
      ))}
    </FormSection>
  );
});

const enhance = compose(
  withState('storedValues', 'setStoredValues', {}),
  withProps(() => ({
    sequenceConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
    autogenerateConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    configsNamesArray: [
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    ],
  })),
  branch(({ addBillToFromCreateBranchFrom }: Object) => G.isNilOrFalse(addBillToFromCreateBranchFrom),
    withAsyncSequence,
  ),
  withFormik({
    enableReinitialize: true,
    validationSchema: () => Yup.lazy(({ saveAsTemplate }: Object) =>
      Yup.object().shape(getLocationValidationSchemaObject(saveAsTemplate)),
    ),
    mapPropsToValues: (props: Object) => {
      const { storedValues, initialValues, asyncSequence, searchedValues } = props;

      const defaultFields = G.ifElse(
        G.isNotNilAndNotEmpty(asyncSequence),
        R.assoc(GC.FIELD_TEMPLATE_ID, asyncSequence, defaultLocationFields),
        defaultLocationFields,
      );

      return G.setInitialLocationFormikValues(
        {
          ...defaultFields,
          ...defaultLatLngFields,
          ...defaultContactFields,
        },
        R.mergeRight(storedValues, initialValues),
        searchedValues,
      );
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const { asyncSequence, submitHandler } = props;

      if (R.and(
        R.propEq(asyncSequence, GC.FIELD_TEMPLATE_ID, values),
        G.isTrue(G.getPropFromObject('saveAsTemplate', values)),
      )) {
        return submitHandler(R.assoc(GC.FIELD_TEMPLATE_ID, null, values));
      }

      submitHandler(values);
    },
  }),
  withLocationCustomChangeHandler,
  withHandlers({
    handleSubmitAndSaveLocation: ({ setFieldValue }: Object) => () =>
      setFieldValue('saveAsTemplate', true),
    handleSetLatLongToLocation: (props: Object) => async () => {
      const { setValues, initialValues } = props;

      const address = R.compose(
        R.join(', '),
        R.values,
        R.filter(G.isNotNilAndNotEmpty),
        R.pick([
          GC.FIELD_ZIP,
          GC.FIELD_CITY,
          GC.FIELD_STATE,
          GC.FIELD_COUNTRY,
          GC.FIELD_ADDRESS_1,
        ]),
      )(initialValues);

      if (G.isNilOrEmpty(address)) return;

      const lat = G.getPropFromObject(GC.FIELD_LATITUDE, initialValues);
      const lon = G.getPropFromObject(GC.FIELD_LONGITUDE, initialValues);

      if (R.and(G.isNotNilAndNotEmpty(lat), G.isNotNilAndNotEmpty(lon))) return;

      // TODO: skip this call on did mount
      const { latitude, longitude } = await G.geocodeByPlaceAddress(address, 'forms -> BillToLocationForm');

      setValues(R.mergeRight(initialValues, { latitude, longitude }));
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleSetLatLongToLocation();
    },
  }),
  pure,
);

const BillToLocationForm = (props: Object) => {
  const {
    handleSubmit,
    setFieldValue,
    submitBtnText,
    submitAndSaveLocation,
    handleSubmitAndSaveLocation,
    addBillToFromCreateBranchFrom,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <LocationSections {...props} />
      <FormFooter2
        boxStyles={{ p: 15 }}
        submitBtnText={submitBtnText}
        submitAction={() => {
          if (G.isTrue(addBillToFromCreateBranchFrom)) {
            setFieldValue('saveAsTemplate', false);
          } else {
            setFieldValue('saveAsTemplate', R.not(submitAndSaveLocation));
          }
        }}
        actionButtons={G.ifElse(
          G.isTrue(submitAndSaveLocation),
          [{
            type: 'submit',
            action: handleSubmitAndSaveLocation,
            buttonStyles: { width: 'max-content' },
            displayText: G.getWindowLocale('actions:submit-and-save-location', 'Submit and Save Location'),
          }],
          null,
        )}
      />
    </form>
  );
};

const contractBillToLocationEnhance = compose(
  withState('storedValues', 'setStoredValues', {}),
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitHandler(values),
    validationSchema: Yup.object().shape(contractBillToLocationValidationSchemaObject),
    mapPropsToValues: ({ storedValues, initialValues, searchedValues }: Object) => G.setInitialLocationFormikValues(
      R.assoc(GC.FIELD_CONTACTS, [], defaultLocationFields),
      R.mergeRight(storedValues, initialValues),
      searchedValues,
    ),
  }),
  withLocationCustomChangeHandler,
);

export const ContractBillToLocationForm = contractBillToLocationEnhance(BillToLocationForm);

export default enhance(BillToLocationForm);
