import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const validationSchemaCarrierObject = {
  [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT]: Yup.object({
    [GC.FIELD_CARRIER_NAME]: G.yupStringNotRequired,
  }),
};

export const validationSchema = Yup.object()
  .shape({
    [GC.FIELD_MODE]: G.yupStringNotRequired,
    [GC.FIELD_SERVICE_TYPE]: G.yupStringNotRequired,
  }).shape(validationSchemaCarrierObject);

export const defaultValues = {
  [GC.FIELD_MODE]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT]: {
    [GC.FIELD_CARRIER_NAME]: null,
  },
};

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const fields = [
  {
    type: 'select',
    inputWrapperStyles,
    fieldName: GC.FIELD_MODE,
    label: ['titles:mode', 'Mode'],
    options: 'transportationModeOptions',
  },
  {
    type: 'select',
    options: 'serviceTypeOptions',
    fieldName: GC.FIELD_SERVICE_TYPE,
    inputWrapperStyles: { width: 270 },
    label: ['titles:service-type', 'Service Type'],
  },
];
