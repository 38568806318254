import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box } from '../../ui';
// forms
import { Fieldset2 } from '../';
//////////////////////////////////////////////////

const defaultValues = {
  date: null,
  override: false,
};

const getValidationSchema = ({ override }: Object) => {
  if (override) {
    return {
      date: G.yupStringRequired,
      override: Yup.boolean()
        .nullable(true)
        .notRequired(),
    };
  }

  return {
    date: G.yupStringNotRequired,
    override: Yup.boolean()
      .nullable(true)
      .notRequired(),
  };
};

const getFields = (override: boolean) => {
  const overrideField = {
    type: 'checkbox',
    fieldName: 'override',
    inputWrapperStyles: { mb: 25 },
    label: ['titles:override', 'Override"'],
  };

  if (R.not(override)) return R.of(Array, overrideField);

  return [
    overrideField,
    {
      isClearable: true,
      fieldName: 'date',
      type: 'datePicker',
      timeSelection: false,
      inputWrapperStyles: { mb: '5px' },
      label: ['titles:gl-posted-date', 'GL Posted Date'],
    },
  ];
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SELECT_GL_POSTED_DATE_FORM',
    validationSchema: () => Yup.lazy((values: Object) => Yup.object().shape(getValidationSchema(values))),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const { date, override } = values;

      submitAction(G.ifElse(override, date, null));
    },
  }),
  pure,
);

export const SelectGLPostedDateForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box pb={12}>
      <Box mb={15}>
        {G.getWindowLocale(
          'messages:use-invoice-date-or-override-gl-posted-date',
          'Would you like to use Invoice Date as GL posted date or override it?',
        )}
      </Box>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getFields(R.path(['values', 'override'], props))}
      />
    </Box>
    <FormFooter2 />
  </form>
));
