import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { renderBorderColor } from '../helpers';
// ui
import { Box, PlacesAutocomplete } from '../../ui';
//////////////////////////////////////////////////

class PlacesAutocompleteInput extends React.Component {
  handleBlur = () => {
    const { id, setFieldTouched } = this.props;

    setFieldTouched(id, true);
  };

  handleChange = (value: string) => {
    const { id, setFieldValue } = this.props;

    setFieldValue(id, value);
  };

  handleSelect = async (value: string, enter: boolean = false) => {
    const {
      id,
      values,
      handlers,
      setValues,
      addressFieldName,
      shouldHandleEnter,
      customPlaceGeocoder,
      customSelectLocationFunction,
    } = this.props;

    const getCatchCallback = () => {
      const handleEnter = R.path(['handleEnter'], handlers);

      if (G.isAllTrue(enter, G.isFunction(handleEnter), shouldHandleEnter)) {
        return () => handleEnter(value, id);
      }

      return null;
    };

    let result;

    if (G.isTrue(customPlaceGeocoder)) {
      const geocodeByPlace = R.path(['geocodeByPlace'], handlers);

      result = await geocodeByPlace(value, 'forms -> FieldsetComponent', getCatchCallback);
    } else {
      result = await G.geocodeByPlaceAddress(
        value,
        'forms -> FieldsetComponent',
        getCatchCallback(),
        { addressFieldName },
      );
    }

    const data = {
      ...result,
      label: id,
      [GC.FIELD_LOAD_BOARD_ORIGIN_CITY]: G.getPropFromObject('city', result), // TODO: check why it is here
    };

    if (G.isFunction(customSelectLocationFunction)) {
      return customSelectLocationFunction(data, addressFieldName);
    }

    setValues(R.mergeRight(R.or(values, {}), data), id);
  };

  render() {
    let value = R.pathOr('', [GC.FIELD_VALUE], this.props);

    if (G.isObject(value)) {
      value = G.concatLocationFields(value);
    }

    const {
      id,
      mt,
      width,
      hasError,
      disabled,
      placeholder,
      setFieldValue,
      useMenuPortalTarget,
      additionalBorderColor,
      useAdditionalBorderColor,
    } = this.props;

    return (
      <Box mt={mt}>
        <PlacesAutocomplete
          id={id}
          width={width}
          value={value}
          disabled={disabled}
          handleBlur={this.handleBlur}
          setFieldValue={setFieldValue}
          handleSelect={this.handleSelect}
          useMenuPortalTarget={useMenuPortalTarget}
          placeholder={R.or(placeholder, 'Search a location...')}
          additionalStyles={{
            control: (baseStyles: Object, props: Object) => ({
              ...baseStyles,
              minHeight: 30,
              borderRadius: 4,
              height: 'max-content',
              boxShadow: G.ifElse(G.isTrue(props.isFocused), '0 0 5px 0 rgba(206, 40, 40, 0.5)'),
              borderColor: renderBorderColor({ hasError, additionalBorderColor, useAdditionalBorderColor }),
              ':hover': {
                borderColor: G.ifElse(hasError, G.getTheme('forms.inputs.borderColorErr'), 'hsl(0, 0%, 70%)'),
              },
            }),
          }}
        />
      </Box>
    );
  }
}

export default PlacesAutocompleteInput;
