import React from 'react';
import { CountryDropdown } from 'react-country-region-selector';
//////////////////////////////////////////////////

const CountrySelect = (props: Object) => {
  const { id, value, disabled, setFieldValue, setFieldTouched } = props;

  const handleBlur = () => setFieldTouched(id, true);

  const handleChange = (value: string) => setFieldValue(id, value);

  return (
    <CountryDropdown
      id={id}
      disabled={disabled}
      value={value || ''}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

export default CountrySelect;
