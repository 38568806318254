import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  mb: 25,
  width: 400,
};

export const getDocumentFields = (initialValues: Object) => {
  const isDocumentUpload = G.isNilOrEmpty(R.path([GC.FIELD_DOCUMENT_UPLOAD], initialValues));

  return [
    {
      isRequired: true,
      type: 'reactSelect',
      options: 'availableDocumentTypes',
      fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
      label: ['titles:document-type', 'Document Type'],
      inputWrapperStyles: { ...defaultFieldStyles, zIndex: 2 },
    },
    {
      type: 'textarea',
      fieldName: GC.FIELD_DOCUMENT_COMMENTS,
      label: ['titles:description', 'Description'],
      inputWrapperStyles: { ...defaultFieldStyles, mb: 15 },
    },
    {
      version: 2,
      type: 'multiswitch',
      fieldName: GC.FIELD_DOCUMENT_PROOF_TYPE,
      inputWrapperStyles: {
        ...defaultFieldStyles,
        display: 'flex',
        justifyContent: 'center',
      },
      options: [
        {
          width: 100,
          name: 'General',
          value: GC.DOCUMENT_PROOF_TYPE_GENERAL_VALUE,
        },
        {
          width: 80,
          name: GC.DOCUMENT_PROOF_TYPE_POP,
          value: GC.DOCUMENT_PROOF_TYPE_POP,
        },
        {
          width: 80,
          name: GC.DOCUMENT_PROOF_TYPE_POD,
          value: GC.DOCUMENT_PROOF_TYPE_POD,
        },
      ],
    },
    {
      type: 'reactSelect',
      options: 'eventsOptions',
      label: ['titles:stops', 'Stops'],
      fieldName: GC.FIELD_DOCUMENT_EVENT_GUID,
      inputWrapperStyles: {
        ...defaultFieldStyles,
        mb: G.ifElse(isDocumentUpload, 25, 15),
      },
    },
    {
      type: 'text',
      isRequired: true,
      label: ['titles:url', 'URL'],
      fieldName: GC.FIELD_DOCUMENT_URL,
      inputWrapperStyles: {
        ...defaultFieldStyles,
        mb: 15,
        display: G.ifElse(isDocumentUpload, 'block', 'none'),
      },
    },
    {
      type: 'file',
      fieldName: GC.FIELD_DOCUMENT_UPLOAD,
      inputWrapperStyles: defaultFieldStyles,
      label: ['titles:upload-file', 'Upload File'],
    },
  ];
};

export const defaultDocumentsFields = {
  [GC.FIELD_DOCUMENT_URL]: '',
  [GC.FIELD_DOCUMENT_UPLOAD]: [],
  [GC.FIELD_DOCUMENT_COMMENTS]: '',
  [GC.FIELD_DOCUMENT_PROOF_TYPE]: '',
  [GC.FIELD_DOCUMENT_EVENT_GUID]: '',
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: '',
};

export const defaultValidationSchemaDocumentFormObject = {
  [GC.FIELD_DOCUMENT_EVENT_GUID]: G.yupStringNotRequired,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_URL]: Yup.string()
    .nullable(true)
    .url(G.getShouldBeUrlLocaleTxt()),
  [GC.FIELD_DOCUMENT_COMMENTS]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
};

export const getDocumentsFormValidationSchemaObject = (values: Object) => {
  const requiredFields = [values[GC.FIELD_DOCUMENT_UPLOAD], values[GC.FIELD_DOCUMENT_URL]];

  if (R.any(G.isNotNilAndNotEmpty)(requiredFields)) return defaultValidationSchemaDocumentFormObject;

  const requiredFieldsValidationSchemaObject = {
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.array()
      .nullable(true)
      .required(G.getShouldBeFileOrUrlLocaleTxt()),
    [GC.FIELD_DOCUMENT_URL]: Yup.string()
      .nullable(true)
      .required(G.getShouldBeFileOrUrlLocaleTxt())
      .url(G.getShouldBeUrlLocaleTxt()),
  };

  return R.mergeRight(defaultValidationSchemaDocumentFormObject, requiredFieldsValidationSchemaObject);
};
