import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-search
import TemplateSearch from '../';
import { makeSelectLocationTemplates } from '../selectors';
import { getLocationListBySearchRequest } from '../actions';
import { locationFields, defaultLocationFields } from '../settings';
//////////////////////////////////////////////////

const fieldsToUse = [
  GC.FIELD_ZIP,
  GC.FIELD_CITY,
  GC.FIELD_STATE,
  GC.FIELD_COUNTRY,
  GC.FIELD_CONTACTS,
  GC.FIELD_COMMENTS,
  GC.FIELD_LOCATION,
  GC.FIELD_LATITUDE,
  GC.FIELD_LONGITUDE,
  GC.FIELD_ADDRESS_1,
  GC.FIELD_ADDRESS_2,
  GC.FIELD_TEMPLATE_ID,
  GC.FIELD_LOCATION_NAME,
  GC.FIELD_LOCATION_TYPE,
  GC.FIELD_OPERATION_HOUR,
];

const fieldsToPick2 = [
  GC.FIELD_INTEGRATION_ID,
  GC.FIELD_INTEGRATION_TYPE,
];

const makeTemplateSelectOptions = (locationTemplates: Array) => (
  R.map((locationTemplate: Object) => {
    const data = R.filter(
      (item: string) => G.notEquals(item, null),
      R.pick(fieldsToUse, locationTemplate),
    );

    const data2 = R.map(
      (item: string) => R.or(item, ''),
      R.pick(fieldsToPick2, locationTemplate),
    );

    const dataToUse = R.mergeRight(data, data2);

    const { zip, city, state, country, address1, address2, templateId, locationName } = dataToUse;

    const address = G.ifElse(
      G.isNotNilAndNotEmpty(R.path(['address2'], dataToUse)),
      R.join(':', [address1, address2]),
      address1,
    );

    const locationData = R.join(
      ':',
      [templateId, locationName],
    );

    return ({
      value: dataToUse,
      label: `${G.cutString(locationData, 30, true)},
        ${G.cutString(address, 30, true)},
        ${city}, ${state} ${zip} ${country}
      `,
    });
  }, locationTemplates)
);

const Component = (props: Object) => (
  <TemplateSearch
    {...props}
    templateFields={locationFields}
    templateList={props.locationTemplates}
    defaultTemplateFields={defaultLocationFields}
    configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
    getListTemplatesAction={props.getLocationListBySearchRequest}
    selectOptions={makeTemplateSelectOptions(props.locationTemplates)}
  />
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  locationTemplates: makeSelectLocationTemplates(state),
});

export const LocationTemplateSearch = connect(mapStateToProps, {
  getLocationListBySearchRequest,
})(Component);
